import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import { ArrowForward } from '@material-ui/icons';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../../images/pourwall-logo.svg';
import BarScene from '../../images/illustration-signup.svg';
import Glassware from '../../images/glassware.svg';

import { SessionContext } from '../../contexts/session';
import { SIGN_UP_MEMBER } from '../signup/mutations';
import style from '../signup/styles';
import { FeedbackBar } from '../../components/FeedbackBar';
import { getMessageFromError } from '../../util/graphql';

const useStyles = makeStyles(style);

export const SignUpMember = ({ history }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const classes = useStyles();
  let { user, token, login } = useContext(SessionContext);
  const queryParams = new URLSearchParams(location.search);

  const [firstName, setFirstName] = useState(decodeURIComponent(queryParams.get('firstName') || ''));
  const [lastName, setLastName] = useState(decodeURIComponent(queryParams.get('lastName') || ''));
  const [email, setEmail] = useState(decodeURIComponent(queryParams.get('email') || ''));
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  const [validating, setValidating] = useState(false);

  const [signUp, { loading, data }] = useMutation(SIGN_UP_MEMBER);

  const go = async () => {
    setErrorMessage(null);
    setValidating(true);
    try {
      if (firstName && lastName && email && password) {
        await signUp({
          variables: {
            firstName,
            lastName,
            email,
            password
          }
        });
      }
    } catch (e) {
      setErrorMessage(getMessageFromError(e));
    }
  };

  useEffect(() => {
    if (queryParams.get('email')) {
      history.push({ pathname: '/signup-member' });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.memberFinishSignup?.token && data?.memberFinishSignup?.user) {
      login(data.memberFinishSignup.user, data.memberFinishSignup.token);
      history.push('/taps');
    }
  }, [data, history, login]);

  if (user && token) return <Redirect to="/taps" />;

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.header} xs={12} md={8}>
        <img src={Logo} alt="POURWALL" className={classes.logo} />
        <Hidden mdUp>
          <Typography variant="h1">{t('Signup.LetsGetStartedMember')}</Typography>
          <p>{t('Signup.UpAndRunning')}</p>
        </Hidden>
        <Hidden smDown>
          <div className={classes.product}>
            <div className={classes.productText}>
              <img alt="" src={Glassware} className={classes.glassware} />
              <h1 className={classes.productHeading}>{t('Signup.SimpleDigitalBeverageMenus')}</h1>
              <p className={classes.productDescription}>{t('Signup.CreatedForBars')}</p>
            </div>
            <img alt="" src={BarScene} className={classes.barScene} />
          </div>
        </Hidden>
      </Grid>
      <Grid item className={classes.content} xs={12} md={4}>
        <Hidden smDown>
          <Typography variant="h1">{t('Signup.LetsGetStarted')}</Typography>
          <p>{t('Signup.UpAndRunning')}</p>
        </Hidden>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.FirstName')}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            error={validating && !firstName}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.LastName')}
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            error={validating && !lastName}
          />

          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.Email')}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            disabled
            error={validating && !email}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Signup.CreatePassword')}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            error={validating && !password}
          />

          <Button className={classes.button} variant="contained" color="primary" onClick={go} disabled={loading}>
            {t('Signup.Next')} <ArrowForward />
          </Button>
        </form>
      </Grid>
      <FeedbackBar message={errorMessage} />
    </Grid>
  );
};
