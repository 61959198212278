export const Default = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#3c3c3c',
  foregroundColor: '#efefef',
  foregroundColor2: '#f7e63b',
  textSize: 'medium',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: false,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: false,
  columns: 2
};

export const PourwallBrand = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#fd643f',
  foregroundColor: '#ffffff',
  foregroundColor2: '#37dea9',
  textSize: 'condensed',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: false,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: false,
  columns: 3
};

export const PourwallBrandInverse = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#37dea9',
  foregroundColor: '#ffffff',
  foregroundColor2: '#fd643f',
  textSize: 'condensed',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: false,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: false,
  columns: 3
};

export const DarkDense = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#000000',
  foregroundColor: '#ffffff',
  foregroundColor2: '#ffb304',
  textSize: 'condensed',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: true,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'dense',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: true,
  columns: 2
};

export const Ninja = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#121e5d',
  foregroundColor: '#66f8d0',
  foregroundColor2: '#f83980',
  textSize: 'medium',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: false,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: false,
  columns: 2
};

export const MaxedOut = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#3c3c3c',
  foregroundColor: '#efefef',
  foregroundColor2: '#f7e63b',
  textSize: 'condensed',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: false,
  hideBeverageSourceName: true,
  showBeverageSourceLocation: null,
  menuType: 'dense',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: false,
  showStripes: true,
  columns: 3
};

export const BrightAndClean = {
  backgroundImageUrl: null,
  backgroundOpacity: null,
  showBackgroundImageHelper: false,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#ffffff',
  foregroundColor: '#3f6cb4',
  foregroundColor2: '#ea602e',
  textSize: 'medium',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: false,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'dense',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: true,
  columns: 2
};

export const Woody = {
  backgroundImageUrl: 'https://pourwall.s3.amazonaws.com/live/uploads/cafe-solstice/photo-1508392196713-1391ddb22a2a.jpeg',
  backgroundOpacity: null,
  showBackgroundImageHelper: true,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#000000',
  foregroundColor: '#ffffff',
  foregroundColor2: '#fdfdfd',
  textSize: 'medium',
  showTapNumber: true,
  showBeverageImage: true,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: true,
  columns: 2
};

export const Forest = {
  backgroundImageUrl: 'https://pourwall.s3.amazonaws.com/live/uploads/cafe-solstice/photo-1475359524104-d101d02a042b.jpeg',
  backgroundOpacity: null,
  showBackgroundImageHelper: true,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#000000',
  foregroundColor: '#ffffff',
  foregroundColor2: '#fdfdfd',
  textSize: 'medium',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: true,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: true,
  columns: 2
};

export const TonkaTruck = {
  backgroundImageUrl: 'https://pourwall.s3.amazonaws.com/live/uploads/cafe-solstice/photo-1436262513933-a0b06755c784.jpeg',
  showBackgroundImageHelper: false,
  backgroundOpacity: null,
  backgroundPosition: null,
  backgroundSize: null,
  backgroundRepeat: null,
  backgroundColor: '#fdfcfc',
  foregroundColor: '#000000',
  foregroundColor2: '#040303',
  textSize: 'medium',
  showTapNumber: true,
  showLocation: false,
  showBeverageImage: true,
  hideBeverageSourceName: false,
  showBeverageSourceLocation: null,
  menuType: 'normal',
  enableAutoPaging: null,
  showHeading: false,
  showRating: false,
  showServings: true,
  showStripes: true,
  columns: 2
};

export const Themes = [
  {
    name: 'POURWALL Default',
    themeOptions: Default
  },
  {
    name: 'Dark Dense',
    themeOptions: DarkDense
  },
  {
    name: 'POURWALL Brand',
    themeOptions: PourwallBrand
  },
  {
    name: 'POURWALL Inverse',
    themeOptions: PourwallBrandInverse
  },
  {
    name: 'Ninja',
    themeOptions: Ninja
  },
  {
    name: 'Maxed Out',
    themeOptions: MaxedOut
  },
  {
    name: 'Bright & Clean',
    themeOptions: BrightAndClean
  },
  {
    name: 'Woody',
    themeOptions: Woody
  },
  {
    name: 'Forest',
    themeOptions: Forest
  },
  {
    name: 'Tonka Truck',
    themeOptions: TonkaTruck
  }
];
