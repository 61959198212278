import React, { useState } from 'react';
import { Button, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';

import Logo from '../../images/pourwall-logo.svg';
import BarScene from '../../images/bar-scene.svg';
import Glassware from '../../images/glassware.svg';

import style from './styles';
import { RESET_PASSWORD } from './mutations';
import { FeedbackBar } from '../../components/FeedbackBar';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles(style);

export const ForgotPassword = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [validating, setValidating] = useState(false);

  const [resetPassword, { loading, error, data }] = useMutation(RESET_PASSWORD);

  const success = data?.sendPasswordReset?.success;

  const go = async () => {
    setValidating(true);
    try {
      if (email) {
        await resetPassword({ variables: { email } });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const Title = () => {
    return (
      <>
        <Typography variant="h1">{t('ForgotPassword.ForgottenPassword')}</Typography>
        <p>{t('ForgotPassword.PasswordReset')}</p>
      </>
    );
  };

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.header} xs={12} md={8}>
        <img src={Logo} alt="POURWALL" className={classes.logo} />
        <Hidden mdUp>
          <Title />
        </Hidden>
        <Hidden smDown>
          <div className={classes.product}>
            <div className={classes.productText}>
              <img alt="" src={Glassware} className={classes.glassware} />
              <h1 className={classes.productHeading}>{t('ForgotPassword.SimpleDigitalBeverageMenus')}</h1>
              <p className={classes.productDescription}>{t('ForgotPassword.CreatedForBars')}</p>
            </div>
            <img alt="" src={BarScene} className={classes.barScene} />
          </div>
        </Hidden>
      </Grid>
      <Grid item className={classes.content} xs={12} md={4}>
        <Hidden smDown>
          <Title />
        </Hidden>
        <form className={classes.form}>
          {!success && (
            <>
              <TextField
                variant="outlined"
                fullWidth
                label={t('ForgotPassword.Email')}
                error={validating && !email}
                type="email"
                className={classes.input}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button type="submit" className={classes.button} variant="contained" color="primary" onClick={go} disabled={loading}>
                {t('ForgotPassword.Reset')}
              </Button>
            </>
          )}
          {success && (
            <>
              <p>{t('ForgotPassword.FollowLink')}</p>
            </>
          )}
        </form>
        <div>
          <p>
            {t('ForgotPassword.ReturnTo')} <CustomLink href="/login">{t('ForgotPassword.Signin')}</CustomLink>.
          </p>
        </div>
      </Grid>
      <FeedbackBar message={error} />
    </Grid>
  );
};
