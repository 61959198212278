import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { CREATE_USER_VESSEL } from '../screens/userVessels/mutations';

import { GET_USER_VESSELS } from './queries';
import { SessionContext } from './session';

export const UserVesselsContext = createContext(null);

export const UserVesselsProvider = ({ children }) => {
  const [userVessels, setUserVessels] = useState([]);
  const { user } = useContext(SessionContext);

  const [doUserVessels, { loading, error, data }] = useLazyQuery(GET_USER_VESSELS, {
    fetchPolicy: 'network-only'
  });

  const [doCreateUserVessel, { loading: loadingCreate, data: createData }] = useMutation(CREATE_USER_VESSEL);

  const fetchUserVessels = () => {
    doUserVessels({
      variables: {
        id: user?._id
      }
    });
  };

  const createUserVessel = (userVessel) => {
    doCreateUserVessel({
      variables: {
        userVessel: {
          ...userVessel,
          createdBy: user?._id
        }
      }
    });
  };

  useEffect(() => {
    if (!loading && data?.userVessels && data?.userVessels !== userVessels) {
      setUserVessels(data.userVessels);
    }
  }, [loading, data, error, userVessels]);

  useEffect(
    () => {
      if (!loadingCreate && createData?.userVesselCreateOne) {
        userVessels.push(createData.userVesselCreateOne?.record);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [createData, loadingCreate]
  );

  useEffect(
    () => {
      fetchUserVessels();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <UserVesselsContext.Provider value={{ userVessels, fetchUserVessels, createUserVessel }}>{children}</UserVesselsContext.Provider>;
};
