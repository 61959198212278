import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { MenuPrintConfig } from './types';
import { getTapLocation } from '../../../util/tap';
import { UserVesselsContext } from '../../../contexts/userVessels';
import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../../util/lang';

const Servings: React.FC<{ tap: any; config: MenuPrintConfig }> = ({ tap, config: { theme, showServingSize, showServingCurrency } }) => {
  const classes = useStyles();
  const vesselsContext = useContext(UserVesselsContext) as any;

  return (
    <div className={classes.servingsContainer}>
      {tap.servings?.map((serving: any) => (
        <div key={serving._id} className={classes.servingContainer}>
          <p className={classes.servingName} style={{ color: theme.secondaryText }}>
            {resolveVesselName(serving.vessel, vesselsContext?.userVessels ?? []).toUpperCase()}{' '}
            {showServingSize ? `(${getServingSizeLabel(serving)})` : ''}
          </p>
          <p className={classes.servingPrice} style={{ color: theme.primaryText }}>
            {getServingPriceLabel(serving, showServingCurrency)}
          </p>
        </div>
      ))}
    </div>
  );
};

const VerticalTap: React.FC<{ tap: any; config: MenuPrintConfig; headerName: string; tapsGroupHeight: number }> = ({
  tap,
  config,
  headerName,
  tapsGroupHeight
}) => {
  const classes = useStyles();
  const { showDescription, showTapLocation, showHandle, showBreweryName, columnCount, showServings, theme } = config;
  const tapLocation = getTapLocation(tap);
  const breweryName = tap.beverage?.source?.name?.split('(')[0];
  const showServingsComponent = showServings && !!tap.servings?.length;
  const isGrouped = config.groupBy !== 'none';
  const containerStyle: any = { marginTop: headerName ? 20 : 0, borderLeftColor: theme.primary };
  if (!isGrouped) {
    containerStyle.marginLeft = 0;
    containerStyle.borderLeftColor = 'transparent';
  }

  return (
    <div className={classes.container} style={containerStyle}>
      {!!headerName && (
        <div className={classes.categoryHeader} style={{ width: `${tapsGroupHeight}px`, top: tapsGroupHeight - 20 }}>
          <span>{headerName}</span>
        </div>
      )}
      <div className={classes.row}>
        {showHandle && (
          <div className={classes.indexWrapper}>
            <p style={{ color: theme.primary }}>{tap.name}</p>
          </div>
        )}
        <div className={classes.contentContainer}>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={classes.titleContainer}>
              <p className={classes.tapName} style={{ color: theme.primaryText }}>
                {tap.beverage?.name}
              </p>
              {tap.showABV && (
                <>
                  <div className={classes.titleSeparator} style={{ backgroundColor: theme.primary }} />
                  <p className={classes.abv} style={{ color: theme.primaryText }}>
                    {tap.beverage?.abv}%
                  </p>
                </>
              )}
              {tap.showIBU && (
                <>
                  <div className={classes.titleSeparator} style={{ backgroundColor: theme.primary }} />
                  <p className={classes.abv} style={{ color: theme.primaryText }}>
                    {tap.beverage?.ibu} IBUs
                  </p>
                </>
              )}
            </div>
            {columnCount === 1 && showServingsComponent && <Servings tap={tap} config={config} />}
          </div>

          <div className={classes.breweryContainer}>
            {showBreweryName && (
              <p className={classes.breweryName} style={{ color: theme.primaryText }}>
                {breweryName}
              </p>
            )}
            {showTapLocation && (
              <p className={classes.breweryLocation} style={{ color: theme.secondaryText }}>
                {tapLocation}
              </p>
            )}
          </div>
          {columnCount !== 1 && showServingsComponent && <Servings tap={tap} config={config} />}
          {showDescription && (
            <p className={classes.description} style={{ color: theme.primaryText }}>
              {tap.beverage?.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    padding: '12px 30px 12px 8px',
    borderLeft: `1px solid #FD5C36`,
    marginLeft: 20
  },
  categoryHeader: {
    position: 'absolute',
    top: -20,
    transform: 'scale(-1) rotate(90deg)',
    transformOrigin: 'bottom left',
    textAlign: 'right',
    color: '#333',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 18,
    left: -4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxHeight: 20
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  indexWrapper: {
    minWidth: 12,
    marginTop: 3,
    marginRight: 8,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 'bold',
    color: '#FD5C36'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    marginBottom: 4
  },
  tapName: {
    fontWeight: 600,
    lineHeight: '17px',
    fontFamily: 'Bitter'
  },
  titleSeparator: {
    marginLeft: 8,
    marginRight: 8,
    color: '#FD5C36',
    width: 1,
    height: 10
  },
  abv: {
    fontSize: 12,
    textAlign: 'end',
    lineHeight: '17px'
  },
  breweryContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    flexWrap: 'wrap'
  },
  breweryName: {
    marginRight: 5,
    fontSize: 10
  },
  breweryLocation: {
    fontSize: 8,
    fontStyle: 'italic'
  },
  servingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    marginBottom: 4
  },
  servingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginRight: 8
  },
  servingName: {
    fontSize: 6,
    fontWeight: 600,
    marginRight: 4
  },
  servingPrice: {
    fontSize: 10,
    fontWeight: 600
  },
  description: {
    fontSize: 7,
    marginTop: 5
  }
}));

export default VerticalTap;
