import { useQuery } from 'react-apollo';
import { GET_LOCATION_BY_ID } from '../contexts/queries';
import { useEffect, useState } from 'react';

export const useIsPremiumByLocation = (locationId: string) => {
  const { data, loading } = useQuery(GET_LOCATION_BY_ID, {
    variables: { id: locationId },
    fetchPolicy: 'cache-and-network'
  });

  const [premium, setPremium] = useState(false);

  useEffect(() => {
    if (
      data?.location?.account?.plan === 'premium' &&
      (data?.location?.account?.status === 'active' || data?.location?.account?.status === 'trialing')
    ) {
      setPremium(true);
    } else {
      setPremium(false);
    }
  }, [data?.location?.account?.plan, data?.location?.account?.status]);

  return {
    premium,
    loading
  };
};
