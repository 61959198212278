import * as Sentry from '@sentry/react';
import * as ReactDOMClient from 'react-dom/client';
import App from './App';

import './index.css';
import './i18n';

const isRunningLocally = window.location.hostname === 'localhost';

if (!isRunningLocally) {
  Sentry.init({
    dsn: 'https://5a009b677b266a2b253c27b714f67f72@o559556.ingest.sentry.io/4506383302066176',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/^https:\/\/pourwall\.com/]
      }),
      new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container!);

root.render(<App />);
