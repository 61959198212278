import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, CircularProgress, Dialog, DialogContent, Grid, TextField, Typography, useMediaQuery, Zoom } from '@material-ui/core';
import React, { useReducer, useState } from 'react';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ActionBar } from './ActionBar';
import { spreadMutations } from '../util/graphql';
import { useMutation } from 'react-apollo';
import { CREATE_LOCATION, DELETE_LOCATION, EDIT_LOCATION } from '../screens/settings/mutations';
import { CurrencyDropdown } from './CurrencyDropdown';
import { VolumeUnitDropdown } from './VolumeUnitDropdown';
import { DeleteDialog } from './DeleteDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  sectionHeading: {
    marginTop: '20px'
  },
  formContainer: {
    paddingTop: '10px'
  }
}));

export const EditLocationDialog = ({ location, account, subDetail, onSuccess, onCancel, canDelete }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [name, setName] = useState(location?.name);
  const [currency, setCurrency] = useState(location?.servingSettings?.currency || 'USD');
  const [volumeUnit, setVolumeUnit] = useState(location?.servingSettings?.volumeUnit || 'oz');

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [validating, setValidating] = useState(false);
  const [isDelLoading, toggleDelLoading] = useReducer((state) => !state, false);
  const [isDoneLoading, toggleDoneLoading] = useReducer((state) => !state, false);

  const [mutationResults] = spreadMutations([useMutation(CREATE_LOCATION), useMutation(EDIT_LOCATION), useMutation(DELETE_LOCATION)]);

  const [createLocation, editLocation, deleteLocation] = mutationResults;

  const handleSave = async () => {
    toggleDoneLoading();
    setValidating(true);

    let locationData = {
      name,
      servingSettings: {
        currency,
        volumeUnit
      },
      shortName: '',
      active: true
    };

    try {
      if (name) {
        let newLocation = null;
        if (!location?._id) {
          const { data } = await createLocation({
            variables: {
              record: { ...locationData, account: account._id }
            }
          });
          newLocation = data?.locationCreateOne?.record;
        } else {
          await editLocation({
            variables: {
              record: { ...locationData, _id: location?._id }
            }
          });
        }
        onSuccess(newLocation);
      }
    } catch (error) {
      console.error('Failed to save location:', error);
    } finally {
      toggleDoneLoading();
    }
  };

  const onDelete = async () => {
    toggleDelLoading();
    setShowDeleteDialog(false);
    try {
      await deleteLocation({
        variables: {
          id: location._id
        }
      });
      onSuccess();
    } catch (error) {
      console.error('Failed to delete location:', error);
    } finally {
      toggleDelLoading();
    }
  };

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };
  const onCancelDelete = () => {
    setShowDeleteDialog(false);
  };

  const formatNewLocationDesc = (text) => {
    const subPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: subDetail?.currency || 'USD'
    }).format(subDetail?.unit_amount / 100);
    return text
      .replace('{sub_price}', subPrice)
      .replace('{sub_interval}', subDetail?.interval)
      .replace('{sub_period}', subDetail?.interval === 'year' ? 'Annual' : 'Monthly');
  };

  return (
    <Dialog open={location !== null} TransitionComponent={Transition} keepMounted fullScreen={fullScreen} onClose={onCancel}>
      <DialogContent>
        <>
          <Grid container>
            <Grid item xs={2}>
              <ArrowBack onClick={onCancel} className={'cursor-pointer'} />
            </Grid>
            <Grid item xs={8} className={classes.pageHeading}>
              {!location?._id ? t('LocationCard.NewLocationTitle') : t('LocationCard.EditLocationTitle')}
            </Grid>
          </Grid>
          <Grid container className={classes.formContainer} spacing={2} style={{ marginBottom: 100 }}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.sectionHeading}>
                {t('LocationCard.Basics')}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                variant="outlined"
                label={t('LocationCard.LocationName')}
                value={name}
                error={validating && !name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
              {validating && !name && (
                <Typography color="error" variant={'caption'} style={{ marginTop: 4 }}>
                  *{t('LocationCard.LocationNameError')}
                </Typography>
              )}
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">{t('LocationCard.DefaultSettings')}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyDropdown currency={currency} setCurrency={setCurrency} label={t('LocationCard.DefaultCurrency')} />
              </Grid>
              <Grid item xs={12} md={6}>
                <VolumeUnitDropdown label={t('LocationCard.DefaultVolumeUnit')} volumeUnit={volumeUnit} setVolumeUnit={setVolumeUnit} />
              </Grid>
            </Grid>
            {!location?._id && (
              <Grid item>
                <Typography>
                  {formatNewLocationDesc(t('LocationCard.NewLocationPriceDesc'))} <br />
                </Typography>
              </Grid>
            )}
          </Grid>
          <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
            <Grid container direction="row">
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button
                  variant={!location?._id ? 'text' : 'outlined'}
                  color={!location?._id ? 'default' : 'secondary'}
                  onClick={!location?._id ? onCancel : handleDelete}
                  style={{ marginRight: 8 }}
                  disabled={(location?._id && !canDelete) || isDelLoading}>
                  {isDelLoading ? (
                    <CircularProgress size={24} color={'inherit'} />
                  ) : !location?._id ? (
                    t('LocationCard.CancelLocationButton')
                  ) : (
                    t('LocationCard.DeleteLocationButton')
                  )}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave} disabled={isDoneLoading}>
                  {isDoneLoading ? (
                    <CircularProgress size={24} color={'inherit'} />
                  ) : !location?._id ? (
                    t('LocationCard.NewLocationButton')
                  ) : (
                    t('LocationCard.EditLocationButton')
                  )}
                </Button>
              </Grid>
            </Grid>
          </ActionBar>
        </>
      </DialogContent>
      {location._id && (
        <DeleteDialog
          show={showDeleteDialog}
          onCancel={onCancelDelete}
          title={t('LocationCard.DeleteDialogTitle')}
          description={t('LocationCard.DeleteDialogDesc')}
          onDelete={onDelete}
        />
      )}
    </Dialog>
  );
};
