import { Button, CircularProgress, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { ActionBar } from '../../components/ActionBar';
import { Layout } from '../../components/Layout';
import { SessionContext } from '../../contexts/session';
import { getMessageFromError, spreadMutations } from '../../util/graphql';

import { sortBy } from 'lodash';
import moment from 'moment-timezone';
import { FeedbackBar } from '../../components/FeedbackBar';
import { LanguageDropdown } from '../../components/LanguageDropdown';
import { EditLocationDialog } from '../../components/LocationDialog';
import PremiumFeatureDialog from '../../components/PremiumFeatureDialog';
import { GET_SUBSCRIPTION_DETAILS } from '../../contexts/queries';
import IconPremium from '../../images/icon-premium.svg';
import { PremiumYellow, LightGrey, MediumGrey, Red } from '../../theme';
import { LocationCard } from './LocationCard';
import { CHANGE_PASSWORD, DELETE_ACCOUNT, DELETE_LOCATION_MEMBER, EDIT_SUBSCRIPTION, UPDATE_ACCOUNT, UPDATE_LANGUAGE } from './mutations';
import { GET_USER } from './queries';
import { formatDate } from './utils';
import { MemberCard } from './MemberCard';
import { ManageLocationUsers } from './ManageLocationUsers';
import AddMemberDialog from './AddMemberDialog';
import { DeleteDialog } from '../../components/DeleteDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  saveButton: {
    flexGrow: 1,
    marginLeft: '20px'
  },
  dangerZone: {
    color: Red
  },
  loadingContainer: {
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    marginRight: '10px',
    cursor: 'pointer',
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${LightGrey}`,
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: `${MediumGrey}`
  },
  plusIcon: {
    fontSize: '64px',
    lineHeight: '64px',
    fontWeight: 400
  },
  addLocation: {
    minHeight: '240px',
    maxHeight: '100%'
  },
  failedPaymentBanner: {
    display: 'flex',
    border: `1px solid ${Red}`,
    borderRadius: '12px',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  failedPaymentBannerTitle: {
    backgroundColor: 'red',
    borderRadius: '8px',
    color: 'white',
    padding: '8px'
  }
}));

interface PlanCardProps {
  account: any;
  grandfathered: boolean;
}

const AddCard = ({
  title,
  nonPremiumMessage,
  onClick,
  nonPremium
}: {
  title: string;
  nonPremiumMessage: string;
  onClick: () => void;
  nonPremium: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={onClick}>
      {nonPremium && (
        <img
          alt="Icon Premium Feature"
          src={IconPremium}
          width={32}
          height={32}
          className="absolute top-[6px] right-[6px] rotate-[35deg]"
        />
      )}
      <p className={classes.plusIcon}>+</p>
      <p>{title}</p>
      {nonPremium && <p style={{ color: PremiumYellow }}>{nonPremiumMessage}</p>}
    </div>
  );
};

const PlanCard = ({ account, grandfathered }: PlanCardProps) => {
  const { t, i18n } = useTranslation();

  const [editSubscription, { loading, data }] = useMutation(EDIT_SUBSCRIPTION);

  useEffect(() => {
    if (data?.editSubscription?.stripePortalUrl) {
      window.location.href = data?.editSubscription?.stripePortalUrl;
    }
  }, [data]);

  const handleCreateSubscription = async () => {
    if ((account?.plan === 'premium' && !grandfathered) || account?.pendingSubscriptionPayment) {
      await editSubscription();
    } else {
      window.location.pathname = '/account/subscription';
    }
  };

  return (
    <div className="mx-5 my-8 w-auto md:w-1/2">
      <h2 className=" font-unica font-bold text-xl mb-1">{t('Account.CurrentPlan')}</h2>
      {(account?.plan !== 'premium' || grandfathered) && !account?.pendingSubscriptionPayment && (
        <>
          <h3 className="font-bold text-2xl">{t(`Plans.Basic${grandfathered ? 'Grandfathered' : ''}`)}</h3>
          <p className="mb-4">
            {t(`Plans.BasicDescription${grandfathered ? 'Grandfathered' : ''}${account?.elegibleForFreeTrial ? 'FreeTrial' : ''}`)}
          </p>
          <Button variant="contained" color="primary" onClick={handleCreateSubscription}>
            {account.elegibleForFreeTrial ? t('Account.ButtonStartFreeTrial') : t('Account.ButtonUpgrade')}
          </Button>
        </>
      )}

      {account?.plan === 'premium' && !grandfathered && !account.datePlanCancels && (
        <>
          <h3 className="font-bold text-2xl">{t('Plans.Premium')}</h3>
          <p className="mb-4">{t('Plans.PremiumDescription')}</p>
          <Button variant="contained" color="primary" onClick={handleCreateSubscription} disabled={loading}>
            {t('Account.ButtonEditPlan')}
          </Button>
        </>
      )}

      {account?.pendingSubscriptionPayment && (
        <>
          <h3 className="font-bold text-2xl">{t('Plans.Basic')}</h3>
          <p className="text-dark-orange mb-4">
            {t('Plans.PremiumDescriptionExpired', { date: formatDate(account.pendingSubscriptionPayment, i18n.language) })}
          </p>
          <Button variant="contained" color="primary" onClick={handleCreateSubscription} disabled={loading}>
            {t('Account.ButtonEditPlan')}
          </Button>
        </>
      )}

      {account?.plan === 'premium' && !grandfathered && account.datePlanCancels && (
        <>
          <h3 className="font-bold text-2xl">
            {t('Plans.Premium')} - <span className=" text-dark-orange">{t('Plans.Expiring')}</span>
          </h3>
          <p className="mb-4">
            {t('Plans.PremiumDescriptionExpiring', {
              date: formatDate(account.datePlanCancels, i18n.language, moment(account.datePlanCancels).isAfter(moment().add(1, 'month')))
            })}
          </p>
          <Button variant="contained" color="primary" onClick={handleCreateSubscription} disabled={loading}>
            {t('Account.ButtonEditPlan')}
          </Button>
        </>
      )}
    </div>
  );
};

export const AccountSettings = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { user, account, locations, locLoading, locRefetch, accountRefetch, logout, premium, isOwner } = useContext(SessionContext);

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [showDeleteAccountConfirmation, setShowDeleteAccountConfirmation] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<any>(undefined);
  const [validating, setValidating] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [editLocation, setEditLocation] = useState<any>(null);
  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);
  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);
  const {
    loading: getUserLoading,
    error,
    data
  } = useQuery(GET_USER, {
    variables: { id: user?._id },
    fetchPolicy: 'cache-and-network'
  });

  const { data: subsDetailData } = useQuery(GET_SUBSCRIPTION_DETAILS, {
    variables: { id: account?._id },
    fetchPolicy: 'cache-and-network',
    skip: !account?._id
  });

  const [deleteLocationMember, { loading: deleteMemberLoading }] = useMutation(DELETE_LOCATION_MEMBER);

  const [mutationResults, status] = spreadMutations([
    useMutation(UPDATE_ACCOUNT),
    useMutation(CHANGE_PASSWORD),
    useMutation(DELETE_ACCOUNT),
    useMutation(UPDATE_LANGUAGE)
  ]);

  const [updateAccount, changePassword, deleteAccount, updateLanguage] = mutationResults as any[];
  const { loading } = status as any;

  const handleSave = async () => {
    setValidating(true);
    if (firstName && lastName && email) {
      try {
        await updateAccount({
          variables: {
            firstName,
            lastName,
            email
          }
        });
        if (password && confirmPassword && password === confirmPassword) {
          await changePassword({ variables: { password } });
        }
        await updateLanguage({ variables: { language } });
      } catch (e) {
        setErrorMessage(getMessageFromError(e));
        console.log(e, getMessageFromError(e));
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && data && data.user) {
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
      setEmail(data.user.local.email);
    }

    return () => {
      isMounted = false;
    };
  }, [data]);

  const handleDeleteAccount = async () => {
    const {
      data: {
        deleteAccount: { success }
      }
    } = await deleteAccount();
    if (success) {
      logout();
      window.location.pathname = '/login';
    }
  };

  const handleLanguage = async (lang: string) => {
    await i18n.changeLanguage(lang);
    moment.locale(lang);
    setLanguage(lang);
  };

  const addLocation = () => {
    if (premium && !subsDetailData?.subscription?.grandfathered) {
      let location = {
        _id: null,
        name: ''
      };

      setEditLocation(location);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const addMember = () => {
    if (premium && !subsDetailData?.subscription?.grandfathered) {
      setShowAddMemberDialog(true);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const [managedUserEmail, setManagedUserEmail] = useState<string | null>(null);

  const manageLocations = (email: string) => {
    if (premium && !subsDetailData?.subscription?.grandfathered) {
      setManagedUserEmail(email);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const onRemoveMember = (email: string) => {
    setMemberToDelete(email);
  };

  const handleDeleteMember = async (email: string) => {
    const result = await deleteLocationMember({
      variables: {
        locationIds: account.users.find((u: any) => u.user.local.email === email).locations,
        email: email
      }
    });
    if (result.data.deleteMemberUser.success) {
      setMemberToDelete(undefined);
      await accountRefetch();
    }
  };

  const handleLogOut = async () => {
    logout();
    window.location.pathname = '/login';
  };

  const onSuccess = async () => {
    await locRefetch();

    setEditLocation(null);
  };

  return (
    <>
      <Layout title={t('Account.Settings')} className="mb-24">
        <FeedbackBar message={errorMessage} />
        <Grid container className={classes.root} spacing={2}>
          {account?.pendingSubscriptionPayment && (
            <Grid className={classes.failedPaymentBanner} item xs={12}>
              <Typography className={classes.failedPaymentBannerTitle} variant="h1">
                {t('Plans.BasicChargeFailed')}
              </Typography>
              <Typography variant="h2">{t('Plans.PaymentFailedWarning')}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h2">{t('Account.Basics')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('Account.FirstName')}
              value={firstName}
              error={validating && !firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('Account.LastName')}
              value={lastName}
              error={validating && !lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('Account.Email')}
              value={email}
              error={validating && !email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">{t('Account.AppLanguage')}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <LanguageDropdown onChange={handleLanguage} />
          </Grid>
        </Grid>

        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">{t('Account.Security')}</Typography>
            <p>{t('Account.ChangePassword')}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('Account.NewPassword')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label={t('Account.ConfirmNewPassword')}
              value={confirmPassword}
              error={!!password && (!confirmPassword || password !== confirmPassword)}
              helperText={password && confirmPassword && password !== confirmPassword ? t('Account.PasswordsDontMatch') : undefined}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>

        <FeedbackBar message={errorMessage} />
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h2">{t('Account.LocationsTitle')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {locLoading ? (
                <Grid item xs={12} className={classes.loadingContainer}>
                  <CircularProgress />
                </Grid>
              ) : (
                sortBy(locations, (l) => l.name).map((l, i) => (
                  <Grid item xs={12} sm={6} md={4} key={i}>
                    <LocationCard key={i} location={l} onEdit={() => setEditLocation(l)} />
                  </Grid>
                ))
              )}

              {isOwner && (
                <Grid item xs={12} sm={6} md={4} className={classes.addLocation}>
                  <AddCard
                    title={t('Account.NewLocationCardTitle')}
                    nonPremiumMessage={t('Account.NewLocationCardPremium')}
                    onClick={addLocation}
                    nonPremium={!premium || subsDetailData?.subscription?.grandfathered}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {isOwner && (
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h2">{t('Account.Members')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {premium
                  ? account?.users
                      .filter((member: any) => {
                        const everyoneButCurrentUser = !!member.user && member.user.local.email !== user.local.email;
                        return everyoneButCurrentUser;
                      })
                      .map((u: any, i: number) => (
                        <Grid item xs={12} sm={6} md={4} key={i}>
                          <MemberCard
                            key={i}
                            member={u}
                            deleteMemberLoading={deleteMemberLoading}
                            onManageLocations={() => manageLocations(u.user.local.email)}
                            onRemove={() => onRemoveMember(u.user.local.email)}
                          />
                        </Grid>
                      ))
                  : null}
                {isOwner && (
                  <Grid item xs={12} sm={6} md={4} className={classes.addLocation}>
                    <AddCard
                      title={t('Account.AddMember')}
                      nonPremiumMessage={t('Account.NewLocationCardPremium')}
                      onClick={addMember}
                      nonPremium={!premium || subsDetailData?.subscription?.grandfathered}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}

        {account && isOwner && <PlanCard account={account} grandfathered={subsDetailData?.subscription?.grandfathered} />}

        <Grid container className={classes.root} spacing={2} style={{ marginBottom: 100 }}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.dangerZone}>
              {t('Account.DangerZone')}
            </Typography>
            <p className={classes.dangerZone}>{t('Account.DeleteAccount')}</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="outlined" color="secondary" onClick={() => setShowDeleteAccountConfirmation(true)}>
              {t('Account.DeleteMyAccount')}
            </Button>
          </Grid>
          <DeleteDialog
            show={showDeleteAccountConfirmation}
            onCancel={() => setShowDeleteAccountConfirmation(false)}
            onDelete={handleDeleteAccount}
            title={t('Account.PermanentlyDeleteAccountTitle')}
            description={t('Account.PermanentlyDeleteAccount')}
          />
        </Grid>
        <DeleteDialog
          show={!!memberToDelete}
          onCancel={() => setMemberToDelete(false)}
          onDelete={() => handleDeleteMember(memberToDelete)}
          title={t('Account.PermanentlyDeleteAccountTitle')}
          description={t('Account.PermanentlyDeleteMemberAccount', { memberEmail: memberToDelete })}
        />
        <ActionBar dialog={undefined} loading={loading || getUserLoading}>
          <Button variant="contained" color="secondary" onClick={handleLogOut}>
            {t('Account.LogOut')}
          </Button>
          <Button variant="contained" color="primary" className={classes.saveButton} disabled={loading} onClick={handleSave}>
            {t('Account.Save')}
          </Button>
        </ActionBar>
        <FeedbackBar message={error} />
      </Layout>
      {managedUserEmail && <ManageLocationUsers userEmail={managedUserEmail} onClose={() => setManagedUserEmail(null)} />}
      <AddMemberDialog
        open={showAddMemberDialog}
        onClose={() => setShowAddMemberDialog(false)}
        onAdd={() => setShowAddMemberDialog(false)}
      />
      {editLocation && (
        <EditLocationDialog
          location={editLocation}
          account={account}
          subDetail={subsDetailData?.subscription}
          onCancel={() => setEditLocation(null)}
          onSuccess={onSuccess}
          canDelete={locations.length > 1}
        />
      )}
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureDescription={t('PremiumFeatureDialog.Features.MultiLocation')}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </>
  );
};
