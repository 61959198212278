import { makeStyles } from '@material-ui/core';
import ForwardSlashIcon from '../../../images/icon-forward-slash';

interface ClassicFooterProps {
  content: Array<string>;
  config?: {
    textColor?: string;
    separatorColor?: string;
  };
}

const ClassicFooter = ({ content, config }: ClassicFooterProps) => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.centeredContainer}>
        {content.map((item, index) => {
          const renderSeparator = index < content.length - 1;

          return (
            <div key={index} className={classes.footerElement}>
              <p className={classes.footerElementText} style={{ color: config?.textColor || '#656A6E' }}>
                {item}
              </p>
              {renderSeparator && (
                <div className={classes.slashWrapper}>
                  <ForwardSlashIcon color={config?.separatorColor} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClassicFooter;

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    width: '100%',
    height: 28
  },
  centeredContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerElement: {
    display: 'flex',
    flexDirection: 'row'
  },
  footerElementText: {
    margin: '0 8px'
  },
  slashWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}));
