import gql from 'graphql-tag';

import { USER_VESSEL_FIELDS } from '../../data/graphql';

export const DELETE_USER_VESSEL = gql`
    mutation($id:MongoID!){
        userVesselRemoveById(_id:$id){
            record {
                ${USER_VESSEL_FIELDS}
            }
        }
    }
`;

export const CREATE_USER_VESSEL = gql`
    mutation($userVessel: CreateOneUserVesselInput!){
        userVesselCreateOne(record:$userVessel){
            record {
                ${USER_VESSEL_FIELDS}
            }
        }
    }
`;
