import React, { useState, useEffect } from 'react';

import { HashLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import Logo from '../../../images/pourwall-logo.svg';
import { Orange } from '../../../theme';

import { DEVICE_PING } from '../mutations';

const useStyles = makeStyles((theme) => ({
  main: {},
  h1: {
    fontSize: '2.5rem',
    fontFamily: '"Open Sans", Roboto, sans-serif',
    lineHeight: '1.25',
    fontWeight: '400',
    margin: '0',
    textRendering: 'optimizeLegibility'
  },
  deviceRegistration: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '50px',
    backgroundColor: '#4C4C4C',
    color: 'white',
    '& > p': {
      margin: 0
    }
  },
  logo: {
    paddingBottom: '50px'
  },

  a: {
    color: Orange,
    textDecoration: 'none'
  },

  registrationBox: {
    display: 'flex',
    alignItems: 'center',
    '& > .loading': {
      marginRight: '30px'
    }
  },
  registrationCode: {
    fontSize: '9em'
  },

  linkDeviceLoading: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: 30
  },

  deviceIcon: {
    //@include span-columns(1.75);
  },

  deviceMeta: {
    //@include span-columns(10);
  },
  itemOptions: {
    '& > button': {
      width: 'auto'
    }
  }
}));

export const DeviceRegistration = ({ deviceType, code, onDeviceUpdated }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [registrationCode, setRegistrationCode] = useState(code);

  const [pingDevice] = useMutation(DEVICE_PING);

  if (!registrationCode) {
    // generate key
    const chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'; // no zeros or o's
    let code = '';
    for (var i = 0; i < 4; i++) {
      code += chars[Math.floor(Math.random() * chars.length)];
    }

    setRegistrationCode(code);
  }

  useEffect(() => {
    const handlePing = async () => {
      const { data } = await pingDevice({
        variables: { code: registrationCode, deviceType }
      });

      // check if the device is now linked to a screen
      if (data?.device) {
        onDeviceUpdated(data?.device);
      }
    };
    if (code) {
      handlePing();
    }
    let timer = setInterval(handlePing, 5000);

    return () => clearTimeout(timer);
  }, [code, pingDevice, registrationCode, deviceType, onDeviceUpdated]);

  const linkUrl = `https://${window.location.host}/link`;

  return (
    <div className={classes.deviceRegistration}>
      <img src={Logo} className={classes.logo} alt="POURWALL" />

      <h1 className={classes.h1}>{t('Display.EnterCode')}</h1>
      <p>
        {t('Display.OrVisit')}{' '}
        <a href={linkUrl} className={classes.a}>
          {linkUrl}
        </a>{' '}
        {t('Display.FromAnotherDevice')}
      </p>

      <div className={classes.registrationBox}>
        <div className={classes.linkDeviceLoading}>
          <HashLoader color={'#FD643F'} />
        </div>
        <div className={classes.registrationCode}>{registrationCode}</div>
      </div>
    </div>
  );
};
