export const MAX_SCREEN_IMAGE_SIZE_BYTES = 500000;
export const MAX_TAP_IMAGE_SIZE_BYTES = 50000;

export const humanReadableSize = function (size) {
  let maxImageSizeUnit = 'MB';
  let maxImageSize = size / 1000000;
  if (maxImageSize < 1) {
    maxImageSize = maxImageSize * 1000;
    maxImageSizeUnit = 'KB';
  }
  return `${maxImageSize} ${maxImageSizeUnit}`;
};

export const checkFileSize = function (file, sizeLimit) {
  if (file.size > sizeLimit) {
    return new Error(`Image file size must be smaller than ${humanReadableSize(sizeLimit)}.`);
  }
};

export const uploadToAws = async (signedAwsUrl, file, cacheDurationInSeconds = 31536000) => {
  let headers = {
    'Content-Type': file.type,
    'Cache-Control': 'max-age=' + cacheDurationInSeconds
  };

  await fetch(signedAwsUrl, { headers, method: 'PUT', body: file });
};
