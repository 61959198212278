import React from 'react';
import clsx from 'clsx';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'outlined' | 'default';
  selected?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Card = ({ variant = 'default', selected = false, className, children, ...props }: CardProps) => {
  return (
    <div
      className={clsx(
        'rounded-lg relative transition-all duration-200',
        {
          'border-1': variant === 'outlined',
          'border-accent-grey hover:border-green border-1 hover:border-2': variant === 'outlined' && !selected,
          'border-green border-2': selected,
          'bg-white': variant === 'default'
        },
        className
      )}
      {...props}>
      {selected && (
        <div className="absolute -top-2 -right-[10px] w-6 h-6 bg-green rounded-full flex items-center justify-center">
          <svg width="10" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L4.75 9.25L1 5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      )}
      {children}
    </div>
  );
};

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

Card.Content = function CardContent({ className, children, ...props }: CardContentProps) {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};
