import React from 'react';
import { DarkOrange } from '../theme';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: DarkOrange,
    cursor: 'pointer'
  }
}));

const CustomLink = ({ ...props }) => {
  const classes = useStyles();

  return <Link {...props} style={{ ...props.style }} className={classes.link} underline={'always'} />;
};

export default CustomLink;
