export function reconnectingWebSocket(url) {
  let client;
  let isConnected = false;
  let reconnectOnClose = true;
  let messageListeners = [];
  let stateChangeListeners = [];

  function on(fn) {
    messageListeners.push(fn);
    return () => off(fn);
  }

  function off(fn) {
    messageListeners = messageListeners.filter((l) => l !== fn);
  }

  function onStateChange(fn) {
    stateChangeListeners.push(fn);
    return () => {
      stateChangeListeners = stateChangeListeners.filter((l) => l !== fn);
    };
  }

  function start() {
    client = new WebSocket(url);

    const logSocketState = (prefix, event) => {
      let msg;
      switch (client.readyState) {
        case WebSocket.CLOSED:
          msg = 'CLOSED';
          break;
        case WebSocket.CLOSING:
          msg = 'CLOSING';
          break;
        case WebSocket.CONNECTING:
          msg = 'CONNECTING';
          break;
        case WebSocket.OPEN:
          msg = 'OPEN';
          break;
        default:
          msg = `unknown: ${client.readyState}`;
          break;
      }
      console.log(prefix || 'WebSocket', msg, JSON.stringify(event, null, 2));
    };

    client.onopen = (event) => {
      logSocketState('WebSocket.onopen', event);
      isConnected = true;
      stateChangeListeners.forEach((fn) => fn(true));
    };

    // Close without reconnecting;
    client.closeSocket = () => {
      console.log('WebSocket closing without reconnect');
      reconnectOnClose = false;
      client.close();
    };

    client.onmessage = (event) => {
      messageListeners.forEach((fn) => fn(event.data));
    };

    client.onerror = (event) => {
      logSocketState('WebSocket.onerror', event);
    };

    client.onclose = (event) => {
      logSocketState('WebSocket.onclose', event);

      isConnected = false;
      stateChangeListeners.forEach((fn) => fn(false));

      if (!reconnectOnClose) {
        console.log('WebSocket closed by the app');
        return;
      }

      console.log('WebSocket closed by the server');

      setTimeout(start, 3000);
    };
  }

  start();

  return {
    on,
    off,
    onStateChange,
    closeSocket: () => client.close(),
    getClient: () => client,
    isConnected: () => isConnected
  };
}
