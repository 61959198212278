import React, { useState } from 'react';
import { Button, Grid, Hidden, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../../images/pourwall-logo.svg';
import BarScene from '../../images/bar-scene.svg';
import Glassware from '../../images/glassware.svg';

import style from './styles';
import { UPDATE_PASSWORD } from './mutations';
import { FeedbackBar } from '../../components/FeedbackBar';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles(style);

export const ResetPassword = withRouter(({ match }) => {
  const { t } = useTranslation();
  const token = match?.params?.token;

  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [helpText, setHelpText] = useState('');
  const [validating, setValidating] = useState(false);

  const [resetPasswordWithToken, { loading, error, data }] = useMutation(UPDATE_PASSWORD);

  const success = data?.resetPasswordWithToken?.success;

  const go = async () => {
    setValidating(true);
    setHelpText('');
    try {
      if (password && confirmPassword && token) {
        if (password === confirmPassword) await resetPasswordWithToken({ variables: { token, password } });
        else setHelpText(`Your passwords don't match`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const Title = () => {
    return (
      <>
        <Typography variant="h1">{t('ResetPassword.ResetYourPassword')}</Typography>
        <p>{t('ResetPassword.CreateNewPassword')}</p>
      </>
    );
  };

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.header} xs={12} md={8}>
        <img src={Logo} alt="POURWALL" className={classes.logo} />
        <Hidden mdUp>
          <Title />
        </Hidden>
        <Hidden smDown>
          <div className={classes.product}>
            <div className={classes.productText}>
              <img alt="" src={Glassware} className={classes.glassware} />
              <h1 className={classes.productHeading}>{t('ResetPassword.SimpleDigitalBeverageMenus')}</h1>
              <p className={classes.productDescription}>{t('ResetPassword.CreatedForBars')}</p>
            </div>
            <img alt="" src={BarScene} className={classes.barScene} />
          </div>
        </Hidden>
      </Grid>
      <Grid item className={classes.content} xs={12} md={4}>
        <Hidden smDown>
          <Title />
        </Hidden>
        {!success && (
          <form className={classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              label={t('ResetPassword.NewPassword')}
              error={validating && !password}
              type="password"
              className={classes.input}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              fullWidth
              label={t('ResetPassword.ConfirmPassword')}
              error={validating && (!confirmPassword || !!helpText)}
              type="password"
              className={classes.input}
              onChange={(e) => setConfirmPassword(e.target.value)}
              helperText={helpText}
            />
            <Button className={classes.button} variant="contained" color="primary" onClick={go} disabled={loading}>
              {t('ResetPassword.UpdatePassword')}
            </Button>
          </form>
        )}
        {success && (
          <p style={{ padding: '20px 0 120px 0' }}>
            {t('ResetPassword.PasswordUpdated')} <CustomLink href="/login">{t('ResetPassword.Login')}</CustomLink>{' '}
            {t('ResetPassword.WithNewPassword')}
          </p>
        )}
        <div>
          <p>
            {t('ResetPassword.NoAccountYet')} <CustomLink href="/signup">{t('ResetPassword.SignUp')}</CustomLink>.
          </p>
        </div>
      </Grid>
      <FeedbackBar message={error} />
    </Grid>
  );
});
