import React, { useContext, useImperativeHandle, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  Slide,
  TextField,
  useMediaQuery,
  useTheme,
  IconButton
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { ActionBar } from '../../../components/ActionBar';
import { getVesselLabel, resolveVesselSize, Vessels } from '../../../util/lang';
import { SessionContext } from '../../../contexts/session';
import { UserVesselsContext } from '../../../contexts/userVessels';
import { getCurrencySign } from '../../../util/currencies';
import { CurrencyDropdown } from '../../../components/CurrencyDropdown';
import { VolumeUnitDropdown } from '../../../components/VolumeUnitDropdown';
import { DeleteDialog } from '../../../components/DeleteDialog';
import { DELETE_USER_VESSEL } from '../../userVessels/mutations';
import { useMutation } from 'react-apollo';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  list: {
    border: '4px solid rgba(224, 224, 224, 1)'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const filter = createFilterOptions();

export const EditServingDialog = ({ serving, onCancel, onSave, onDelete = null }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  if (!serving) return null;

  return (
    <Dialog open={serving !== null} TransitionComponent={Transition} keepMounted fullScreen={fullScreen} onClose={onCancel}>
      <DialogContent>
        <Grid container>
          <Grid item xs={2}>
            <ArrowBack onClick={onCancel} />
          </Grid>
          <Grid item xs={8} className={classes.pageHeading}>
            {serving._id ? t('Serving.EditServing') : t('Serving.NewServing')}
          </Grid>
        </Grid>
        {serving && <EditServingForm serving={serving} onSave={onSave} onDelete={onDelete} />}
      </DialogContent>
    </Dialog>
  );
};

export const EditServingForm = React.forwardRef(({ serving, onSave, onDelete, showActions = true }, ref) => {
  const { currentLocation } = useContext(SessionContext);
  const { t } = useTranslation();

  const { userVessels } = useContext(UserVesselsContext);

  const classes = useStyles();
  const [vessel, setVessel] = useState(serving.vessel ? { name: serving.vessel, size: serving.size } : '');
  const [size, setSize] = useState(serving.size || '');
  const [price, setPrice] = useState(serving.price || '');
  const [currency, setCurrency] = useState(serving?.settings?.currency || currentLocation?.servingSettings?.currency);
  const [volumeUnit, setVolumeUnit] = useState(serving?.settings?.volumeUnit || currentLocation?.servingSettings?.volumeUnit);
  const [validating, setValidating] = useState(false);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [vesselToDelete, setVesselToDelete] = useState(null);

  const handleSave = async () => {
    setValidating(true);
    if (vessel && size && price != null && price !== '') {
      // prepare and return the updated serving
      let updatedItem = {
        ...serving,
        vessel: vessel.name,
        isNew: !!vessel.isNew,
        size,
        price,
        settings: { volumeUnit, currency }
      };

      onSave?.(updatedItem);
      return updatedItem;
    }
  };

  useImperativeHandle(ref, () => ({
    handleSave
  }));

  const handleDelete = () => {
    onDelete();
  };

  const selectVessel = (vessel) => {
    setVessel(vessel);
    if (vessel.size) {
      setSize(resolveVesselSize(vessel, volumeUnit));
    }
  };

  const selectVolumeUnit = (volumeUnit) => {
    setVolumeUnit(volumeUnit);
    setSize(resolveVesselSize(vessel, volumeUnit));
  };

  const options = useMemo(() => {
    const formattedUserVessels = userVessels?.map(({ _id, name, size }) => {
      return {
        _id,
        name,
        size,
        type: 'Custom'
      };
    });
    return Vessels.concat(formattedUserVessels);
  }, [userVessels]);

  const onCancelDelete = () => {
    setShowDeleteDialog(false);
    setVesselToDelete(null);
  };

  const [doDeleteUserVessel] = useMutation(DELETE_USER_VESSEL);

  const onDeleteVessel = async () => {
    if (vesselToDelete) {
      try {
        await doDeleteUserVessel({
          variables: {
            id: vesselToDelete._id
          }
        });
      } catch (error) {
        console.error('Failed to delete vessel:', error);
      } finally {
        options.splice(options.indexOf(vesselToDelete), 1);
      }
    }
    onCancelDelete();
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 80 }}>
      <Grid item xs={12}>
        <FormControl error={validating && !vessel} variant="outlined" fullWidth>
          <Autocomplete
            freeSolo
            selectOnFocus
            value={getVesselLabel(vessel, volumeUnit)}
            options={options}
            classes={{ listbox: classes.list }}
            groupBy={(option) => {
              return option.type;
            }}
            getOptionLabel={(option) => {
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.name;
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  name: t('Serving.AddVessel', { vessel: params.inputValue }),
                  type: 'Custom',
                  isNew: true
                });
              }

              return filtered;
            }}
            onChange={(_, newValue) => {
              if (newValue === null || newValue === undefined) {
                setVessel('');
                return;
              }
              if (typeof newValue === 'string') {
                selectVessel({
                  name: newValue,
                  type: 'Custom'
                });
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                selectVessel({
                  name: newValue.inputValue,
                  type: 'Custom',
                  isNew: true
                });
              } else {
                selectVessel(newValue);
              }
            }}
            renderOption={(option) => (
              <li style={{ position: 'relative', width: '100%' }}>
                {option.type === 'Custom' && !option.isNew && (
                  <IconButton
                    style={{ zIndex: 'calc(infinity)', position: 'absolute', right: 0, top: -12 }}
                    edge="end"
                    onClick={(event) => {
                      event.stopPropagation();
                      setVesselToDelete(option);
                      setShowDeleteDialog(true);
                    }}>
                    <Delete />
                  </IconButton>
                )}
                {getVesselLabel(option, volumeUnit)}
              </li>
            )}
            renderInput={(params) => (
              <TextField error={validating && !vessel} {...params} label={t('Serving.VesselType')} variant="outlined" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <VolumeUnitDropdown volumeUnit={volumeUnit} setVolumeUnit={selectVolumeUnit} label={t('Serving.VolumeUnit')} />
      </Grid>
      <Grid item xs={6}>
        <CurrencyDropdown currency={currency} setCurrency={setCurrency} label={t('Serving.Currency')} />
      </Grid>
      <Grid item xs={6}>
        <TextField
          error={validating && !size}
          variant="outlined"
          fullWidth
          type="number"
          label={t('Serving.Size')}
          value={size}
          onChange={(e) => {
            if (e.target.value.trim() !== '') {
              setSize(Number(e.target.value));
            } else {
              setSize('');
            }
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{volumeUnit}.</InputAdornment>
          }}
          className={classes.formControl}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={price}
          type="number"
          label={t('Serving.Price')}
          variant="outlined"
          className={classes.formControl}
          error={validating && (price == null || price === '')}
          onChange={(e) => {
            if (e.target.value.trim() !== '') {
              setPrice(Number(e.target.value));
            } else {
              setPrice('');
            }
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySign(currency)}</InputAdornment>
          }}
        />
      </Grid>
      {showActions && (
        <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
          <Grid container direction="row">
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              {onDelete && (
                <Button variant="text" color="secondary" onClick={handleDelete}>
                  {t('Serving.Delete')}
                </Button>
              )}
              <Button variant="text" color="primary" onClick={handleSave}>
                {t('Serving.Save')}
              </Button>
            </Grid>
          </Grid>
        </ActionBar>
      )}
      <DeleteDialog
        show={showDeleteDialog}
        onCancel={onCancelDelete}
        title={t('Serving.DeleteCustomVesselTitle')}
        description={t('Serving.DeleteCustomVesselDesc')}
        onDelete={onDeleteVessel}
      />
    </Grid>
  );
});
