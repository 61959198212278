import gql from 'graphql-tag';

export const RESET_PASSWORD = gql`
  mutation ($email: String!) {
    sendPasswordReset(email: $email) {
      success
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation ($token: String!, $password: String!) {
    resetPasswordWithToken(token: $token, password: $password) {
      success
    }
  }
`;
