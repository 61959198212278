import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Green, FontFamilyUnicaOne, Red } from '../../theme';
import { BarLoader } from 'react-spinners';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1.5rem',
    borderBottom: '1px solid #ddd',
    marginBottom: '15px'
  },
  clickContainer: {
    cursor: 'pointer'
  },
  itemName: {
    fontWeight: '800',
    fontSize: '24px',
    lineHeight: '1.45',
    marginBottom: '5px'
  },
  itemSource: {
    fontSize: '20px',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    marginBottom: '4px'
  },

  deviceStatusDisconnected: {
    display: 'inline-block',
    height: 10,
    width: 10,
    borderRadius: 20,
    backgroundColor: Red,
    marginRight: 10
  },
  deviceStatusConnected: {
    color: Green,
    display: 'flex',
    alignItems: 'center',

    '& span': {
      marginLeft: 10
    },

    '& .dot': {
      display: 'inlineBlock',
      backgroundColor: '$green',
      borderRadius: '50px',
      width: '12px',
      height: '12px',
      marginRight: '10px'
    },

    '& img': {
      height: '25px',
      marginRight: 15
    }
  },

  lastUpdated: {
    fontSize: '0.8em',
    textTransform: 'uppercase',
    opacity: 0.6,
    fontFamily: FontFamilyUnicaOne,
    marginTop: 5
  }
}));

export const ScreenListItem = ({ screen, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} onClick={() => onClick()} className={classes.clickContainer}>
        <div className={classes.itemName}>{screen.name}</div>
        <div className={classes.itemSource}>
          {screen.pages.length} {t('ScreenListItem.Pages', { count: screen.pages.length })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <DeviceStatus device={screen.device} />
      </Grid>
    </Grid>
  );
};

const DeviceStatus = ({ device }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [lastSeen, setLastSeen] = useState('Never');
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const updateLastSeen = () => {
      if (device && device.lastHeartbeat) {
        let heartbeat = moment(device.lastHeartbeat);
        heartbeat.locale(i18n.language);
        setLastSeen(heartbeat.fromNow());
        // assume if we haven't heard from the device in 5 minutes, we're not connected anymore
        if (moment().diff(heartbeat, 'minutes') <= 5) {
          setConnected(true);
        } else {
          setConnected(false);
        }
      }
    };

    updateLastSeen();
    const interval = setInterval(() => updateLastSeen(), 10000);

    return () => clearInterval(interval);
  }, [device, i18n.language]);

  return (
    <>
      {!connected && (
        <>
          <div className="status-disconnected">
            <span className={classes.deviceStatusDisconnected}></span>
            <span>{t('ScreenListItem.Disconnected')}</span>
          </div>
          <div className={classes.lastUpdated}>
            {t('ScreenListItem.LastSeen')} {lastSeen || t('ScreenListItem.Never')}
          </div>
        </>
      )}
      {connected && (
        <>
          <div className={classes.deviceStatusConnected}>
            <BarLoader color={'#39CA74'} width={10} />
            <span>{t('ScreenListItem.Connected')}</span>
          </div>
          <div className={classes.lastUpdated}>
            {t('ScreenListItem.LastSeen')} {lastSeen}
          </div>
        </>
      )}
    </>
  );
};
