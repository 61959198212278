interface EmbedIconProps {
  className?: string;
}

const EmbedIcon = ({ className }: EmbedIconProps) => (
  <svg className={className} width="24" height="14" viewBox="0 0 24 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.74792 1.56251L2.62511 7.00001L8.74417 12.4375C8.81804 12.5029 8.87831 12.5822 8.92154 12.6708C8.96477 12.7595 8.99011 12.8558 8.99612 12.9543C9.00212 13.0527 8.98868 13.1514 8.95655 13.2447C8.92442 13.3379 8.87424 13.424 8.80886 13.4978C8.74349 13.5717 8.66421 13.632 8.57554 13.6752C8.48688 13.7184 8.39056 13.7438 8.2921 13.7498C8.19365 13.7558 8.09497 13.7423 8.0017 13.7102C7.90844 13.6781 7.82242 13.6279 7.74855 13.5625L0.998551 7.56251C0.918746 7.49213 0.85483 7.40557 0.811051 7.30859C0.767271 7.21161 0.744629 7.10642 0.744629 7.00001C0.744629 6.89361 0.767271 6.78842 0.811051 6.69143C0.85483 6.59445 0.918746 6.50789 0.998551 6.43751L7.74855 0.437512C7.82242 0.371892 7.90849 0.321464 8.00185 0.289107C8.0952 0.256751 8.19402 0.243099 8.29265 0.248932C8.39129 0.254764 8.4878 0.279967 8.5767 0.323101C8.66559 0.366235 8.74512 0.426456 8.81074 0.500324C8.87636 0.574193 8.92679 0.660263 8.95914 0.75362C8.9915 0.846977 9.00515 0.945794 8.99932 1.04443C8.99348 1.14306 8.96828 1.23958 8.92515 1.32847C8.88201 1.41737 8.82179 1.49689 8.74792 1.56251ZM22.9979 6.43751L16.2479 0.437512C16.0987 0.305484 15.9032 0.238126 15.7044 0.250258C15.5055 0.262389 15.3196 0.353015 15.1876 0.502199C15.0556 0.651383 14.9882 0.846906 15.0004 1.04575C15.0125 1.2446 15.1031 1.43048 15.2523 1.56251L21.3751 7.00001L15.2561 12.4375C15.1822 12.5029 15.1219 12.5822 15.0787 12.6708C15.0355 12.7595 15.0101 12.8558 15.0041 12.9543C14.9981 13.0527 15.0115 13.1514 15.0437 13.2447C15.0758 13.3379 15.126 13.424 15.1914 13.4978C15.2567 13.5717 15.336 13.632 15.4247 13.6752C15.5134 13.7184 15.6097 13.7438 15.7081 13.7498C15.8066 13.7558 15.9053 13.7423 15.9985 13.7102C16.0918 13.6781 16.1778 13.6279 16.2517 13.5625L23.0017 7.56251C23.0815 7.49213 23.1454 7.40557 23.1892 7.30859C23.233 7.21161 23.2556 7.10642 23.2556 7.00001C23.2556 6.89361 23.233 6.78842 23.1892 6.69143C23.1454 6.59445 23.0815 6.50789 23.0017 6.43751H22.9979Z"
      fill="currentColor"
    />
  </svg>
);

export default EmbedIcon;
