import React, { useState } from 'react';
import { makeStyles, Popper, ClickAwayListener } from '@material-ui/core';
import { ChromePicker } from 'react-color';

import LabeledOutline from './LabeledOutline';

const useColorPickerStyles = makeStyles((theme) => ({
  swatch: {
    //padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    //display: 'inline-block',
    cursor: 'pointer'
  },
  color: {
    //width: '36px',
    height: '1.7rem',
    borderRadius: '2px'
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  }
}));

export const ColorPicker = ({ color, label, onColorSelected }) => {
  const classes = useColorPickerStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  return (
    <LabeledOutline label={label}>
      <div className={classes.swatch} onClick={handleClick}>
        <div className={classes.color} style={{ backgroundColor: color }} />
      </div>
      {open ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper id={'simple-popper'} open={open} anchorEl={anchorEl} style={{ zIndex: 2 }} placement="bottom" disablePortal={true}>
            <ChromePicker color={color} onChange={onColorSelected} triangle="hide" />
          </Popper>
        </ClickAwayListener>
      ) : null}
    </LabeledOutline>
  );
};
