import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/Layout';
import { CREATE_SUBSCRIPTION } from './mutations';

import IconRadialUnselected from '../../images/icon-radial-unselected.svg';
import IconRadialSelected from '../../images/icon-radial-selected.svg';
import { Button } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { SessionContext } from '../../contexts/session';

interface PriceType {
  cost: Number;
  id: string;
  period: 'month' | 'year';
  discount: Number | null;
}

interface Dictionary<T> {
  [Key: string]: T;
}

const Prices: Dictionary<PriceType> = {
  monthly: {
    cost: 30,
    id: 'PREMIUM_MONTHLY',
    period: 'month',
    discount: null
  },
  yearly: {
    cost: 290,
    id: 'PREMIUM_YEARLY',
    period: 'year',
    discount: 20
  }
};

export default function AccountSubscriptions() {
  const { t } = useTranslation();
  const [selectedPrice, setSelectedPrice] = useState<string>(Prices.monthly.id);
  const { account } = useContext(SessionContext);
  const [createSubscription, { loading }] = useMutation(CREATE_SUBSCRIPTION);

  const handleCreateSubscription = async () => {
    const {
      data: {
        createSubscription: { stripeCheckoutUrl }
      }
    } = await createSubscription({ variables: { priceId: selectedPrice } });
    if (stripeCheckoutUrl) {
      window.location.href = stripeCheckoutUrl;
    }
  };

  const handleBack = () => {
    window.location.pathname = '/account';
  };

  const selectedPriceData = Object.values(Prices).find((price) => price.id === selectedPrice);
  const isElegibleForFreeTrial = account?.elegibleForFreeTrial;
  return (
    <Layout title={t('Subscriptions.Title')}>
      <div className="max-w-2xl px-8 py-4">
        <button className="flex flex-row justify-center items-center uppercase" onClick={handleBack}>
          <ChevronLeft /> {t('Common.Back')}
        </button>
      </div>
      <div className="max-w-3xl mx-auto p-8 lg:p-2 lg:pb-24">
        <h2 className="font-bold text-xl text-dark-grey">{t(`Subscriptions.Heading${isElegibleForFreeTrial ? 'FreeTrial' : ''}`)}</h2>
        {!isElegibleForFreeTrial && <p className="text-dark-grey">{t('Subscriptions.Subheading')}</p>}

        <div className="flex flex-wrap">
          <PriceCard
            price={Prices.monthly}
            selected={selectedPrice === Prices.monthly.id}
            onClick={() => setSelectedPrice(Prices.monthly.id)}
          />
          <PriceCard
            price={Prices.yearly}
            selected={selectedPrice === Prices.yearly.id}
            onClick={() => setSelectedPrice(Prices.yearly.id)}
          />
        </div>
        <h3 className="mb-8 text-md h-6 text-dark-grey">
          {isElegibleForFreeTrial &&
            t('Subscriptions.TrialAndThen', { price: `$${selectedPriceData?.cost}`, byTime: selectedPriceData?.period })}
        </h3>

        <div className="flex flex-row justify-start">
          <Button variant="contained" color="primary" onClick={handleCreateSubscription} disabled={loading}>
            {t(`Subscriptions.ButtonSelect${isElegibleForFreeTrial ? 'FreeTrial' : ''}`)}
          </Button>
        </div>
      </div>
    </Layout>
  );
}

interface PriceCardProps {
  price: PriceType;
  selected: boolean;
  onClick: () => void;
}

function PriceCard({ price, selected, onClick }: PriceCardProps) {
  const { t } = useTranslation();
  return (
    <div className={'w-[340px] cursor-pointer py-2 md:px-2 text-dark-grey'} onClick={onClick}>
      <div className={clsx('bg-white rounded-xl', selected ? 'p-[22px] border-3 border-green' : 'p-[24px] border-1 border-light-grey')}>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <img src={selected ? IconRadialSelected : IconRadialUnselected} alt={selected ? 'selected' : 'unselected'} />
            <span className="ml-2 text-xl flex-1">
              {price.period === 'month' ? t('Subscriptions.PayMonthly') : t('Subscriptions.PayYearly')}
            </span>
            {price.discount && (
              <span className=" bg-green px-3 py-1 ml-2 rounded-lg flex justify-center items-center text-md">
                -{price.discount?.toString()}%
              </span>
            )}
          </div>
          <div className="flex-1 flex flex-row items-baseline">
            <h5 className="text-4xl font-bold">${price.cost.toString()}</h5>
            <div className=" text-md ml-1">{price.period === 'month' ? t('Subscriptions.PerMonth') : t('Subscriptions.PerYear')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
