import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { HEAR_ABOUT_US_OPTIONS, convertToCapital } from '../util/hearAboutUsOptions';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '250px'
  }
}));

export function HearAboutUsDropdown({ aboutUs, setHearAboutUs, label = 'How did you heard about us?', error = false }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" className={classes.formControl} error={error}>
      <InputLabel htmlFor="grouped-select">{label}</InputLabel>
      <Select value={aboutUs} labelId="grouped-select" onChange={(e) => setHearAboutUs(e.target.value)} labelWidth={220}>
        {Object.keys(HEAR_ABOUT_US_OPTIONS).map((key) => (
          <MenuItem value={HEAR_ABOUT_US_OPTIONS[key]} key={key}>
            {t('Signup.' + convertToCapital(key))}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
