import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { LinkDeviceWorkflow } from '../../devices/LinkDeviceWorkflow';

import FireTvImage from '../../../images/device-firetv.png';
import CustomLink from '../../../components/CustomLink';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  deviceOverview: {
    paddingTop: 20,

    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  deviceImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const FireTvConnector = ({ screen, onCancel }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={2}>
          <ArrowBack onClick={onCancel} />
        </Grid>
        <Grid item xs={8} className={classes.pageHeading}>
          {t('FireTv.ConnectFireTv')}
        </Grid>
        <Grid container item xs={12} className={classes.deviceOverview} spacing={2}>
          <Grid item xs={3} className={classes.deviceImage}>
            <img alt="Fire TV" src={FireTvImage} />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h2">{t('FireTv.FireTvReady')}</Typography>
            <p>
              {t('FireTv.Download')}{' '}
              <CustomLink href="https://www.amazon.com/POURWALL/dp/B0178IVJ60/" target="_blank">
                {t('FireTv.PourwallFireTv')}
              </CustomLink>{' '}
              {t('FireTv.App')}
            </p>
            <p style={{ opacity: 0.8 }}>
              {t('FireTv.ScreenOff')} {t('FireTv.GoTo')} <em>{t('FireTv.Settings')}</em> &gt;{' '}
              <em>
                {t('FireTv.Display')} &amp; {t('FireTv.Sounds')}
              </em>{' '}
              &gt; <em>{t('FireTv.ScreenSaver')}</em>. {t('FireTv.Select')} <u>{t('FireTv.Never')}</u> {t('FireTv.Under')}{' '}
              <em>{t('FireTv.StartTime')}</em>.
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          <LinkDeviceWorkflow screen={screen} />
        </Grid>
      </Grid>
    </Grid>
  );
};
