import { MENU_TYPE } from './enums';

import { useContext, useEffect } from 'react';
import { SessionContext } from '../../../contexts/session';
import useLocationStorage from './useLocationStorage';

export const defaultMenuColors = {
  [MENU_TYPE.CLASSIC]: {
    primary: '#FD5C36',
    secondary: '#FFF2EB',
    primaryText: '#35495D',
    secondaryText: '#656A6E',
    dash: '#FFF2EB'
  },
  [MENU_TYPE.VERTICAL]: {
    primary: '#35495D',
    secondary: '#FD5C36',
    primaryText: '#35495D',
    secondaryText: '#656A6E',
    dash: '#DBE7F2'
  },
  [MENU_TYPE.DEFAULT]: {}
};

export const useMenuColorsStorage = (key: string, menuType: MENU_TYPE, initialValue: string) => {
  const { currentLocation } = useContext(SessionContext);
  const colorKey = key + menuType;
  const [color, setColor] = useLocationStorage(colorKey, initialValue);

  useEffect(() => {
    const storedValue = window.localStorage.getItem(colorKey + currentLocation?._id);
    setColor((storedValue && JSON.parse(storedValue)) ?? initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuType]);

  return [color, setColor];
};
