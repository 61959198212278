import React, { useState } from 'react';

export const DeviceContext = React.createContext(null);

export const DeviceProvider = ({ children }) => {
  const [device, setDevice] = useState();

  const registerDevice = (device) => {
    if (device) {
      setDevice(device);
    }
  };

  const clearDevice = () => {
    setDevice(undefined);
  };

  return <DeviceContext.Provider value={{ device, registerDevice, clearDevice }}>{children}</DeviceContext.Provider>;
};
