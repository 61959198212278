import gql from 'graphql-tag';
import { SCREEN_FIELDS } from '../../data/graphql';

export const UPDATE_DEVICE = gql`
    mutation($device: UpdateByIdDeviceInput!){
        deviceUpdateById(record:$device){
            record {
                _id,
                name,
                type,
                registrationCode,
                ipAddress,
                screen {
                    ${SCREEN_FIELDS}
                },
    
            }
        }
    }
`;

export const UPDATE_SCREEN = gql`
    mutation($screen: UpdateByIdScreenInput!){
        screenUpdateById(record:$screen){
            record {
                ${SCREEN_FIELDS}
            }
        }
    }
`;
