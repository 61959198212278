import React, { useContext, useEffect, useState } from 'react';
import { Button, Fab, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { useQuery } from 'react-apollo';
import { Add } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { SessionContext } from '../../contexts/session';

import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { ScreenListItem } from './ScreenListItem';
import { FeedbackBar } from '../../components/FeedbackBar';
import PremiumFeatureDialog from '../../components/PremiumFeatureDialog';

import { GET_SCREENS } from './queries';

import EmptyScreenListImage from '../../images/screens.svg';
import IconPremiumChip from '../../images/icon-premium-chip.svg';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },

  emptyListContainer: {
    padding: '80px 30px',
    textAlign: 'center',

    '& img': {
      width: 140
    },

    '& p': {
      padding: '20px 0'
    }
  }
}));

export const Screens = withRouter(({ history }) => {
  const { t } = useTranslation();
  let session = useContext(SessionContext);
  const { currentLocation, premium } = session;

  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);

  const classes = useStyles();

  const { data, loading, error, startPolling, stopPolling } = useQuery(GET_SCREENS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network'
  });

  let screens = data?.screens || [];

  const handleAddNewScreen = () => {
    if (premium || screens.length === 0) {
      // TODO: protect this route
      history.push(`/screens/new`);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const edit = (screen?: any) => {
    if (screen) history.push(`/screens/${screen._id}/edit`);
    else history.push(`/screens/new`);
  };

  useEffect(() => {
    startPolling(30 * 1000);
    return stopPolling;
  }, [startPolling, stopPolling]);

  return (
    <Layout title={t('Screens.Screens')}>
      <FeedbackBar message={error} />
      {loading && screens.length === 0 && <Loading style={{ padding: 40 }} />}
      {!loading && screens.length === 0 && (
        <div className="w-full flex flex-col items-center">
          <div className={clsx(classes.emptyListContainer, 'flex max-w-lg flex-col items-center')}>
            <Typography variant="h2">{t('Screens.NoScreensYet')}</Typography>
            <img alt="No Screens" src={EmptyScreenListImage} />
            <p>
              {t('Screens.ScreensDisplayed')} <CustomLink href="/taps">{t('Screens.TapList')}</CustomLink> {t('Screens.CustomImages')}
            </p>
            <Button variant="contained" color="primary" onClick={() => edit()}>
              {t('Screens.AddFirstScreen')}
            </Button>
          </div>
        </div>
      )}
      {screens.length > 0 && (
        <>
          <Alert severity="info" variant="standard" style={{ margin: '0 30px' }}>
            {t('Screens.ScreenReady')}
          </Alert>
          {screens.map((s: any) => (
            <ScreenListItem key={s._id} screen={s} onClick={() => edit(s)} />
          ))}
          <Fab
            variant="extended"
            color={premium ? 'primary' : 'default'}
            aria-label="add"
            className={classes.fab}
            onClick={handleAddNewScreen}>
            <Add /> {t('Screens.NewScreen')}
            {!premium && screens.length >= 1 && (
              <img alt="Icon Premium Feature" src={IconPremiumChip} className="absolute top-[-6px] right-[-6px]" />
            )}
          </Fab>
        </>
      )}
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureDescription={t('PremiumFeatureDialog.Features.UnlimitedScreensDescription')}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </Layout>
  );
});
