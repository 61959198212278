interface RectSlashIconProps {
  color?: string;
  className?: string;
}

const RectSlashIcon = ({ color, className }: RectSlashIconProps) => (
  <svg className={className} width="4" height="8" viewBox="0 0 3 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="1" width="3" height="6" fill={color ?? '#FD5C36'} />
  </svg>
);

export default RectSlashIcon;
