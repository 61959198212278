export enum CLASSIC_HEADER_TYPE {
  LEFT_ALIGN = 'left-align',
  CENTER_ALIGN = 'center-align',
  LOGO = 'logo'
}

export enum VERTICAL_HEADER_TYPE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_ALTERNATE_ONE = 'secondary-alternate-one',
  SECONDARY_ALTERNATE_TWO = 'secondary-alternate-two',
  LOGO = 'logo'
}

export enum MENU_TYPE {
  DEFAULT = 'default',
  CLASSIC = 'classic',
  VERTICAL = 'vertical'
}
