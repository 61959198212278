export const HEAR_ABOUT_US_OPTIONS = {
  SELECT_AN_OPTION: 'Select an option',
  GOOGLE_SEARCH: 'Google search',
  SOCIAL_MEDIA: 'Social media',
  SOMEWHERE_ELSE: 'I saw POURWALL being used somewhere',
  SOMEONE_TOLD_ME: 'Someone told me about POURWALL',
  OTHER: 'Other'
};

export const convertToCapital = (inputString) =>
  inputString
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

export const DEFAULT_HEAR_ABOUT_US = HEAR_ABOUT_US_OPTIONS.SELECT_AN_OPTION;
