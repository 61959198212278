import { makeStyles } from '@material-ui/core';
import React from 'react';
import { MenuPrintConfig, MenuPrintPageProps } from './types';

export interface ColumnLayoutProps {
  config: MenuPrintConfig;
  onColumnRef: (ref: HTMLDivElement | null, index: number) => void;
  columns: MenuPrintPageProps['columns'];
  ColumnComponent: React.ElementType<{ data: MenuPrintPageProps['columns'][number] }>;
}

const ColumnLayout: React.FC<ColumnLayoutProps> = ({ config, onColumnRef, columns, ColumnComponent }) => {
  const classes = useStyles();
  const columnWidth = `calc(${Math.floor(100 / config.columnCount)}% - 5px)`;

  return (
    <div className={classes.container}>
      {Array.from({ length: config.columnCount }).map((_, i) => {
        const data = columns?.[i];
        if (!data) {
          return <div key={i} style={{ width: columnWidth }} />;
        }
        return (
          <div key={'beverage-col-' + i} ref={(el) => onColumnRef(el, i)} style={{ width: columnWidth }}>
            <ColumnComponent data={data} />
          </div>
        );
      })}
    </div>
  );
};

export default ColumnLayout;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  col: {
    height: '100%'
  }
}));
