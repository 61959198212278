import React, { useState, useEffect, useContext } from 'react';
import { sortBy, findIndex, filter, find } from 'lodash';
import { useMutation } from 'react-apollo';
import { makeStyles } from '@material-ui/core';
import color from 'color';

import { DEVICE_HEARTBEAT } from '../mutations';

import PourwallLogo from '../../../images/pourwall-logo.svg';
import PourwallLogoAlt from '../../../images/pourwall-logo-alt.svg';

import { ImagePage } from './ImagePage';
import { TapPage } from './TapPage';

import { DisplayBackgroundColor } from '../../../theme';
import { DeviceContext } from '../../../contexts/device';
import { useWebSocket } from '../../../contexts/webSockets';

const useStyles = makeStyles((theme) => ({
  displayLandscapeContainer: {
    height: '100vh',
    display: 'flex',
    background: DisplayBackgroundColor,
    transform: ({ screenOrientation }) => (screenOrientation === 'landscape_180' ? 'rotate(180deg)' : null)
  },
  displayPortraitContainer: {
    width: ({ height }) => `${height}px`,
    height: ({ width }) => `${width}px`,
    display: 'flex',
    background: DisplayBackgroundColor,
    position: 'absolute',
    left: ({ width }) => (width * 21.875) / 100,
    top: ({ width }) => -(width * 21.875) / 100,
    transform: ({ screenOrientation }) => (screenOrientation === 'portrait_270' ? 'rotate(270deg)' : 'rotate(90deg)')
  }
}));

const heartbeatInterval = process.env.REACT_APP_HEARTBEAT_INTERVAL;

export const Display = () => {
  const { device, registerDevice } = useContext(DeviceContext);
  const { screen } = device;
  const screenOrientation = screen?.orientation || 'landscape_0';
  const [windowSize, setWindowSize] = useState({
    width: window?.innerWidth || 1920,
    height: window?.innerHeight || 1080
  });

  const classes = useStyles({
    ...windowSize,
    screenOrientation
  });

  const [currentPage, setCurrentPage] = useState(null);

  const [heartbeat, { data }] = useMutation(DEVICE_HEARTBEAT);
  const { webSocketEventEmitter, isWebSocketConnected } = useWebSocket();

  if (!currentPage) {
    let nextPage = getNextPage(screen, currentPage);
    setCurrentPage(nextPage);
  }

  if (data && data?.device?.screen?.dateUpdated !== screen.dateUpdated) {
    registerDevice(data.device);
  }

  useEffect(() => {
    console.log('currentPage changed', currentPage);

    let timer = setTimeout(() => {
      let nextPage = getNextPage(screen, currentPage);
      setCurrentPage(nextPage);
    }, currentPage.rotationDuration * 1000);

    return () => clearTimeout(timer);
  }, [screen, currentPage]);

  useEffect(
    () => {
      const intervals = [];

      if (!isWebSocketConnected) {
        console.log('Adding backup display polling interval');
        const deviceUpdateInterval = setInterval(async () => {
          try {
            const { data } = await heartbeat({ variables: { id: device._id } });
            console.log('Heartbeat response for device:', data.device._id);
            if (data.device) {
              registerDevice(data.device);
            }
          } catch (e) {
            console.log('An error occurred during heartbeat', e.message);
          }
        }, Number(heartbeatInterval || 60) * 1000);
        intervals.push(deviceUpdateInterval);
      } else {
        console.log('WebSocket is available. Device update polling disabled.');
      }

      return () => {
        console.log('Removing backup polling intervals for display');
        intervals.map(clearInterval);
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isWebSocketConnected, device]
  );

  useEffect(
    () => {
      let subscription;

      if (isWebSocketConnected) {
        subscription = webSocketEventEmitter.subscribe('SCREEN_UPDATE', async () => {
          console.log('WebSocket SCREEN_UPDATE event received. Calling heartbeat...');
          const { data } = await heartbeat({ variables: { id: device._id } });
          console.log('Heartbeat response data.device._id:', data.device._id);
          if (data.device) {
            registerDevice(data.device);
          }
        });
      }

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [webSocketEventEmitter, device, heartbeat, isWebSocketConnected]
  );

  // this effect should really only run on first load
  useEffect(() => {
    const { screen } = device;
    // preload all images
    const { pages } = screen;
    const imagePages = filter(pages, (p) => p.type === 'image');
    console.log('Prefetching images for', imagePages.length, 'pages');
    imagePages.forEach((page) => {
      var img = new Image();
      img.onload = () => console.log('loaded image', page.imageUrl);
      img.src = page.imageUrl;
    });
  }, [device]);

  let theme = currentPage?.style?.themeOptions || device?.location?.settings?.themeOptions || {};
  if (currentPage?.style?.pageKey) {
    // see if we can find the page this one references...
    theme = find(screen.pages, (p) => p.key === currentPage?.style.pageKey)?.style?.themeOptions || theme;
  }

  const logoImage = color(theme.backgroundColor || 'black').isLight() ? PourwallLogoAlt : PourwallLogo;

  window.addEventListener('resize', function (event) {
    const height = event.target.innerHeight;
    const width = event.target.innerWidth;
    setWindowSize({ width, height });
  });

  return (
    <div className={screenOrientation.includes('portrait_') ? classes.displayPortraitContainer : classes.displayLandscapeContainer}>
      {currentPage && currentPage.type === 'taps' && <TapPage page={currentPage} screen={screen} key={currentPage._id} />}
      {currentPage && currentPage.type === 'image' && <ImagePage page={currentPage} screen={screen} key={currentPage._id} />}
      {theme.showLogo && (
        <div
          style={{
            position: 'absolute',
            bottom: 20,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img src={logoImage} alt="POURWALL" style={{ width: '200px', height: 'auto' }} />
        </div>
      )}
    </div>
  );
};

const getNextPage = (screen, currentPage) => {
  const { pages } = screen;
  const orderedPages = sortBy(pages, (p) => p.number);

  let page = currentPage;
  if (!page) {
    page = orderedPages[0];
  } else {
    // search by key because the pages may have been rearranged in realtime
    let i = findIndex(orderedPages, (p) => p.key === page.key);
    if (i === orderedPages.length - 1) {
      page = orderedPages[0];
    } else {
      page = orderedPages[i + 1];
    }
  }

  console.log(`Moving to page ${page.number} (of ${screen.pages.length}) for ${page.rotationDuration} seconds...`);

  if (page.type === 'image') {
    // make sure the image is loaded before displaying the page
    var img = new Image();
    /*
            img.onload = () => {
                setCurrentPage(page);
                pageTimer = setTimeout(() => next(), page.rotationDuration * 1000);
            };
            */
    img.src = page.imageUrl;
  } else {
    //setCurrentPage(page);
    //pageTimer = setTimeout(() => next(), page.rotationDuration * 1000);
  }
  return page;
};
