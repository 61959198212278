import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { Button, CircularProgress, Grid, MenuItem, TextField } from '@material-ui/core';

import { Layout } from '../../components/Layout';
import { SessionContext } from '../../contexts/session';
import { FeedbackBar } from '../../components/FeedbackBar';

import { COPY_TAPS, GET_TAPS } from './queries';
import { UserVesselsProvider } from '../../contexts/userVessels';
import CopyTapList, { TapWithSettings } from '../../components/CopyTapList';

interface Location {
  _id: string;
  name: string;
}

export const CopyTaps = withRouter(({ history }) => {
  const { currentLocation, locations } = useContext(SessionContext);
  const { t } = useTranslation();

  const [selectedTaps, setSelectedTaps] = useState<TapWithSettings[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location>({
    _id: 'select',
    name: t('Taps.SelectLocation')
  });

  const [copyTaps, { loading: copyTapsLoading, error: copyTapsError }] = useMutation(COPY_TAPS, {
    onCompleted: () => {
      history.push('/taps');
    }
  });

  const { loading, error, data, refetch } = useQuery(GET_TAPS, {
    variables: { location: selectedLocation?._id },
    fetchPolicy: 'cache-and-network',
    skip: selectedLocation._id === 'select'
  });

  const handleCopyTaps = useCallback(async () => {
    try {
      const tapsToCopy = selectedTaps.map((tap) => ({
        tapId: tap._id,
        copyServings: tap.copyServings
      }));

      await copyTaps({
        variables: {
          tapsToCopy,
          targetLocationId: currentLocation._id
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [selectedTaps, currentLocation, copyTaps]);

  const handleNotHavingMultipleLocations = useCallback(() => {
    if (locations?.length === 1) {
      history.goBack();
    }
  }, [locations, history]);

  useEffect(() => {
    if (selectedLocation._id !== 'select') {
      refetch?.({ location: selectedLocation._id });
    }
  }, [selectedLocation, refetch]);

  useEffect(() => {
    handleNotHavingMultipleLocations();
  }, [handleNotHavingMultipleLocations]);

  return (
    <UserVesselsProvider>
      <Layout title={t('Taps.Copy')}>
        <FeedbackBar message={copyTapsError || error} />

        <Grid container spacing={3} direction="column">
          <Grid item>
            <Grid container className="px-4 pt-1.5 pb-3 border-b border-gray-300">
              <Grid item>
                <ArrowBack onClick={() => history.goBack()} className="cursor-pointer" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2} className="px-10">
              <Grid item xs={12} md={6} style={{ minHeight: '80px' }}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label={t('Taps.CopyFrom')}
                  value={selectedLocation._id}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        className: 'border-1 border-accent-grey mt-2'
                      },
                      MenuListProps: {
                        className: '!py-0'
                      }
                    }
                  }}
                  onChange={(e) => {
                    const location = locations.find((l) => l._id === e.target.value);
                    if (location) {
                      setSelectedLocation(location);
                    }
                  }}>
                  <MenuItem value="select" disabled>
                    {t('Taps.SelectLocation')}
                  </MenuItem>
                  {(locations || [])
                    .filter((l) => l._id !== currentLocation._id)
                    .map((l) => (
                      <MenuItem key={l._id} value={l._id}>
                        {l.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            {loading && (
              <Grid container direction="column" justifyContent="center" alignItems="center" className="min-h-[400px]">
                {t('Taps.LoadingMessage', { location: currentLocation.name })}
                <CircularProgress className="mt-10" color="secondary" />
              </Grid>
            )}

            {!loading && selectedLocation._id === 'select' && <div className="min-h-[400px]" />}

            {!loading && selectedLocation._id !== 'select' && <CopyTapList taps={data?.taps || []} setSelectedTaps={setSelectedTaps} />}
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={6} />
              <Grid item container xs={6} wrap="nowrap" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                  <Button variant="outlined" color="secondary" onClick={() => history.goBack()}>
                    {t('Common.Cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedLocation._id === 'select' || copyTapsLoading}
                    onClick={handleCopyTaps}
                    className="whitespace-nowrap">
                    {selectedTaps.length === 1 ? t('Taps.CopyOne') : t('Taps.Copy', { count: selectedTaps.length })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </UserVesselsProvider>
  );
});
