import { makeStyles } from '@material-ui/core';
import BeerIcon from '../../../images/icon-cup-beer.tsx';
import { CLASSIC_HEADER_TYPE } from './enums.ts';

const ClassicUnderlined = ({ title, theme }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title} style={{ color: theme.primaryText }}>
        {title}
      </div>
      <div className={classes.titleMidUnderline} style={{ backgroundColor: theme.primary }} />
      <div className={classes.titleFullUnderline} style={{ backgroundColor: theme.primary }} />
    </div>
  );
};

const ClassicCentered = ({ title, theme }) => {
  const classes = useStyles();
  return (
    <div className={classes.centeredContainer}>
      <div className={classes.separator} style={{ backgroundColor: theme.primary }} />
      <BeerIcon className={classes.doubleIcon} color={theme.primary} />
      <div className={classes.centeredTitle} style={{ color: theme.primaryText }}>
        {title}
      </div>
      <BeerIcon className={classes.doubleIcon} color={theme.primary} />
      <div className={classes.separator} style={{ backgroundColor: theme.primary }} />
    </div>
  );
};

const ClassicLogo = ({ logo }) => {
  const classes = useStyles();
  return <img className={classes.headerLogo} src={logo} alt="Brand logo" />;
};

const ClassicHeader = ({ config }) => {
  const classes = useStyles();
  const { title, headerType, headerImageUrl, theme } = config;

  let Content = null;
  if (headerType === CLASSIC_HEADER_TYPE.LOGO) {
    Content = <ClassicLogo logo={headerImageUrl} />;
  } else if (headerType === CLASSIC_HEADER_TYPE.CENTER_ALIGN) {
    Content = <ClassicCentered title={title} theme={theme} />;
  } else {
    Content = <ClassicUnderlined title={title} theme={theme} />;
  }

  return <div className={classes.header}>{Content}</div>;
};

export default ClassicHeader;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: 580,
    height: 48
  },
  centeredContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerLogo: {
    flex: 1
  },
  separator: {
    width: 60,
    height: 1,
    backgroundColor: theme.primary
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
    letterSpacing: 1.08,
    fontFamily: 'Bitter',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 580
  },
  centeredTitle: {
    fontSize: 30,
    marginRight: 16,
    marginLeft: 16,
    color: theme.primaryText,
    fontFamily: 'Bitter',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 320
  },
  doubleIcon: {
    marginRight: 16,
    marginLeft: 16,
    fill: theme.primary
  },
  titleMidUnderline: {
    width: 425,
    height: 0.5,
    backgroundColor: theme.primary
  },
  titleFullUnderline: {
    width: 580,
    height: 0.5,
    marginTop: 6,
    backgroundColor: theme.primary
  }
}));
