import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Orange, White } from '../theme';

import { Bar } from './NavBar';
import { WalkthroughDialog } from '../screens/walkthrough/dialog';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '1.5rem',
    paddingTop: '1.5rem',
    backgroundColor: Orange,
    paddingBottom: '100px',
    '& > p': {
      opacity: '0.6',
      margin: 0
    }
  },
  headerContainer: {
    padding: 0
  },
  content: {
    //padding: '1.5rem',
    padding: 0,
    maxWidth: '1000px'
  },
  main: {
    //padding: '1.5rem',
    paddingTop: 30,
    marginTop: '-80px',
    minHeight: '200px',
    backgroundColor: White
  }
}));

interface LayoutProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}

export const Layout = ({ title, className, ...props }: LayoutProps) => {
  const classes = useStyles();
  const navbarProps = {
    title: title
  };
  return (
    <Grid container direction="column">
      <Grid item className={classes.header} xs={12}>
        <Container maxWidth="md" className={classes.headerContainer}>
          <Bar {...navbarProps} />
        </Container>
      </Grid>
      <Grid item xs={12}>
        <Container className={classes.content}>
          <main className={clsx(classes.main, className)}>{props.children}</main>
        </Container>
      </Grid>
      <WalkthroughDialog />
    </Grid>
  );
};
