import React, { useState, useContext } from 'react';

export const EnableWebSocketsContext = React.createContext(null);

export const EnableWebSocketsProvider = ({ children }) => {
  const [webSocketsEnabled, setWebSocketsEnabled] = useState();

  const toggleWebSockets = (value) => {
    setWebSocketsEnabled(value);
  };

  return <EnableWebSocketsContext.Provider value={{ toggleWebSockets, webSocketsEnabled }}>{children}</EnableWebSocketsContext.Provider>;
};

export const useEnableWebSockets = () => useContext(EnableWebSocketsContext);
