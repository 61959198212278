import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';

import { UserVesselsContext } from '../../../contexts/userVessels';
import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../../util/lang';

export const Serving = ({ serving, theme }) => {
  const { userVessels } = useContext(UserVesselsContext);

  return (
    <Grid item key={serving._id} className={`item-meta-cost-single ${theme.menuType || ''}`}>
      <div className="item-price" style={{ color: theme.foregroundColor2, fontFamily: theme.primaryFont }}>
        {getServingPriceLabel(serving, theme?.showServingCurrency)}
      </div>
      <div
        className="item-size"
        style={{
          color: theme.foregroundColor,
          fontFamily: theme.secondaryFont
        }}>
        <div className="vessel-type">{resolveVesselName(serving.vessel, userVessels)}</div>
        {theme.showServingSize && <div className="vessel-size">({getServingSizeLabel(serving)})</div>}
      </div>
    </Grid>
  );
};
