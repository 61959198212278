import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ActionBar } from '../../components/ActionBar';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FIND_DEVICE, GET_SCREENS } from './queries';
import { UPDATE_DEVICE, UPDATE_SCREEN } from './mutations';
import { SessionContext } from '../../contexts/session';
import { cleanCachedObjectForMutation } from '../../util/graphql';

import { FeedbackBar } from '../../components/FeedbackBar';
import { DeviceCard } from './DeviceCard';
import { DeviceContext } from '../../contexts/device';

import CheckedImage from '../../images/checked.png';
import BarScene from '../../images/bar-scene.svg';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 80
  },
  pageHeading: {
    textAlign: 'center',
    marginBottom: 20
  },
  screenListItem: {
    padding: 10,
    cursor: 'pointer',
    marginBottom: 10
  },
  listItemSelected: {
    position: 'relative',
    backgroundColor: '#f9f9f9',

    '&::after': {
      background: `url(${CheckedImage}) no-repeat`,
      backgroundSize: 'cover',
      content: '""',
      height: 36,
      width: 36,
      position: 'absolute',
      top: -8,
      right: -8
    }
  },

  finished: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 30px',

    '& > img': {
      width: '100%',
      maxWidth: 200
    }
  }
}));

export const LinkDeviceWorkflow = withRouter(({ history, screen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentLocation } = useContext(SessionContext);

  const [code, setCode] = useState('');
  const [selectedScreen, setSelectedScreen] = useState(screen);
  const [finished, setFinished] = useState(false);
  const { device, registerDevice } = useContext(DeviceContext);

  const [findDevice, { loading: findDeviceLoading, error: findDeviceError, data: findDeviceData }] = useLazyQuery(FIND_DEVICE);
  const { loading, error, data } = useQuery(GET_SCREENS, {
    variables: { location: currentLocation?._id }
  });

  const [updateDevice] = useMutation(UPDATE_DEVICE);
  const [updateScreen] = useMutation(UPDATE_SCREEN);

  useEffect(() => {
    if (findDeviceData?.device && (!device || device._id !== findDeviceData.device._id)) {
      registerDevice(findDeviceData.device);
    }
  }, [findDeviceData, device, registerDevice]);

  const screens = data?.screens || [];

  const handleConfirmCode = async () => {
    await findDevice({ variables: { code } });
  };

  const catchReturn = (e) => {
    if (e.key === 'Enter') handleConfirmCode();
  };

  // memoize this so we can use it in a useEffect hook
  const handleLinkScreenToDevice = useCallback(async () => {
    if (device && selectedScreen) {
      const cleanDevice = cleanCachedObjectForMutation(device);
      cleanDevice.screen = selectedScreen._id;
      await updateDevice({ variables: { device: cleanDevice } });
      // do the reverse, too
      const cleanScreen = cleanCachedObjectForMutation(selectedScreen);
      cleanScreen.device = cleanDevice._id;
      console.log('updating screen...', cleanScreen);
      await updateScreen({ variables: { screen: cleanScreen } });
      setFinished(true);
    }
  }, [device, updateDevice, selectedScreen, updateScreen]);

  const handleFinished = () => {
    history.push('/screens');
  };

  useEffect(() => {
    if (device && screen) {
      // we came in with a screen preselected
      // and we just found a device
      // so let's auto link it
      handleLinkScreenToDevice();
    }
    // eslint-disable-next-line
  }, [device]);

  return (
    <Grid container spacing={3} className={classes.root}>
      {(findDeviceError || (findDeviceData && !device) || error) && (
        <FeedbackBar
          message={
            findDeviceError || (findDeviceData && !device)
              ? t('LinkDeviceWorkflow.DeviceNotFound')
              : error
              ? t('LinkDeviceWorkflow.NotWork')
              : undefined
          }
        />
      )}
      {!finished && (
        <>
          {!device && (
            <>
              <Grid item xs={12}>
                {t('LinkDeviceWorkflow.EnterCode')}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={t('LinkDeviceWorkflow.DeviceCode')}
                  value={code}
                  onChange={(e) => setCode(e.target.value.toUpperCase())}
                  onKeyPress={catchReturn}
                />
              </Grid>
            </>
          )}
          {device && !screen && (
            <>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.pageHeading}>
                  {t('LinkDeviceWorkflow.DeviceFound')}
                </Typography>
                <DeviceCard device={device} />
              </Grid>
              <Grid item xs={12}>
                <p>{t('LinkDeviceWorkflow.SelectScreen')}</p>
              </Grid>
              <Grid item xs={12}>
                {screens.map((screen) => (
                  <Paper
                    key={screen._id}
                    variant="outlined"
                    className={clsx(classes.screenListItem, selectedScreen === screen ? classes.listItemSelected : null)}>
                    <ScreenListItem screen={screen} onClick={() => setSelectedScreen(screen)} />
                  </Paper>
                ))}
                {screens.length === 0 && (
                  <p>
                    {t('LinkDeviceWorkflow.NoScreensYet')} <CustomLink href="/screens">{t('LinkDeviceWorkflow.CreateAScreen')}</CustomLink>{' '}
                    {t('LinkDeviceWorkflow.TryAgain')}
                  </p>
                )}
              </Grid>
            </>
          )}
        </>
      )}
      {finished && (
        <Grid container>
          <Grid item xs={12} className={classes.finished}>
            <Typography variant="h2" style={{ marginBottom: 15 }}>
              {t('LinkDeviceWorkflow.AllSet')}
            </Typography>
            <img alt="Finished" src={BarScene} />
            <p>{t('LinkDeviceWorkflow.ShortMoments')}</p>
          </Grid>
        </Grid>
      )}
      <ActionBar dialog={1} loading={loading || findDeviceLoading}>
        {!finished && !device && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={!code || loading || findDeviceLoading}
            onClick={handleConfirmCode}>
            {t('LinkDeviceWorkflow.Next')}
          </Button>
        )}
        {!finished && device && (
          <Button variant="contained" color="primary" fullWidth disabled={!selectedScreen} onClick={handleLinkScreenToDevice}>
            {t('LinkDeviceWorkflow.Link')}
          </Button>
        )}
        {finished && (
          <Button variant="contained" color="secondary" fullWidth onClick={handleFinished}>
            {t('LinkDeviceWorkflow.Done')}
          </Button>
        )}
      </ActionBar>
    </Grid>
  );
});

const useListItemStyles = makeStyles((theme) => ({
  itemHandle: {
    display: 'inline-block',
    border: '1px solid #DDD',
    borderRadius: '100%',
    height: '40px',
    width: '40px',
    lineHeight: '38px',
    textAlign: 'center',
    fontSize: '13px',
    marginTop: '4px'
  },
  itemName: {
    fontWeight: '800',
    fontSize: '24px',
    lineHeight: '1.45',
    marginBottom: '5px'
  },
  itemSource: {
    fontSize: '20px',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    marginBottom: '4px'
  }
}));

export const ScreenListItem = ({ screen, onClick }) => {
  const classes = useListItemStyles();

  return (
    <Grid container onClick={() => onClick()} className={classes.container}>
      <Grid item xs={12}>
        <div>
          <div className={classes.itemName}>{screen.name}</div>
          <div className={classes.itemSource}>{screen.pages.length} pages</div>
          <div className="status-disconnected">
            <span className="dot"></span>
            <span>Disconnected</span>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
