import React from 'react';
import { FormControl, Select, MenuItem, OutlinedInput } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({}));

export function LanguageDropdown({ onChange }) {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        value={i18n.language || 'en-US'}
        labelId="language-select"
        onChange={handleChange}
        input={<OutlinedInput />}
        label="Language"
        autoWidth>
        <MenuItem value="en-US" key="en-US" selected={i18n.language === 'en-US' || i18n.language === 'en'}>
          {'\uD83C\uDDFA\uD83C\uDDF8'} English
        </MenuItem>
        <MenuItem value="es" key="es" selected={i18n.language === 'es'}>
          {'\ud83c\uddea\ud83c\uddf8'} Español
        </MenuItem>
      </Select>
    </FormControl>
  );
}
