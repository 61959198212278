import React from 'react';
import { Button, Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    marginRight: '10px',
    cursor: 'pointer',
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'space-between'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginBottom: '10px'
  },
  locationName: {
    width: '100%',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '1.45',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word'
  },
  value: {
    fontWeight: 'bold',
    fontSize: '18px'
  }
}));

export const LocationCard = ({ location, onEdit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.container} variant="outlined">
      <div className={classes.content}>
        <div className={classes.item}>
          <h1 className={classes.locationName}>{location.name}</h1>
        </div>

        <div className={classes.item}>
          <h3>{t('LocationCard.DefaultCurrency')}</h3>
          <span className={classes.value}>{location?.servingSettings?.currency}</span>
        </div>

        <div className={classes.item}>
          <h3>{t('LocationCard.DefaultVolumeUnit')}</h3>
          <span className={classes.value}>{location?.servingSettings?.volumeUnit}</span>
        </div>
      </div>
      <Grid container direction="row">
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button variant="text" color="primary" onClick={onEdit}>
            {t('LocationCard.Edit')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
