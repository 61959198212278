import React, { useContext, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileCopyOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';

import { SessionContext } from '../../../contexts/session';
import { LightGrey, BackgroundColor } from '../../../theme';
import { Dialog } from '../../../components/Dialog';

import { ActionBar } from '../../../components/ActionBar';
import { DialogSettings } from '../DialogSettings';

const useStyles = makeStyles((theme) => ({
  menuBorder: {
    border: `1px solid ${LightGrey}`
  },
  infoAlert: {
    '& svg': {
      height: 14,
      width: 'auto'
    },
    '& > p': {
      margin: 0,
      padding: 0
    },
    '& p:nth-child(2)': {
      marginTop: 10
    }
  },
  code: {
    margin: 0,
    backgroundColor: BackgroundColor,
    overflow: 'scroll',
    padding: 10,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 4,
    border: `1px solid ${LightGrey}`,
    marginBottom: 10
  },
  clipboard: {
    textTransform: 'capitalize',
    marginLeft: 5
  },
  alert: {
    marginLeft: 5,
    marginRight: 5,
    padding: 0,
    paddingRight: 10,
    paddingLeft: 10
  }
}));

export const EmbedDialog = ({ open, tapsCount, onCancel }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [params, setParams] = useState('');
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const { currentLocation } = useContext(SessionContext);

  const url = `//${window.location.hostname}/embed/${currentLocation?._id}/${params}`;
  const code = `
<iframe title="${currentLocation?.name} ${t('EmbedDialog.TapList')}" width="100%" height="600px" src="${url}" frameBorder="0"></iframe>
<p>Powered by <a href="//pourwall.com?utm_source=Widget&utm_medium=Referral&utm_campaign=widget&utm_content=${
    currentLocation?.name
  }">POURWALL</a>.</p>

`;

  const handlePreview = () => {
    window.open(url);
  };

  const hideAlert = () => setShowCopySuccess(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    setShowCopySuccess(true);
    setTimeout(() => hideAlert(), 3000);
  };

  return (
    <Dialog title={t('EmbedDialog.WebsiteEmbed')} open={open} onCancel={onCancel}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            <div className={classes.infoAlert}>{t('EmbedDialog.Paste')}</div>
          </Alert>
        </Grid>

        <Grid item xs={12}>
          <pre className={classes.code}>{code}</pre>
          <Grid container direction="row">
            <Button variant="outlined" color="default" onClick={copyToClipboard}>
              <FileCopyOutlined />
              <div className={classes.clipboard}>{t('EmbedDialog.CopyToClipboard')}</div>
            </Button>
            {showCopySuccess && (
              <Alert className={classes.alert} onClose={hideAlert} severity="info">
                {t('EmbedDialog.CopyToClipboardSuccess')}
              </Alert>
            )}
          </Grid>
        </Grid>

        <DialogSettings setParams={setParams} tapsCount={tapsCount} prefix="embedded" />

        <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
          <Grid container direction="row">
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button variant="text" color="default" onClick={handlePreview}>
                {t('EmbedDialog.Preview')}
              </Button>
              <Button variant="text" color="primary" onClick={onCancel}>
                {t('EmbedDialog.Done')}
              </Button>
            </Grid>
          </Grid>
        </ActionBar>
      </Grid>
    </Dialog>
  );
};
