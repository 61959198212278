import { useState, useEffect, createRef, useMemo } from 'react';
import { Dialog, DialogContent, Grid, Button, TextField, Typography, makeStyles, DialogActions, DialogTitle } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@material-ui/icons';
import { useQuery } from 'react-apollo';
import { GET_TAPS } from '../queries';
import { useSession } from '../../../contexts/session';
import { sortBy } from 'lodash';
import { Loading } from '../../../components/Loading';
import { FeedbackBar } from '../../../components/FeedbackBar';
import shortid from 'shortid';
import { EditServingForm } from './EditServingDialog';
import CloseIcon from '@material-ui/icons/Close';
import { DarkOrange } from '../../../theme';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 16
  },
  list: {
    border: '4px solid rgba(224, 224, 224, 1)'
  },
  separator: {
    borderBottom: '2px solid #e0e0e0',
    marginTop: 20
  },
  titleContainer: { display: 'flex', justifyContent: 'center' },
  loading: { margin: 40 },
  selectTapLabel: { marginTop: 20, marginBottom: 12, fontFamily: 'Unica One', textTransform: 'uppercase' },
  servingContainer: { marginBottom: -50 },
  servingHeaderContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  servingTitle: { fontFamily: 'Unica One', textTransform: 'uppercase' },
  dialogActions: { paddingRight: 24, paddingBottom: 24, paddingTop: 24, boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.05)' },
  button: {
    backgroundColor: '#FBE9E7',
    color: DarkOrange,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: '#FFCCBC'
    }
  },
  icon: {
    tintColor: DarkOrange
  }
}));

export const CopyServingsDialog = ({ currentTap, open, onClose, onCopyServings }) => {
  const { t } = useTranslation();
  const [selectedTap, setSelectedTap] = useState(null);
  const [servingsToEdit, setServingsToEdit] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();
  const { currentLocation } = useSession();

  const { data, loading, error } = useQuery(GET_TAPS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network'
  });

  const taps = useMemo(
    () => sortBy(data?.taps?.filter((tap) => tap.servings?.length > 0 && currentTap?._id !== tap._id) || [], (t) => t.name),
    [currentTap, data]
  );

  useEffect(() => {
    if (selectedTap) {
      setServingsToEdit(
        selectedTap.servings.map(({ vessel, size, price, volumeUnit, currency }, index) => ({
          title: `${t('CopyServingsDialog.Serving')} ${index + 1}`,
          ref: createRef(),
          serving: {
            key: shortid.generate(),
            vessel: vessel,
            size,
            price,
            settings: { volumeUnit, currency }
          }
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTap]);

  useEffect(() => {
    if (isDeleting) {
      setIsDeleting(false);
    }
  }, [isDeleting]);

  const handleCopy = async () => {
    let updatedServings = [];
    let hasError = false;
    for (let i = 0; i < servingsToEdit.length; i++) {
      const ref = servingsToEdit[i].ref;
      const updatedItem = await ref.current.handleSave();
      if (updatedItem) {
        updatedServings.push(updatedItem);
      } else {
        hasError = true;
      }
    }
    if (!hasError) {
      onCopyServings(updatedServings);
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedTap(null);
    setIsDeleting(false);
    setServingsToEdit([]);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          height: '800px'
        }
      }}>
      <FeedbackBar message={error} />
      <DialogTitle>
        <Grid container item alignItems="center">
          <Grid item xs={2}>
            <ArrowBack onClick={onClose} />
          </Grid>
          <Grid item xs={8} className={classes.pageHeading}>
            {t('CopyServingsDialog.Title').toUpperCase()}
          </Grid>
          <Grid item xs={2} />
        </Grid>

        <Grid item>
          {loading ? (
            <Loading className={classes.loading} />
          ) : (
            <>
              <Typography className={classes.selectTapLabel} variant="h6">
                {t('CopyServingsDialog.SelectTapLabel')}
              </Typography>
              <Autocomplete
                options={taps}
                getOptionLabel={(tap) => tap.beverage.name}
                onChange={(event, value) => setSelectedTap(value)}
                renderInput={(params) => <TextField {...params} label={t('CopyServingsDialog.SelectTap')} variant="outlined" fullWidth />}
              />
            </>
          )}
        </Grid>
        {servingsToEdit.length > 0 && <div className={classes.separator} />}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          {selectedTap &&
            !isDeleting &&
            servingsToEdit.map((servingToEdit) => (
              <Grid item key={servingToEdit.key} className={classes.servingContainer}>
                <Grid item xs={12} className={classes.servingHeaderContainer}>
                  <Typography className={classes.servingTitle} variant="h6">
                    {servingToEdit.title}
                  </Typography>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      endIcon={<CloseIcon className={classes.icon} />}
                      onClick={() => {
                        setIsDeleting(true);
                        setServingsToEdit(servingsToEdit.filter((s) => s.serving.key !== servingToEdit.serving.key));
                      }}>
                      {t('CopyServingsDialog.Delete')}
                    </Button>
                  </Grid>
                </Grid>
                <EditServingForm serving={servingToEdit.serving} ref={servingToEdit.ref} showActions={false} />
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} color="secondary">
          {t('CopyServingsDialog.Cancel')}
        </Button>
        <Button onClick={handleCopy} disabled={servingsToEdit.length === 0} color="primary" variant="contained">
          {t('CopyServingsDialog.CopyServings')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
