import React, { useLayoutEffect, useRef, useState } from 'react';
import { MenuPrintConfig, MenuPrintPageProps } from './types';
import VerticalTap from './VerticalTap';
import { getGroupName } from './utils';

const chunkByGroups = (data: any[]) => {
  const result: any[][] = [];
  let currentGroup: any[] = [];
  data.forEach((entry, index) => {
    if (entry.groupName && index !== 0) {
      if (currentGroup.length) {
        result.push(currentGroup);
      }
      currentGroup = [];
      currentGroup.push(entry);
    } else {
      currentGroup.push(entry);
    }
  });
  if (currentGroup.length) {
    result.push(currentGroup);
  }
  return result;
};

const TapGroup: React.FC<{ data: MenuPrintPageProps['columns'][number]; config: MenuPrintConfig }> = ({ data, config }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState(100);
  const isGrouped = config.groupBy !== 'none';

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  }, []);

  const dashStyle: any = {
    position: 'absolute',
    width: '100%',
    borderBottomWidth: 1,
    borderColor: config.theme.dash,
    borderStyle: 'dashed'
  };
  if (isGrouped) {
    dashStyle.marginLeft = 28;
    dashStyle.width = 'calc(100% - 28px)';
  }

  return (
    <div id="tapgroup" ref={containerRef}>
      {data.map((entry: any, index: any) => {
        if (!entry) {
          return null;
        }

        if (entry.groupName) {
          return null;
        }
        const groupName = data[index - 1]?.groupName;
        const isPreviousItemAGroup = groupName && isGrouped;
        const showHeader = isGrouped && (isPreviousItemAGroup || index === 0);
        const headerName = showHeader ? getGroupName(entry, config.groupBy, 'style') : null;

        return (
          <>
            {index > 0 && !isPreviousItemAGroup && <div style={dashStyle}></div>}
            <VerticalTap key={entry._id} tap={entry} config={config} headerName={headerName} tapsGroupHeight={containerHeight} />
          </>
        );
      })}
    </div>
  );
};

const VerticalColumnComponent: React.FC<{ data: MenuPrintPageProps['columns'][number]; config: MenuPrintConfig }> = ({ data, config }) => {
  const chunkedData = chunkByGroups(data);
  return (
    <div style={{ position: 'relative', padding: '0 0px 0 0px' }}>
      {chunkedData.map((categoryData, index) => (
        <TapGroup key={`tapgroup-${index}`} data={categoryData} config={config} />
      ))}
    </div>
  );
};

export default VerticalColumnComponent;
