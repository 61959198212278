interface ForwardSlashIconProps {
  color?: string;
  className?: string;
}

const ForwardSlashIcon = ({ color, className }: ForwardSlashIconProps) => (
  <svg className={className} width="7" height="10" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.86621" y="0.651855" width="6" height="1" transform="rotate(120 3.86621 0.651855)" fill={color || '#FD5C36'} />
  </svg>
);

export default ForwardSlashIcon;
