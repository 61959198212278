import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from '../../components/ColorPicker';
import { FontPicker } from '../../components/FontPicker';
import useLocalStorage from '../../contexts/localStorage';
import { useSession } from '../../contexts/session';
import * as theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  selectMenu: {
    border: `1px solid ${theme.LightGrey}`
  }
}));

export const DialogSettings = ({ setParams, tapsPresentationType, tapsCount, prefix }) => {
  const { t } = useTranslation();
  const { premium } = useSession();

  const classes = useStyles();

  const [settings, setSettings] = useLocalStorage(prefix, {
    backgroundColor: theme.White,
    foregroundColor: theme.DarkSlateGrey,
    foregroundColor2: theme.MediumSlateGrey,
    primaryFont: 'Open Sans',
    secondaryFont: 'Unica One',
    showTapNumber: true,
    showLocation: false,
    showBeverageImage: false,
    hideBeverageSourceName: false,
    showLogo: true,
    fontSize: '1rem',
    showServings: true,
    showServingSize: false,
    showServingCurrency: false,
    showDescription: false,
    sortTapsBy: 'handle',
    groupTapsBy: 'none',
    tapStart: 1,
    tapEnd: 0
  });

  useEffect(() => {
    if (tapsCount > 0 && settings.tapEnd === 0) {
      setSettings((prev) => ({ ...prev, tapEnd: tapsCount }));
    }
  }, [setSettings, settings, tapsCount]);

  const presentShowLogoOption = premium && tapsPresentationType === 'qrCode';

  const handleChange = (key, transform) => (event) => {
    const fieldValue = event.target?.value || !!event.target?.checked;
    setSettings((state) => ({
      ...state,
      [key]: transform ? transform(event) : fieldValue
    }));
  };

  const handleColor = (key) => (color) => {
    setSettings((state) => ({ ...state, [key]: color.hex }));
  };

  useEffect(() => {
    let params = Object.entries(settings)
      .filter(([key, value]) => value !== undefined)
      .map((e) => e.join('=').replace('#', '').replace(' ', '%20'))
      .join('&');
    setParams(params);
  }, [settings, setParams]);

  return (
    <Grid item>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginTop: 20 }}>
            {t('DialogSettings.Settings')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="grouped-select">{t('DialogSettings.SortBy')}</InputLabel>
              <Select
                defaultValue=""
                value={settings.sortTapsBy}
                onChange={handleChange('sortTapsBy')}
                label={t('DialogSettings.SortBy')}
                MenuProps={{
                  classes: { paper: classes.selectMenu }
                }}>
                <MenuItem value="handle">{t('DialogSettings.TapHandleNumber')}</MenuItem>
                <MenuItem value="abv">{t('DialogSettings.BeverageABV')}</MenuItem>
                <MenuItem value="style">{t('DialogSettings.Style')}</MenuItem>
                <MenuItem value="category">{t('DialogSettings.Category')}</MenuItem>
                <MenuItem value="breweryName">{t('DialogSettings.BreweryName')}</MenuItem>
                <MenuItem value="beverageName">{t('DialogSettings.BeverageName')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="grouped-select">{t('DialogSettings.GroupBy')}</InputLabel>
              <Select
                defaultValue="none"
                value={settings.groupTapsBy}
                onChange={handleChange('groupTapsBy')}
                MenuProps={{
                  classes: { paper: classes.selectMenu }
                }}
                label={t('DialogSettings.GroupBy')}>
                <MenuItem value="none">
                  <em>{t('DialogSettings.None')}</em>
                </MenuItem>
                <MenuItem value="style">{t('DialogSettings.Style')}</MenuItem>
                <MenuItem value="category">{t('DialogSettings.Category')}</MenuItem>
                <MenuItem value="brewery">{t('DialogSettings.Brewery')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {tapsPresentationType !== 'qrCode' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" style={{ marginTop: 20 }}>
              {t('PageEditForm.TapList')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              label={t('PageEditForm.TapNoStart')}
              type="number"
              variant="outlined"
              value={settings.tapStart}
              onChange={handleChange('tapStart')}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              label={t('PageEditForm.TapNoEnd')}
              type="number"
              variant="outlined"
              value={settings.tapEnd}
              onChange={handleChange('tapEnd')}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              label={t('PageEditForm.TapsShown')}
              type="number"
              variant="outlined"
              disabled
              value={settings.tapEnd - settings.tapStart + 1 || 0}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginTop: 20 }}>
            {t('DialogSettings.Colors')}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <ColorPicker
            color={settings.backgroundColor}
            label={t('DialogSettings.BackgroundColor')}
            onColorSelected={handleColor('backgroundColor')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <ColorPicker
            color={settings.foregroundColor}
            label={t('DialogSettings.TextColor')}
            onColorSelected={handleColor('foregroundColor')}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <ColorPicker
            color={settings.foregroundColor2}
            label={t('DialogSettings.AltTextColor')}
            onColorSelected={handleColor('foregroundColor2')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('PageEditForm.TextSize')}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="display-size">{t('PageEditForm.TextSize')}</InputLabel>
            <Select
              labelId="display-size"
              value={settings.fontSize}
              onChange={handleChange('fontSize')}
              labelWidth={100}
              MenuProps={{
                classes: { paper: classes.selectMenu }
              }}>
              <MenuItem value={'0.8rem'}>{t('PageEditForm.Small')}</MenuItem>
              <MenuItem value={'1rem'}>{t('PageEditForm.Medium')}</MenuItem>
              <MenuItem value={'1.1rem'}>{t('PageEditForm.Large')}</MenuItem>
              <MenuItem value={'1.3rem'}>{t('PageEditForm.ExtraLarge')}</MenuItem>
              <MenuItem value={'1.5rem'}>{t('PageEditForm.ExtraExtraLarge')}</MenuItem>
              <MenuItem value={'1.7rem'}>{t('PageEditForm.SuperSize')}</MenuItem>
              <MenuItem value={'1.8rem'}>{t('PageEditForm.HulkMode')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginTop: 20 }}>
            {t('DialogSettings.Fonts')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FontPicker
            value={settings.primaryFont}
            label={t('DialogSettings.Primary')}
            onChange={handleChange('primaryFont', (font) => font)}
          />
        </Grid>
        <Grid item xs={6}>
          <FontPicker
            value={settings.secondaryFont}
            label={t('DialogSettings.Secondary')}
            onChange={handleChange('secondaryFont', (font) => font)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginTop: 20 }}>
            {t('DialogSettings.Options')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowTapNumber')}
            control={<Checkbox color="primary" checked={settings.showTapNumber} onChange={handleChange('showTapNumber')} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowBeverageImage')}
            control={<Checkbox color="primary" checked={settings.showBeverageImage} onChange={handleChange('showBeverageImage')} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowBreweryName')}
            control={
              <Checkbox
                color="primary"
                checked={!settings.hideBeverageSourceName}
                onChange={handleChange('hideBeverageSourceName', ({ target }) => !target.checked)}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowServings')}
            control={<Checkbox color="primary" checked={settings.showServings} onChange={handleChange('showServings')} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowServingSize')}
            control={<Checkbox color="primary" checked={settings.showServingSize} onChange={handleChange('showServingSize')} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowServingCurrency')}
            control={<Checkbox color="primary" checked={settings.showServingCurrency} onChange={handleChange('showServingCurrency')} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowLocation')}
            control={<Checkbox color="primary" checked={settings.showLocation} onChange={handleChange('showLocation')} />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label={t('DialogSettings.ShowBeverageDescription')}
            control={<Checkbox color="primary" checked={settings.showDescription} onChange={handleChange('showDescription')} />}
          />
        </Grid>
        {presentShowLogoOption && (
          <Grid item xs={12} md={6}>
            <FormControlLabel
              label={t('DialogSettings.ShowLogo')}
              control={<Checkbox color="primary" checked={settings.showLogo} onChange={handleChange('showLogo')} />}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
