import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { DeviceRegistration } from './display/registration';
import { Display } from './display/DisplayDevice';
import { DeviceContext } from '../../contexts/device';
import { useEnableWebSockets } from '../../contexts/enableWebSockets';

export const GoogleCast = withRouter(({ match }) => {
  const code = match.params?.deviceId;
  const { device, registerDevice } = useContext(DeviceContext);
  const { toggleWebSockets } = useEnableWebSockets();

  toggleWebSockets(true);

  const handleDeviceUpdated = (device) => {
    registerDevice(device);
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js';
    //script.async = true;

    script.onload = () => {
      /* global cast */
      const context = cast.framework.CastReceiverContext.getInstance();
      const options = new cast.framework.CastReceiverOptions();
      // prevent our non-media receiver from timing out
      options.disableIdleTimeout = true;
      options.maxInactivity = 3600;
      context.start(options);
      //cast.framework.CastReceiverContext.getInstance().start();
    };

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  return (
    <div className="device chromecast">
      {(!device || !device.screen) && <DeviceRegistration deviceType="chromecast" code={code} onDeviceUpdated={handleDeviceUpdated} />}
      {device && device.screen && <Display />}
    </div>
  );
});
