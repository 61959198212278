const TapHeaderUnderline = ({ color, className }: { color: string; className: string }) => (
  <svg
    className={className}
    width="100%"
    height="16"
    viewBox="0 0 285 16"
    preserveAspectRatio="none"
    fill={color}
    xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H285L277.525 16H0V0Z" />
  </svg>
);

export default TapHeaderUnderline;
