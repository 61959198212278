import { t } from 'i18next';
import React, { useState, useRef, useEffect } from 'react';
import MoreMenuIcon from '../images/icon-more-menu';

interface MenuItem {
  label: string;
  onClick: (e: React.MouseEvent | React.TouchEvent) => void;
  className?: string;
  danger?: boolean;
}

interface DropdownMenuProps {
  items: MenuItem[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmingItem, setConfirmingItem] = useState<number | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setConfirmingItem(null);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
      setConfirmingItem(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = (e: React.MouseEvent | React.TouchEvent, item: MenuItem, index: number) => {
    if (item.danger) {
      if (confirmingItem === index) {
        item.onClick(e);
        setIsOpen(false);
        setConfirmingItem(null);
      } else {
        setConfirmingItem(index);
      }
    } else {
      item.onClick(e);
      setIsOpen(false);
    }
  };

  return (
    <div className="relative" ref={menuRef} onClick={(e) => e.stopPropagation()}>
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleMenu();
        }}
        className={`w-10 ml-auto text-dark-grey hover:bg-[#FFF5F2] cursor-pointer rounded-full p-2 ${isOpen ? 'bg-[#FFF5F2]' : ''}`}>
        <MoreMenuIcon className="w-6 h-6" />
      </div>
      {isOpen && (
        <div className="whitespace-nowrap absolute right-[100%] -translate-y-4 min-w-[180px] w-fit bg-white border border-[#E5E5E5] rounded-lg shadow-[0_2px_12px_rgba(0,0,0,0.08)] z-10">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={(e) => handleItemClick(e, item, index)}
              className={`p-4 text-md text-blackish hover:bg-gray-100 cursor-pointer relative h-[52px] ${item.className || ''}`}>
              <div
                className={`absolute inset-x-4 transition-all duration-200 ${
                  confirmingItem === index ? '-translate-y-8 opacity-0' : 'translate-y-0 opacity-100'
                }`}>
                {item.label}
              </div>
              {item.danger && (
                <div
                  className={`absolute inset-x-4 transition-all duration-200 ${
                    confirmingItem === index ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
                  }`}>
                  {String(t('DropdownMenu.ConfirmDeleteTap'))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
