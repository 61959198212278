import gql from 'graphql-tag';
import { LOCATION_FIELDS, USER_FIELDS } from '../../data/graphql';

export const UPDATE_ACCOUNT = gql`
    mutation($firstName: String!, $lastName: String!, $email: String!){
        user: updateAccount(firstName:$firstName, lastName:$lastName, email:$email){
            ${USER_FIELDS}
        }
    }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($password: String!) {
    changePassword(password: $password) {
      success
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation {
    deleteAccount {
      success
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation ($language: String!) {
    updateLanguage(language: $language) {
      success
    }
  }
`;

export const CREATE_SUBSCRIPTION = gql`
  mutation ($priceId: String!) {
    createSubscription(priceId: $priceId) {
      stripeCheckoutUrl
    }
  }
`;

export const EDIT_SUBSCRIPTION = gql`
  mutation {
    editSubscription {
      stripePortalUrl
    }
  }
`;

export const DELETE_LOCATION = gql`
    mutation ($id: MongoID!) {
        locationRemoveById(_id: $id) {
            recordId
            record{
                ${LOCATION_FIELDS}
            }
        }
    }
`;

export const EDIT_LOCATION = gql`
    mutation ($record: UpdateByIdLocationInput!) {
        locationUpdateById(record: $record) {
            recordId
            record{
                ${LOCATION_FIELDS}
            }
        }
    }
`;

export const CREATE_LOCATION = gql`
    mutation ($record: CreateOneLocationInput!) {
        locationCreateOne(record: $record) {
            recordId
            record{
                ${LOCATION_FIELDS}
            }
        }
    }
`;

export const CREATE_LOCATION_MEMBER = gql`
  mutation ($firstName: String!, $lastName: String!, $email: String!, $role: String!, $locationIds: [String!]!) {
    createMemberUser(firstName: $firstName, lastName: $lastName, email: $email, role: $role, locationIds: $locationIds) {
      success
    }
  }
`;

export const UPDATE_LOCATION_MEMBER = gql`
  mutation ($email: String!, $locationIds: [String!]!) {
    updateMemberUser(email: $email, locationIds: $locationIds) {
      success
    }
  }
`;

export const DELETE_LOCATION_MEMBER = gql`
  mutation ($email: String!, $locationIds: [String!]!) {
    deleteMemberUser(email: $email, locationIds: $locationIds) {
      success
    }
  }
`;
