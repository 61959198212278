import { Orange, White } from '../../theme';

import HeroBg from '../../images/hero-bg-mobile.svg';

const styles = (theme) => ({
  root: {
    height: '100%',
    backgroundColor: 'white'
  },
  header: {
    padding: '1.5rem',
    paddingTop: '2rem',
    backgroundColor: Orange,
    minHeight: '250px',
    paddingBottom: '80px',
    color: White,
    '& > p': {
      opacity: '0.6',
      margin: 0
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
      backgroundImage: `url(${HeroBg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom left',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      color: 'white'
    }
  },
  content: {
    padding: '1.5rem',
    backgroundColor: White,
    borderTopLeftRadius: '40px',
    borderTopRightRadius: '40px',
    marginTop: '-80px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '20px',
      marginRight: '20px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginLeft: '0px',
      marginRight: '0px',
      paddingTop: '80px'
    }
  },
  logo: {
    width: '140px',
    marginBottom: '2.5rem'
  },
  form: {
    marginTop: '1rem',
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *': {
      marginBottom: '1rem'
    }
  },
  input: {
    //width: '75%'
  },
  button: {
    marginTop: '2rem',
    padding: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    width: '200px',
    borderRadius: 0,
    color: White,
    fontWeight: 'bold',
    '& > span': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  product: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  productText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '400px'
  },
  glassware: {
    height: '20px'
  },
  productHeading: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    margin: '20px 0 0 0'
  },
  productDescription: {
    opacity: '0.7',
    margin: '0 0 10px 0'
  },
  barScene: {
    height: '400px'
  }
});

export default styles;
