import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, Fade, Grid, Typography } from '@material-ui/core';

import { ArrowBack } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  description: {
    marginTop: '20px',
    whiteSpace: 'pre-wrap'
  },
  formContainer: {
    paddingTop: '10px'
  }
}));

export const DeleteDialog = ({ show, title, description, onCancel, onDelete }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(show);
  const cancelButtonRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    onCancel?.();
  };

  const handleDelete = () => {
    onDelete?.();
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted fullScreen={false} onClose={onCancel}>
      <DialogContent style={{ maxWidth: 450 }}>
        <>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item container>
              <Grid item xs={2}>
                <ArrowBack onClick={onCancel} className={'cursor-pointer'} />
              </Grid>
              <Grid item xs={8} className={classes.title}>
                {title}
              </Grid>
            </Grid>
            <Grid item container className={classes.formContainer}>
              <Typography className={classes.description}>{description}</Typography>
            </Grid>
            <Grid item>
              <Button variant="text" color="default" onClick={handleClose} ref={cancelButtonRef} style={{ marginRight: 8 }}>
                {t('DeleteDialog.CancelButton')}
              </Button>
              <Button variant="text" color="secondary" onClick={handleDelete}>
                {t('DeleteDialog.DeleteButton')}
              </Button>
            </Grid>
          </Grid>
        </>
      </DialogContent>
    </Dialog>
  );
};
