import { makeStyles } from '@material-ui/core';
import BeerIcon from '../../../images/icon-cup-beer.tsx';
import VerticalBG from '../../../images/vertical_background.png';
import VerticalMask from '../../../images/vertical_mask.svg';
import VerticalBubbles from '../../../images/vertical_bubbles.png';

import { VERTICAL_HEADER_TYPE } from './enums.ts';

const VerticalPrimary = ({ title, theme }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.titleBackground} ${classes.backgroundPattern} ${classes.header}`} style={{ backgroundColor: theme.primary }}>
      <div className={classes.title}>{title}</div>
      <div className={classes.iconContainer}>
        <div className={classes.icon}>
          <BeerIcon color={theme.primary} />
        </div>
      </div>
    </div>
  );
};

const VerticalSecondary = ({ title, theme }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.titleBackground} ${classes.header} ${classes.italic}`} style={{ backgroundColor: theme.secondary }}>
      <div className={classes.title}>{title}</div>
      <div className={classes.iconContainer}>
        <div className={classes.icon}>
          <BeerIcon color={theme.primary} />
        </div>
      </div>
    </div>
  );
};

const VerticalSecondaryAlternateOne = ({ title, theme }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleBackgroundAlternate} style={{ backgroundColor: theme.primary }}>
      <div className={classes.titleAlternate}>{title}</div>
    </div>
  );
};

const VerticalSecondaryAlternateTwo = ({ title, theme }) => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: theme.primary }}>
      <div className={classes.titleAlternate}>{title}</div>
    </div>
  );
};

const VerticalLogo = ({ logo }) => {
  const classes = useStyles();
  return <img className={classes.headerLogo} src={logo} alt="Brand logo" />;
};

const VerticalHeader = ({ config }) => {
  const classes = useStyles();
  const { title, headerType, headerImageUrl, theme } = config;

  let Content = null;
  if (headerType === VERTICAL_HEADER_TYPE.LOGO) {
    Content = <VerticalLogo logo={headerImageUrl} />;
  } else if (headerType === VERTICAL_HEADER_TYPE.SECONDARY) {
    Content = <VerticalSecondary title={title} theme={theme} />;
  } else if (headerType === VERTICAL_HEADER_TYPE.SECONDARY_ALTERNATE_ONE) {
    Content = <VerticalSecondaryAlternateOne title={title} theme={theme} />;
  } else if (headerType === VERTICAL_HEADER_TYPE.SECONDARY_ALTERNATE_TWO) {
    Content = <VerticalSecondaryAlternateTwo title={title} theme={theme} />;
  } else {
    Content = <VerticalPrimary title={title} theme={theme} />;
  }

  return <div className={classes.header}>{Content}</div>;
};

export default VerticalHeader;

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: 580,
    height: 70
  },
  headerLogo: {
    flex: 1
  },
  italic: {
    fontStyle: 'italic'
  },
  icon: {
    backgroundColor: 'white',
    padding: 8,
    borderRadius: '50%',
    boxShadow: '-3px 3px 0px black'
  },
  iconContainer: {
    paddingInline: 16
  },
  title: {
    fontSize: 30,
    fontWeight: 900,
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    fontFamily: 'Bitter',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 580,
    textShadow: '-3px 3px 0px black',
    paddingLeft: 16
  },
  titleAlternate: {
    color: 'white',
    fontSize: 50,
    lineHeight: '6.8rem',
    fontWeight: 900,
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    fontFamily: 'Work Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 580,
    paddingLeft: 16
  },
  backgroundPattern: {
    background: `url(${VerticalBG})`
  },
  titleBackground: {
    color: 'white',
    backgroundColor: '#35495D',
    backgroundSize: 'cover',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  titleBackgroundAlternate: {
    background: `url(${VerticalBubbles})`,
    backgroundSize: 'cover',
    '-webkit-mask-image': `url(${VerticalMask})`,
    maskImage: `url(${VerticalMask})`
  }
}));
