import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TableContainer,
  Typography,
  Zoom,
  makeStyles,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import React, { ComponentType, ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBack } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import { SessionContext } from '../../contexts/session';
import { useMutation } from 'react-apollo';
import { UPDATE_LOCATION_MEMBER } from './mutations';
import { useMemberLocationList } from '../../hooks/useMemberLocationList';
import { Alert } from '@material-ui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    marginTop: '20px'
  },
  formContainer: {
    paddingTop: '10px'
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  tableContainer: {
    '@media (min-width: 960px)': {
      maxHeight: '400px'
    }
  }
}));

interface ManageLocationUsersProps {
  userEmail?: any;
  onClose: () => void;
}

export const ManageLocationUsers = ({ userEmail = '', onClose }: ManageLocationUsersProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { account, locations, locRefetch } = useContext(SessionContext);

  const [updateLocationMember, { loading, error: graphqlError }] = useMutation(UPDATE_LOCATION_MEMBER, {});

  const userAccess = account?.users?.find((user: any) => {
    return user.user.local.email === userEmail;
  });

  const { MemberLocationListComponent, checkedLocations } = useMemberLocationList(locations, userAccess?.locations);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (graphqlError) {
      const error = graphqlError.graphQLErrors[0]?.message || t('ManageLocationMembership.UnknownError');
      setErrorMessage(error);
    }
  }, [graphqlError, t]);

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };

  const handleUpdateLocationMember = async () => {
    try {
      await updateLocationMember({
        variables: {
          email: userAccess.user.local.email,
          locationIds: checkedLocations.asArray()
        }
      });
      await locRefetch();
    } catch (e) {
      onClose();
    } finally {
      onClose();
    }
  };

  if (!userAccess) {
    return null;
  }

  return (
    <>
      <Dialog
        open={userEmail !== null}
        TransitionComponent={Transition as ComponentType<TransitionProps & { children?: ReactElement<any, any> | undefined }>}
        keepMounted
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={onClose}>
        <DialogContent>
          <Grid container>
            <Grid item xs={2}>
              <ArrowBack onClick={onClose} className={'cursor-pointer'} />
            </Grid>
            <Grid item xs={8} className={classes.pageHeading}>
              {t('ManageLocationMembership.ManageUsersTitle')}
            </Grid>
          </Grid>
          <Grid container className={classes.formContainer} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.sectionHeading}>
                {t('Account.LocationsTitle')}
              </Typography>
              <Grid item xs={12}>
                <TableContainer className={classes.tableContainer} component={Paper}>
                  <MemberLocationListComponent />
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end" style={{ margin: 20 }}>
              <Button onClick={handleUpdateLocationMember} variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={20} /> : t('ManageLocationMembership.Done')}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {errorMessage && (
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
