import gql from 'graphql-tag';
import { USER_FIELDS } from '../../data/graphql';

export const UPDATE_USER = gql`
    mutation($user: UpdateByIdUserInput!){
        userUpdateById(record:$user){
            record {
                ${USER_FIELDS}
            }
        }
    }
`;
