import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';

import { QRCodeCanvas } from 'qrcode.react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../contexts/session';
import Logo from '../../images/pourwall-qrcode.svg';

const useStyles = makeStyles({
  fullPage: {
    // A4 page
    position: 'relative',

    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    gap: '2mm', // bleeding between stickers

    width: '210mm', // Never change this parameter, it represents the physical width.
    height: '297mm', // Never change this parameter, it represents the physical height.
    padding: '3mm' // 3mm recommended minimal bleed for printing
  },
  item: {
    //represent each sticker
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    minWidth: '280px',
    maxWidth: '380px',
    height: '360px',
    maxHeight: '360px',
    padding: '5mm',
    border: `1px dashed #2D3135`
  },
  itemTitle: {
    //Brand Name
    color: '#2D3135',
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '33px',
    marginBottom: '20px',
    maxWidth: '340px',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'clip',
    whiteSpace: 'nowrap'
  },
  itemDesc: {
    // QRCode use description
    color: '#2D3135',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    maxWidth: '256px',
    textAlign: 'center'
  },
  codeContainer: {
    // the container of the QRCode
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '160px', //must be greater than 128, which is the QRCode width
    height: '160px', //must be greater than 128, which is the QRCode height
    marginBottom: '20px',
    padding: '3mm',
    border: '1px solid #2D3135',
    borderRadius: '2px'
  },
  img: {
    // Pourwall brand
    position: 'absolute',
    left: 10,
    bottom: 20,
    width: '70px',
    height: '12px',

    fontSize: '8px'
  },
  qrImage: {
    // QRCode image
    width: '128px',
    height: '128px'
  }
});

export const PrintQr = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { currentLocation, premium } = useContext(SessionContext);
  const params = location.search.slice(1);
  const showLogo = new URLSearchParams(params).get('showLogo') !== 'false';
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState('');
  const qrRef = useRef();

  const qrs = Array.from(
    new Array(6),
    () => `${window.location.protocol}//${window.location.hostname}/embed/${currentLocation?._id}/${params}`
  );

  useEffect(() => {
    setTimeout(() => {
      ReactGA.event({
        category: 'print_start',
        action: 'print_start',
        label: 'Print QR Code'
      });
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'print_start', {
          event_category: 'print_start',
          event_label: 'Print QR Code'
        });
      }

      window.print();
    }, 2000);
  }, []);

  useEffect(() => {
    if (qrRef.current) {
      const canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
        setQrCodeDataUrl(canvas.toDataURL('image/png'));
      }
    }
  }, []);

  if (!premium) {
    return null;
  }

  return (
    <>
      <div className={classes.fullPage}>
        {qrs.map((value, index) => (
          <div className={classes.item} key={index}>
            <span className={classes.itemTitle}> {currentLocation?.name} </span>
            <div className={classes.codeContainer} ref={qrRef}>
              {qrCodeDataUrl ? (
                <img src={qrCodeDataUrl} alt="QR Code" className={classes.qrImage} />
              ) : (
                <QRCodeCanvas value={value} size={128} />
              )}
            </div>
            <span className={classes.itemDesc}>{t('QR.ScanTheQRCode')}</span>
            {showLogo && (
              <div className={classes.img}>
                <label>{t('QR.PoweredBy')}</label>
                <img src={Logo} width="70" height="12" alt="POURWALL" className={classes.logo} />
              </div>
            )}
          </div>
        ))}
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
                @page {
                    margin: 0;
                    size: auto;
                }
                body {
                    background: white !important;
                    -webkit-print-color-adjust: exact !important;
                }
            `
        }}
      />
    </>
  );
};
