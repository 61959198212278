import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, ArrowDropDown } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { useMutation } from 'react-apollo';
import { filter } from 'lodash';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../../contexts/session';

import TapListIcon from '../../../images/taps.png';
import ImageIcon from '../../../images/upload-image.png';
import CheckedImage from '../../../images/checked.png';

import { ActionBar } from '../../../components/ActionBar';
import FileUpload from '../../../components/FileUpload';
import { FeedbackBar } from '../../../components/FeedbackBar';
import { ColorPicker } from '../../../components/ColorPicker';

import { SIGN_FILE_FOR_UPLOAD } from '../mutations';
import { checkFileSize, MAX_SCREEN_IMAGE_SIZE_BYTES, uploadToAws } from '../../../util/files';

import { FontFamilyUnicaOne, LightGrey } from '../../../theme';
import { Themes } from './themes';
import { ThemeTile } from './ThemeTile';
import { FontPicker } from '../../../components/FontPicker';
import CustomLink from '../../../components/CustomLink';
import { TAP_ORIENTATION } from '../../../util/enums';
// import { TAP_ORIENTATION } from '../../../util/enums';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  sectionHeading: {
    marginTop: '20px'
  },
  formContainer: {
    paddingTop: '10px'
  },
  pageTypeCard: {
    padding: '30px 10px',
    textAlign: 'center',
    cursor: 'pointer',

    '& > img': {
      width: 'auto',
      height: 122,
      marginBottom: '15px'
    },
    '& > div': {
      fontWeight: 'bold'
    },
    '&.active': {
      position: 'relative',
      backgroundColor: '#f9f9f9',
      '&::after': {
        background: `url(${CheckedImage}) no-repeat`,
        backgroundSize: 'cover',
        content: '""',
        height: 36,
        width: 36,
        position: 'absolute',
        top: -8,
        right: -8
      }
    }
  },
  themeSelected: {
    position: 'relative',

    '&::after': {
      background: `url(${CheckedImage}) no-repeat`,
      backgroundSize: 'cover',
      content: '""',
      height: 36,
      width: 36,
      position: 'absolute',
      top: -8,
      right: -8
    }
  },
  image: {
    width: '100%',
    marginTop: 15,

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 250
    }
  },
  imageDimesionInfo: {
    fontSize: '0.9em',
    opacity: 0.6,
    fontFamily: FontFamilyUnicaOne,
    marginTop: 15
  },
  backgroundImage: {
    width: '100%',
    marginTop: 0,

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 250
    }
  },
  backgroundImageDarken: {
    filter: 'brightness(70%)'
  },
  selectMenu: {
    border: `1px solid ${LightGrey}`
  }
}));

export const PageEditForm = ({ screen, page, onCancel, onSave, onDelete }) => {
  const { t, i18n } = useTranslation();
  const session = useContext(SessionContext);
  const { currentLocation, premium } = session;

  const classes = useStyles();

  const [interstitialType, setInterstitialType] = useState(page.type);
  const [error, setError] = useState(null);

  const [number, setNumber] = useState(page.number);
  const [name, setName] = useState(page.name);
  const [type, setType] = useState(page.type);
  const [rotationDuration, setRotationDuration] = useState(page.rotationDuration);
  const [tapStart, setTapStart] = useState(page.tapStart);
  const [tapEnd, setTapEnd] = useState(page.tapEnd);
  const [imageUrl, setImageUrl] = useState(page.imageUrl);
  //const [imagePosition, setImagePosition] = useState(page.imagePosition);

  // style related fields
  const [styleCategory, setStyleCategory] = useState(page.style?.pageKey ? 'Copy From...' : page.style?.theme ? 'Select Theme' : 'Custom');
  const [stylePageKey, setStylePageKey] = useState(page.style?.pageKey);
  const [styleThemeName, setStyleThemeName] = useState(page.style?.theme);

  const themeOptions = page.style?.themeOptions || {};

  const [menuType, setMenuType] = useState(themeOptions.menuType || 'normal');
  const [backgroundColor, setBackgroundColor] = useState(themeOptions.backgroundColor || '#3c3c3c');
  const [foregroundColor, setForegroundColor] = useState(themeOptions.foregroundColor || '#efefef');
  const [foregroundColor2, setForegroundColor2] = useState(themeOptions.foregroundColor2 || '#f7e63b');
  const [textSize, setTextSize] = useState(themeOptions.textSize || 'medium');
  const [primaryFont, setPrimaryFont] = useState(themeOptions.primaryFont || 'Open Sans');
  const [secondaryFont, setSecondaryFont] = useState(themeOptions.secondaryFont || 'Unica One');
  const [showTapNumber, setShowTapNumber] = useState(themeOptions.showTapNumber === false ? false : true);
  const [showLocation, setShowLocation] = useState(themeOptions.showLocation || false);
  const [showBeverageImage, setShowBeverageImage] = useState(themeOptions.showBeverageImage || false);
  const [reverseBreweryAndName, setReverseBreweryAndName] = useState(themeOptions.reverseBreweryAndName || false);
  const [beverageImageSize, setBeverageImageSize] = useState(themeOptions.beverageImageSize || 'none');
  const [hideBeverageSourceName, setHideBeverageSourceName] = useState(themeOptions.hideBeverageSourceName || false);
  //const [showRating, setShowRating] = useState(themeOptions.showRating || false);
  const [showHeading, setShowHeading] = useState(themeOptions.showHeading || false);
  const [showLogo, setShowLogo] = useState(themeOptions.showLogo === false ? false : true);
  const [showServings, setShowServings] = useState(themeOptions.showServings === false ? false : true);
  const [showStripes, setShowStripes] = useState(themeOptions.showStripes || false);
  const [showServingSize, setShowServingSize] = useState(themeOptions.showServingSize || false);
  const [showServingCurrency, setShowServingCurrency] = useState(themeOptions.showServingCurrency || false);
  const [showTapsDescription, setShowTapsDescription] = useState(themeOptions.showTapsDescription || false);
  const [columns, setColumns] = useState(themeOptions.columns || 2);
  const [tapsOrientation, setTapsOrientation] = useState(themeOptions.tapsOrientation || 'left_to_right');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(themeOptions.backgroundImageUrl);
  const [backgroundImagePreviewUrl, setBackgroundImagePreviewUrl] = useState();
  const [backgroundImageFile, setBackgroundImageFile] = useState();
  const [backgroundImageFileSignedUploadUrl, setBackgroundImageFileSignedUploadUrl] = useState();
  const [showBackgroundImageHelper, setShowBackgroundImageHelper] = useState(themeOptions.showBackgroundImageHelper || false);
  const [kegMonitoringPartner, setKegMonitoringPartner] = useState(themeOptions.kegMonitoringPartner || 'none');
  const [kegMonitoringApiKey, setKegMonitoringApiKey] = useState(themeOptions.kegMonitoringApiKey || '');

  const [imageFile, setImageFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState();
  const [signedAwsUrl, setSignedAwsUrl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [signFile] = useMutation(SIGN_FILE_FOR_UPLOAD);

  const handleOpenThemeCategoryMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseThemeCategoryMenu = (category) => {
    if (category) {
      setStyleCategory(category);
      if (category === 'Custom') {
        setStylePageKey('');
        setStyleThemeName('');
      } else if (category === 'Select Theme') {
        setStylePageKey('');
      } else if (category === 'Copy From...') {
        setStyleThemeName('');
      }
    }
    setAnchorEl(null);
  };

  const selectTheme = (theme) => {
    setStyleThemeName(theme.name);
    setMenuType(theme.themeOptions.menuType);
    setBackgroundImageUrl(theme.themeOptions.backgroundImageUrl);
    setBackgroundColor(theme.themeOptions.backgroundColor);
    setForegroundColor(theme.themeOptions.foregroundColor);
    setForegroundColor2(theme.themeOptions.foregroundColor2);
    setShowBeverageImage(theme.themeOptions.showBeverageImage);
    setBeverageImageSize(theme.themeOptions.beverageImageSize);
    setShowHeading(theme.themeOptions.showHeading);
    setShowLogo(theme.themeOptions.showLogo);
    setShowServings(theme.themeOptions.showServings);
    setShowStripes(theme.themeOptions.showStripes);
    setShowTapNumber(theme.themeOptions.showTapNumber);
    setShowLocation(theme.themeOptions.showLocation);
    setShowServingSize(theme.themeOptions.showServingSize);
    setShowServingCurrency(theme.themeOptions.showServingCurrency);
    setShowTapsDescription(theme.themeOptions.showTapsDescription);
    setColumns(theme.themeOptions.columns);
    setHideBeverageSourceName(theme.themeOptions.hideBeverageSourceName);
    setReverseBreweryAndName(theme.themeOptions.reverseBreweryAndName);
    setTextSize(theme.themeOptions.textSize);
    setShowBackgroundImageHelper(theme.themeOptions.showBackgroundImageHelper);
    // TODO: most themes don't have fonts defined yet
    setPrimaryFont(theme.themeOptions.primaryFont || primaryFont);
    setSecondaryFont(theme.themeOptions.secondaryFont || secondaryFont);
  };

  const confirmType = () => {
    setType(interstitialType);
    if (interstitialType === 'taps') {
      setTapStart(1);
      setTapEnd(10);
    }
  };

  const handleFileSelection = async (file, previewUrl) => {
    //The line below is a workaround to show the error messages everytime a file is chosen.
    setError('');
    const fileSizeError = checkFileSize(file, MAX_SCREEN_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setError(fileSizeError.message);
    } else {
      setImagePreviewUrl(previewUrl);
      const variables = {
        locationName: currentLocation?.shortName,
        fileName: file.name,
        fileType: file.type
      };
      const { data, error } = await signFile({ variables });
      if (error) {
        setError(error);
      }
      if (data?.signFile) {
        setImageFile(file);
        setSignedAwsUrl(data.signFile.signedUrl);
        setImageUrl(data.signFile.resourceUrl);
      }
    }
  };

  const handleBackgroundImageSelection = async (file, previewUrl) => {
    //The line below is a workaround to show the error messages everytime a file is chosen.
    setError('');
    const fileSizeError = checkFileSize(file, MAX_SCREEN_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setError(fileSizeError.message);
    } else {
      setBackgroundImagePreviewUrl(previewUrl);
      const variables = {
        locationName: currentLocation?.shortName,
        fileName: file.name,
        fileType: file.type
      };

      const { data, error } = await signFile({ variables });
      if (error) {
        setError(error);
      }
      if (data?.signFile) {
        setBackgroundImageFile(file);
        setBackgroundImageFileSignedUploadUrl(data.signFile.signedUrl);
        setBackgroundImageUrl(data.signFile.resourceUrl);
      }
    }
  };

  const removeBackgroundImage = () => {
    setBackgroundImagePreviewUrl(null);
    setBackgroundImageUrl(null);
    setBackgroundImageFileSignedUploadUrl(null);
    setBackgroundImageFile(null);
  };

  const handleSave = async () => {
    if (imageFile && signedAwsUrl) {
      setLoading(true);
      try {
        await uploadToAws(signedAwsUrl, imageFile);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    }

    if (backgroundImageFile && backgroundImageFileSignedUploadUrl) {
      setLoading(true);
      try {
        await uploadToAws(backgroundImageFileSignedUploadUrl, backgroundImageFile);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    }

    // construct the style object
    const style = {
      pageKey: stylePageKey,
      theme: styleThemeName,
      themeOptions: {
        backgroundColor,
        foregroundColor,
        foregroundColor2,
        textSize,
        showHeading,
        showLogo,
        //showRating,
        menuType,
        showBeverageImage,
        beverageImageSize,
        showTapNumber,
        showLocation,
        showServings,
        showTapsDescription,
        hideBeverageSourceName,
        reverseBreweryAndName,
        showStripes,
        columns,
        backgroundImageUrl,
        showBackgroundImageHelper,
        primaryFont,
        secondaryFont,
        showServingSize,
        showServingCurrency,
        kegMonitoringPartner,
        kegMonitoringApiKey,
        tapsOrientation
      }
    };

    // prepare and return the updated page
    let updatedPage = {
      ...page,
      number,
      name,
      type,
      tapStart,
      tapEnd,
      rotationDuration,
      imageUrl,
      style
    };

    onSave(updatedPage);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <FeedbackBar message={error} />
      <Grid container>
        <Grid item xs={2}>
          <ArrowBack onClick={onCancel} />
        </Grid>
        <Grid item xs={8} className={classes.pageHeading}>
          {page._id ? t('PageEditForm.EditPage') : t('PageEditForm.NewPage')}
        </Grid>
      </Grid>
      {!type && (
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <h2 style={{ textAlign: 'center' }}>{t('PageEditForm.PageAdd')}</h2>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Paper
              variant="outlined"
              className={clsx(classes.pageTypeCard, {
                active: interstitialType === 'taps'
              })}
              onClick={() => setInterstitialType('taps')}>
              <img src={TapListIcon} alt="Tap List" />
              <div>{t('PageEditForm.TapList')}</div>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Paper
              variant="outlined"
              className={clsx(classes.pageTypeCard, {
                active: interstitialType === 'image'
              })}
              onClick={() => setInterstitialType('image')}>
              <img src={ImageIcon} alt="Upload Your Own" />
              <div>{t('PageEditForm.UploadImage')}</div>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '20px' }}>
            <Button variant="contained" color="primary" fullWidth disabled={!interstitialType} onClick={confirmType}>
              {t('PageEditForm.Next')}
            </Button>
          </Grid>
        </Grid>
      )}
      {type && (
        <>
          <Grid container className={classes.formContainer} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.sectionHeading}>
                {t('PageEditForm.Basics')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label={t('PageEditForm.PageTitle')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                label={t('PageEditForm.PageNumber')}
                value={number}
                type="number"
                onChange={(e) => setNumber(Number(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PageEditForm.DurationShown')}
                type="number"
                variant="outlined"
                value={rotationDuration}
                onChange={(e) => setRotationDuration(parseInt(e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">seconds</InputAdornment>
                }}
              />
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: 80 }}>
            {type === 'taps' && (
              <>
                <Typography variant="h2" className={classes.sectionHeading}>
                  {t('PageEditForm.TapList')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label={t('PageEditForm.TapNoStart')}
                      type="number"
                      variant="outlined"
                      value={tapStart}
                      onChange={(e) => setTapStart(parseInt(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label={t('PageEditForm.TapNoEnd')}
                      type="number"
                      variant="outlined"
                      value={tapEnd}
                      onChange={(e) => setTapEnd(parseInt(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      label={t('PageEditForm.TapsShown')}
                      type="number"
                      variant="outlined"
                      disabled
                      value={tapEnd - tapStart + 1 || 0}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {type === 'image' && (
              <>
                {premium && (
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      label={t('PageEditForm.ShowLogo')}
                      control={<Checkbox checked={showLogo} onChange={(e) => setShowLogo(Boolean(e.target.checked))} color="primary" />}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Typography variant="h2" className={classes.sectionHeading}>
                    {t('PageEditForm.Image')}
                  </Typography>
                </Grid>

                <Grid item container xs={6} justifyContent="flex-end" alignItems="center">
                  <FileUpload onFileSelected={handleFileSelection} maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES} />
                </Grid>

                <Grid item xs={12}>
                  {imagePreviewUrl && <img className={classes.image} src={imagePreviewUrl} alt="Preview" />}
                  {!imagePreviewUrl && page.imageUrl && <img className={classes.image} src={page.imageUrl} alt="Custom" />}
                </Grid>

                <div className={classes.imageDimesionInfo}>{t('PageEditForm.DimesionInfo')}</div>
              </>
            )}
            {type === 'taps' && (
              <Grid container item xs={12}>
                <Grid item xs={3}>
                  <Typography variant="h2" className={classes.sectionHeading}>
                    {t('PageEditForm.Style')}
                  </Typography>
                </Grid>
                <Grid container item xs={9} justifyContent="flex-end" alignItems="center">
                  <Button variant="contained" color="primary" onClick={handleOpenThemeCategoryMenu}>
                    {styleCategory}
                    <ArrowDropDown />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => handleCloseThemeCategoryMenu()}
                    classes={{ paper: classes.selectMenu }}>
                    <MenuItem onClick={() => handleCloseThemeCategoryMenu('Custom')}>{t('PageEditForm.Custom')}</MenuItem>
                    <MenuItem onClick={() => handleCloseThemeCategoryMenu('Copy From...')}>{t('PageEditForm.CopyFrom')}</MenuItem>
                    <MenuItem onClick={() => handleCloseThemeCategoryMenu('Select Theme')}>{t('PageEditForm.SelectTheme')}</MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={12}>
                  {styleCategory === 'Custom' && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3">{t('PageEditForm.Size')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="display-size">{t('PageEditForm.Layout')}</InputLabel>
                            <Select
                              labelId="display-size"
                              value={menuType}
                              onChange={(e) => {
                                const newMenuType = e.target.value;
                                if (newMenuType !== 'dense') {
                                  setReverseBreweryAndName(false);
                                }
                                setMenuType(newMenuType);
                              }}
                              labelWidth={i18n.language === 'en-US' ? 50 : 53}
                              MenuProps={{
                                classes: { paper: classes.selectMenu }
                              }}>
                              <MenuItem value={'dense'}>{t('PageEditForm.Dense')}</MenuItem>
                              <MenuItem value={'middle'}>{t('PageEditForm.MiddleGround')}</MenuItem>
                              <MenuItem value={'normal'}>{t('PageEditForm.Relaxed')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="display-size">{t('PageEditForm.TextSize')}</InputLabel>
                            <Select
                              labelId="display-size"
                              value={textSize}
                              onChange={(e) => setTextSize(e.target.value)}
                              labelWidth={i18n.language === 'en-US' ? 68 : 108}
                              MenuProps={{
                                classes: { paper: classes.selectMenu }
                              }}>
                              <MenuItem value={'condensed'}>{t('PageEditForm.Small')}</MenuItem>
                              <MenuItem value={'medium'}>{t('PageEditForm.Medium')}</MenuItem>
                              <MenuItem value={'normal'}>{t('PageEditForm.Large')}</MenuItem>
                              {/* <MenuItem value={'lg'}>{t('PageEditForm.ExtraLarge')}</MenuItem> */}
                              <MenuItem value={'xl'}>{t('PageEditForm.ExtraLarge')}</MenuItem>
                              <MenuItem value={'xl2'}>{t('PageEditForm.ExtraExtraLarge')}</MenuItem>
                              <MenuItem value={'xl3'}>{t('PageEditForm.SuperSize')}</MenuItem>
                              <MenuItem value={'xxl'}>{t('PageEditForm.HulkMode')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="display-size">{t('PageEditForm.Columns')}</InputLabel>
                            <Select
                              labelId="display-size"
                              value={columns}
                              onChange={(e) => setColumns(Number(e.target.value))}
                              labelWidth={i18n.language === 'en-US' ? 70 : 75}
                              MenuProps={{
                                classes: { paper: classes.selectMenu }
                              }}>
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="orientation">{t('PageEditForm.TapsOrientation')}</InputLabel>
                            <Select
                              labelId="orientation"
                              value={tapsOrientation}
                              onChange={(e) => setTapsOrientation(e.target.value)}
                              labelWidth={130}
                              MenuProps={{
                                classes: { paper: classes.selectMenu }
                              }}>
                              <MenuItem value={TAP_ORIENTATION.LEFT_TO_RIGHT}>{t('PageEditForm.LeftToRight')}</MenuItem>
                              <MenuItem value={TAP_ORIENTATION.TOP_TO_BOTTOM}>{t('PageEditForm.TopToBottom')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3" style={{ marginTop: 20 }}>
                            {t('PageEditForm.Colors')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <ColorPicker
                            color={backgroundColor}
                            label={t('PageEditForm.BackgroundColor')}
                            onColorSelected={(c) => setBackgroundColor(c.hex)}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <ColorPicker
                            color={foregroundColor}
                            label={t('PageEditForm.TextColor')}
                            onColorSelected={(c) => setForegroundColor(c.hex)}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <ColorPicker
                            color={foregroundColor2}
                            label={t('PageEditForm.AltTextColor')}
                            onColorSelected={(c) => setForegroundColor2(c.hex)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3" style={{ marginTop: 20 }}>
                            {t('PageEditForm.Fonts')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FontPicker value={primaryFont} label={t('PageEditForm.Primary')} onChange={(f) => setPrimaryFont(f)} />
                        </Grid>
                        <Grid item xs={6}>
                          <FontPicker value={secondaryFont} label={t('PageEditForm.Secondary')} onChange={(f) => setSecondaryFont(f)} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3" style={{ marginTop: 20 }}>
                            {t('PageEditForm.Partners')}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="keg-monitoring">{t('PageEditForm.KegMonitoring')}</InputLabel>
                            <Select
                              labelId="keg-monitoring"
                              value={kegMonitoringPartner}
                              onChange={(e) => setKegMonitoringPartner(e.target.value)}
                              labelWidth={i18n.language === 'en-US' ? 120 : 165}
                              MenuProps={{
                                classes: { paper: classes.selectMenu }
                              }}>
                              <MenuItem value={'none'}>{t('PageEditForm.None')}</MenuItem>
                              <MenuItem value={'kegtron'}>{t('PageEditForm.Kegtron')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          {kegMonitoringPartner === 'kegtron' && (
                            <>
                              <TextField
                                label={t('PageEditForm.KegtronApiKey')}
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={kegMonitoringApiKey}
                                onChange={(e) => setKegMonitoringApiKey(e.target.value)}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3" style={{ marginTop: 20 }}>
                            {t('PageEditForm.Options')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowPageName')}
                            control={
                              <Checkbox checked={showHeading} onChange={(e) => setShowHeading(Boolean(e.target.checked))} color="primary" />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowTapNumber')}
                            control={
                              <Checkbox
                                checked={showTapNumber}
                                onChange={(e) => setShowTapNumber(Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="display-size">{t('PageEditForm.BeverageImages')}</InputLabel>
                            <Select
                              labelId="display-size"
                              value={beverageImageSize}
                              onChange={(e) => {
                                setBeverageImageSize(e.target.value);
                                setShowBeverageImage(e.target.value !== 'none');
                              }}
                              labelWidth={i18n.language === 'en-US' ? 130 : 160}
                              MenuProps={{
                                classes: { paper: classes.selectMenu }
                              }}>
                              <MenuItem value={'none'}>{t('PageEditForm.None')}</MenuItem>
                              <MenuItem value={'small'}>{t('PageEditForm.SmallRound')}</MenuItem>
                              <MenuItem value={'small_square'}>{t('PageEditForm.SmallSquare')}</MenuItem>
                              <MenuItem value={'large'}>{t('PageEditForm.LargeRound')}</MenuItem>
                              <MenuItem value={'large_square'}>{t('PageEditForm.LargeSquare')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowBreweryName')}
                            control={
                              <Checkbox
                                checked={!hideBeverageSourceName}
                                onChange={(e) => setHideBeverageSourceName(!Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        {menuType === 'dense' && (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              label={t('PageEditForm.ReverseBreweryAndName')}
                              control={
                                <Checkbox
                                  checked={reverseBreweryAndName}
                                  onChange={(e) => setReverseBreweryAndName(Boolean(e.target.checked))}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowServings')}
                            control={
                              <Checkbox
                                checked={showServings}
                                onChange={(e) => setShowServings(Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowServingSize')}
                            control={
                              <Checkbox
                                checked={showServingSize}
                                onChange={(e) => setShowServingSize(Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowServingCurrency')}
                            control={
                              <Checkbox
                                checked={showServingCurrency}
                                onChange={(e) => setShowServingCurrency(Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowStripes')}
                            control={
                              <Checkbox checked={showStripes} onChange={(e) => setShowStripes(Boolean(e.target.checked))} color="primary" />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowLocation')}
                            control={
                              <Checkbox
                                checked={showLocation}
                                onChange={(e) => setShowLocation(Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControlLabel
                            label={t('PageEditForm.ShowTapsDescription')}
                            control={
                              <Checkbox
                                checked={showTapsDescription}
                                onChange={(e) => setShowTapsDescription(Boolean(e.target.checked))}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        {premium && (
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              label={t('PageEditForm.ShowLogo')}
                              control={
                                <Checkbox checked={showLogo} onChange={(e) => setShowLogo(Boolean(e.target.checked))} color="primary" />
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h3" style={{ marginTop: 20 }}>
                            {t('PageEditForm.BackgroundImage')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FileUpload onFileSelected={handleBackgroundImageSelection} maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES} />
                        </Grid>

                        {(backgroundImageUrl || backgroundImagePreviewUrl) && (
                          <Grid item xs={12}>
                            <FormControlLabel
                              label={t('PageEditForm.AutoDarken')}
                              control={
                                <Checkbox
                                  checked={showBackgroundImageHelper}
                                  onChange={(e) => setShowBackgroundImageHelper(Boolean(e.target.checked))}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          {backgroundImagePreviewUrl && (
                            <img
                              className={clsx(
                                classes.backgroundImage,
                                showBackgroundImageHelper ? classes.backgroundImageDarken : undefined
                              )}
                              src={backgroundImagePreviewUrl}
                              alt="Preview"
                            />
                          )}
                          {!backgroundImagePreviewUrl && backgroundImageUrl && (
                            <img
                              className={clsx(
                                classes.backgroundImage,
                                showBackgroundImageHelper ? classes.backgroundImageDarken : undefined
                              )}
                              src={backgroundImageUrl}
                              alt="Custom"
                            />
                          )}
                          {(backgroundImageUrl || backgroundImagePreviewUrl) && (
                            <p>
                              <CustomLink onClick={removeBackgroundImage}>{t('PageEditForm.RemoveBackgroundImage')}</CustomLink>
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {styleCategory === 'Copy From...' && (
                    <Paper variant="outlined">
                      <List component="nav" aria-label="main mailbox folders">
                        {filter(screen.pages, (p) => p.key !== page.key).length === 0 && (
                          <Alert variant="standard" severity="info">
                            {t('PageEditForm.NoPages')}
                          </Alert>
                        )}
                        {screen.pages.length > 0 &&
                          filter(screen.pages, (p) => p.key !== page.key).map((p) => (
                            <ListItem key={p.key} button selected={stylePageKey === p.key} onClick={() => setStylePageKey(p.key)}>
                              <ListItemText primary={p.name} secondary={p.type === 'taps' ? 'Tap List' : 'Image'} />
                            </ListItem>
                          ))}
                      </List>
                    </Paper>
                  )}
                  {styleCategory === 'Select Theme' && (
                    <Grid container spacing={2}>
                      {Themes.map((t) => (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          key={t.name}
                          onClick={() => selectTheme(t)}
                          className={clsx(styleThemeName === t.name ? classes.themeSelected : undefined)}>
                          <ThemeTile theme={t} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>

          <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
            <Grid container direction="row">
              <Grid item xs={12}>
                {loading && <LinearProgress />}
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button variant="text" color="secondary" onClick={handleDelete} disabled={loading}>
                  {t('PageEditForm.Delete')}
                </Button>
                <Button variant="text" color="primary" onClick={handleSave} disabled={loading}>
                  {t('PageEditForm.Done')}
                </Button>
              </Grid>
            </Grid>
          </ActionBar>
        </>
      )}
    </>
  );
};
