import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  makeStyles,
  MenuItem,
  TableContainer,
  Paper,
  Snackbar,
  useMediaQuery,
  useTheme,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { ComponentType, ReactElement, useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { useMemberLocationList } from '../../hooks/useMemberLocationList';
import { Transition } from '../../components/Dialog/Transition';
import { TransitionProps } from '@material-ui/core/transitions';
import { SessionContext } from '../../contexts/session';
import { CREATE_LOCATION_MEMBER } from './mutations';
import { Alert } from '@material-ui/lab';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '0.9rem'
  },
  formContainer: {
    paddingTop: '10px'
  },
  actionsContainer: {
    padding: '12px'
  },
  locationsTitle: {
    marginTop: '32px',
    marginBottom: '0px'
  },
  tableContainer: {
    '@media (min-width: 960px)': {
      maxHeight: '400px'
    }
  }
}));

interface AddMemberDialogProps {
  open: any;
  onClose: () => void;
  onAdd: () => void;
}

const AddMemberDialog = ({ open, onClose, onAdd }: AddMemberDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('member');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const { locations, locRefetch } = useContext(SessionContext);
  const { MemberLocationListComponent, checkedLocations } = useMemberLocationList(locations, []);

  const [addLocationMember, { loading, error: graphqlError }] = useMutation(CREATE_LOCATION_MEMBER);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (graphqlError) {
      const error = t(graphqlError.graphQLErrors[0]?.message) || t('ManageLocationMembership.UnknownError');
      setErrorMessage(error);
    }
  }, [graphqlError, t]);

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };

  const validateFirstName = () => {
    setFirstNameError(firstName.length < 2);
  };

  const validateLastName = () => {
    setLastNameError(lastName.length < 2);
  };

  const validateEmail = () => {
    setEmailError(!/^[^@]+@[^@]+\.[^@]+$/.test(email));
  };

  const handleAddLocationMember = async () => {
    validateFirstName();
    validateLastName();
    validateEmail();
    try {
      await addLocationMember({
        variables: {
          firstName,
          lastName,
          email,
          role,
          locationIds: role === 'member' ? checkedLocations.asArray() : []
        }
      });

      await locRefetch();
      onAdd();
      cleanFields();
    } catch (e) {
      console.error(e, graphqlError);
    }
  };

  const handleClose = () => {
    onClose();
    cleanFields();
  };

  const cleanFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setRole('member');
    setFirstNameError(false);
    setLastNameError(false);
    setEmailError(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition as ComponentType<TransitionProps & { children?: ReactElement<any, any> | undefined }>}
        fullScreen={fullScreen}
        fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={2}>
              <ArrowBack onClick={handleClose} className={'cursor-pointer'} />
            </Grid>
            <Grid item xs={8} className={classes.pageHeading}>
              {t('Account.NewUser')}
            </Grid>
          </Grid>{' '}
        </DialogTitle>
        <DialogContent>
          <Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Account.FirstName')}
                  type="string"
                  variant="outlined"
                  value={firstName}
                  error={firstNameError}
                  onChange={(e) => setFirstName(e.target.value)}
                  onBlur={validateFirstName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Account.LastName')}
                  type="string"
                  variant="outlined"
                  value={lastName}
                  error={lastNameError}
                  onChange={(e) => setLastName(e.target.value)}
                  onBlur={validateLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Account.Email')}
                  type="email"
                  variant="outlined"
                  value={email}
                  error={emailError}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={validateEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  label={t('Account.Role')}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}>
                  <MenuItem value="member">{t('Account.Member')}</MenuItem>
                  <MenuItem value="owner">{t('Account.Owner')}</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {role === 'member' && (
              <>
                <Typography className={classes.locationsTitle} variant="h2">
                  {t('Account.LocationsTitle')}
                </Typography>
                <Grid item xs={12}>
                  <TableContainer className={classes.tableContainer} component={Paper}>
                    <MemberLocationListComponent />
                  </TableContainer>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionsContainer}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('Account.Cancel')}
          </Button>
          <Button
            onClick={handleAddLocationMember}
            variant="contained"
            color="primary"
            disabled={loading || firstNameError || lastNameError || emailError}>
            {t('Account.InviteUser')}
          </Button>
        </DialogActions>
      </Dialog>
      {errorMessage && (
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AddMemberDialog;
