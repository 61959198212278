import { t } from 'i18next';
import React from 'react';

type TapListType = 'active' | 'onDeck' | 'inactive';

interface TapListHeaderProps {
  title: string;
  type: TapListType;
  hasActiveTaps: boolean;
  hasOnDeckTaps: boolean;
}

const TapListHeader: React.FC<TapListHeaderProps> = ({ title, type, hasActiveTaps, hasOnDeckTaps }) => {
  const showServings =
    type === 'active' || (type === 'onDeck' && !hasActiveTaps) || (type === 'inactive' && !hasActiveTaps && !hasOnDeckTaps);

  return (
    <>
      <div className="hidden sm:flex py-6 px-6 border-b border-gray-200 bg-light-orange -mr-2 justify-center">
        <span className="visually-hidden"></span>
      </div>
      <div className="py-6 px-4 border-b border-gray-200 bg-light-orange -mx-2">
        <span className="text-[14px] text-dark-orange font-bold uppercase">{String(t(title))}</span>
      </div>
      <div className="hidden md:block py-6 px-4 border-b border-gray-200 bg-light-orange -mx-2">
        {showServings && <span className="text-[14px] text-dark-orange font-bold uppercase">{String(t('TapForm.Servings'))}</span>}
      </div>
      <div className="py-6 px-6 border-b border-gray-200 bg-light-orange -ml-2 flex justify-center">
        <span className="visually-hidden"></span>
      </div>
    </>
  );
};

export default TapListHeader;
