export const getTapLocation = (tap) => {
  const { location } = tap.beverage?.source || {};
  if (!location) {
    return '';
  }
  const locality = location?.locality ? location?.locality + ',' : '';
  let tapLocation = `${locality} ${location?.country}`;
  if (location?.region) {
    tapLocation = `${locality} ${location.region}`;
  }
  return tapLocation;
};

export const getFilteredTapList = (tapStart, tapEnd, listOfTaps) => {
  if (!listOfTaps) return [];
  const startIndex = tapStart ?? 1;
  const endIndex = tapEnd ?? listOfTaps.length;
  const filteredList = listOfTaps.filter((tap) => tap?.name >= startIndex && tap?.name <= endIndex);

  return filteredList.length > 0 ? filteredList : listOfTaps;
};
