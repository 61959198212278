import React from 'react';
import { makeStyles } from '@material-ui/core';

import { FontFamilyUnicaOne, SelectedBoxBackgroundColor } from '../../../theme';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tile: {
    height: 125,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '1.35rem',
    cursor: 'pointer',
    borderWidth: 2,
    borderColor: SelectedBoxBackgroundColor,
    borderStyle: 'solid'
  },
  backdrop: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20
  },
  backdropOn: {
    background: 'rgba(0,0,0,0.4)'
  },
  itemDescription: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemName: {
    fontWeight: 'bold',
    marginRight: 10
  },
  itemSource: {
    textTransform: 'uppercase',
    opacity: 0.8,
    fontFamily: FontFamilyUnicaOne,
    marginRight: 10
  },
  dense: {
    fontSize: '1rem',

    '& div div': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end'
    }
  }
}));

export const ThemeTile = ({ theme: { name, themeOptions } }) => {
  const classes = useStyles();

  const additionalClasses = [
    themeOptions.menuType === 'dense' ? classes.dense : undefined,
    themeOptions.textSize === 'condensed' ? classes.condensed : undefined
  ];

  let tileStyle = { backgroundColor: themeOptions.backgroundColor };
  if (themeOptions.backgroundImageUrl) {
    tileStyle = {
      ...tileStyle,
      backgroundImage: `url(${themeOptions.backgroundImageUrl})`,
      backgroundSize: 'cover'
    };
  }

  return (
    <div className={clsx(classes.tile, ...additionalClasses)} style={tileStyle}>
      <div className={clsx(classes.backdrop, themeOptions.showBackgroundImageHelper ? classes.backdropOn : undefined)}>
        <div className={classes.itemDescription}>
          <div className={classes.itemName} style={{ color: themeOptions.foregroundColor2 }}>
            {name}
          </div>
          {!themeOptions.hideBeverageSourceName && (
            <div className={classes.itemSource} style={{ color: themeOptions.foregroundColor }}>
              {name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
