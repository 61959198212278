import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

export const FeedbackBar = ({ message }) => {
  const { t } = useTranslation();
  const [hasSeenMessage, setHasSeenMessage] = useState(false);
  const [open, setOpen] = useState(false);

  if (message && !hasSeenMessage) {
    setOpen(true);
    setHasSeenMessage(true);
  }

  if (!message && hasSeenMessage) {
    setHasSeenMessage(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} style={{ bottom: 80 }}>
      <Alert variant="filled" onClose={handleClose} severity="error">
        {typeof message === 'string' ? message : t('FeedbackBar.DidntWork')}
      </Alert>
    </Snackbar>
  );
};
