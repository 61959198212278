import React, { useState } from 'react';
import { Grid, makeStyles, Button, Typography, Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import PourwalLogoAltImage from '../../images/pourwall-logo-alt.svg';
import ScreenImage from '../../images/screens.svg';
import TapsImage from '../../images/taps.png';
import DeviceImage from '../../images/device.svg';

import { ActionBar } from '../../components/ActionBar';

const useStyles = makeStyles((theme) => ({
  root: {},
  logoContainer: {
    '& img': {
      width: 130
    }
  },

  content: {
    [theme.breakpoints.up('md')]: {
      height: 500
    }
  },

  top: {
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 30px',

    '& img': {
      width: 'auto',
      height: 230
    },

    [theme.breakpoints.up('md')]: {
      height: 300,
      padding: 20
    }
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    height: 290,
    paddingBottom: 100,
    left: 0,
    right: 0,
    padding: '0 30px 100px 30px',
    textAlign: 'center',
    color: 'white',

    [theme.breakpoints.up('md')]: {
      height: 190
    }
  }
}));

export const Walkthrough = ({ onComplete }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep <= 2) {
      setCurrentStep(currentStep + 1);
    } else {
      console.log('finished');
      onComplete();
    }
  };

  return (
    <Grid container className={classes.root}>
      <Hidden mdUp>
        <Grid item xs={12} className={classes.logoContainer}>
          <img alt="POURWALL" src={PourwalLogoAltImage} />
        </Grid>
      </Hidden>
      <Grid item xs={12} className={classes.content}>
        {currentStep === 1 && <Step1 />}
        {currentStep === 2 && <Step2 />}
        {currentStep === 3 && <Step3 />}
      </Grid>
      <ActionBar dialog={1} style={{ zIndex: 1, background: 'transparent', boxShadow: 'none' }}>
        <Grid container>
          <Grid item xs={12} className={classes.progress}>
            <StepProgress stepCount={3} currentStep={currentStep} onSelectStep={(s) => setCurrentStep(s)} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button fullWidth variant="contained" color="primary" onClick={handleNext}>
              {currentStep < 3 ? t('Walkthrough.Next') : t('Walkthrough.Done')}
            </Button>
          </Grid>
        </Grid>
      </ActionBar>
    </Grid>
  );
};

const useStepProgressStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,

    '& span': {
      width: 10,
      height: 10,
      borderRadius: 10,
      backgroundColor: 'white',
      display: 'inline-block',
      margin: 5,

      '&.active': {
        backgroundColor: theme.palette.primary.main,
        width: 25
      }
    }
  }
}));

const StepProgress = ({ onSelectStep, stepCount, currentStep }) => {
  const classes = useStepProgressStyles();

  return (
    <div className={classes.root}>
      {[...Array(stepCount).keys()].map((i) => (
        <span key={i} className={clsx({ active: i + 1 === currentStep })} onClick={() => onSelectStep(i + 1)} />
      ))}
    </div>
  );
};

const Step1 = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.top}>
        <img alt="Step 1" src={TapsImage} />
      </div>
      <div className={classes.bottom}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">{t('Walkthrough.CreateYourTaps')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <p>{t('Walkthrough.LetUs')}</p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const Step2 = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.top}>
        <img alt="Step 2" src={ScreenImage} />
      </div>
      <div className={classes.bottom}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">{t('Walkthrough.ConfigureScreens')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <p>{t('Walkthrough.CreateAScreen')}</p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const Step3 = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.top}>
        <img alt="Step 3" src={DeviceImage} style={{ width: 200 }} />
      </div>
      <div className={classes.bottom}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">{t('Walkthrough.LinkDevice')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <p>{t('Walkthrough.PlugFireTv')}</p>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
