import React, { ChangeEvent } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { CLASSIC_HEADER_TYPE, VERTICAL_HEADER_TYPE } from './enums';
import { useTranslation } from 'react-i18next';

interface BaseProps {
  onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
}

interface ClassicHeaderSelectProps extends BaseProps {
  value: CLASSIC_HEADER_TYPE;
}

interface VerticalHeaderSelectProps extends BaseProps {
  value: VERTICAL_HEADER_TYPE;
}

export const ClassicHeaderSelect: React.FC<ClassicHeaderSelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <Select labelId="header-type" value={value} onChange={onChange} label={t('PrintDialog.HeaderType')}>
      <MenuItem value={CLASSIC_HEADER_TYPE.LEFT_ALIGN}>{t('PrintDialog.LeftAlign')}</MenuItem>
      <MenuItem value={CLASSIC_HEADER_TYPE.CENTER_ALIGN}>{t('PrintDialog.CenterAlign')}</MenuItem>
      <MenuItem value={CLASSIC_HEADER_TYPE.LOGO}>{t('PrintDialog.TopLogoOnly')}</MenuItem>
    </Select>
  );
};

export const VerticalHeaderSelect: React.FC<VerticalHeaderSelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <Select labelId="header-type" value={value} onChange={onChange} label={t('PrintDialog.HeaderType')}>
      <MenuItem value={VERTICAL_HEADER_TYPE.PRIMARY}>{t('PrintDialog.Primary')}</MenuItem>
      <MenuItem value={VERTICAL_HEADER_TYPE.SECONDARY}>{t('PrintDialog.Secondary')}</MenuItem>
      <MenuItem value={VERTICAL_HEADER_TYPE.SECONDARY_ALTERNATE_ONE}>{t('PrintDialog.SecondaryAltOne')}</MenuItem>
      <MenuItem value={VERTICAL_HEADER_TYPE.SECONDARY_ALTERNATE_TWO}>{t('PrintDialog.SecondaryAltTwo')}</MenuItem>
      <MenuItem value={VERTICAL_HEADER_TYPE.LOGO}>{t('PrintDialog.TopLogoOnly')}</MenuItem>
    </Select>
  );
};
