import currenciesArray from './currencies.json';
export { default as CURRENCIES_ARRAY } from './currencies.json';

export const CURRENCIES = currenciesArray.reduce((currencies, currency) => {
  currencies[currency.id] = currency;
  return currencies;
}, {});

export const CURRENCIES_IDS = currenciesArray.map(({ id }) => id);

export const CURRENCIES_IDS_MAP = currenciesArray.reduce((currencies, currency) => {
  currencies[currency.id] = currency.id;
  return currencies;
}, {});

export const DEFAULT_CURRENCY = CURRENCIES_IDS_MAP.USD;

export function getCurrencySign(currency) {
  return CURRENCIES[currency || DEFAULT_CURRENCY].symbol;
}
