import React, { useState, useContext } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { useMutation, useQuery } from 'react-apollo';
import { ArrowBack, Edit } from '@material-ui/icons';
import { find, without, sortBy } from 'lodash';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../contexts/session';

import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../util/lang';
import { LightGrey } from '../../theme';

import { ActionBar } from '../../components/ActionBar';
import { FeedbackBar } from '../../components/FeedbackBar';
import { NewBeverageTypeSelection } from './beverages/NewBeverageSelection';
import { EditServingDialog } from './servings/EditServingDialog';

import { CREATE_TAP, UPDATE_TAP, DELETE_TAP, SIGN_FILE_FOR_UPLOAD } from './mutations';
import { cleanCachedObjectForMutation, spreadMutations, getMessageFromError } from '../../util/graphql';
import FileUpload from '../../components/FileUpload';
import { checkFileSize, MAX_TAP_IMAGE_SIZE_BYTES, uploadToAws } from '../../util/files';
import { GET_BEVERAGE_CATEGORIES } from './queries';
import { UserVesselsContext } from '../../contexts/userVessels';
import { CopyServingsDialog } from './servings/CopyServingsDialog';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingTop: '10px',
    paddingInline: '24px'
  },
  servingsContainer: {
    paddingTop: '10px',
    paddingInline: '24px',
    marginBottom: 100
  },
  saveButton: {
    marginLeft: 20,
    flexGrow: 1
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  itemImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'inline-block',
    marginRight: 10
  },
  menuBorder: {
    border: `1px solid ${LightGrey}`
  }
}));

export const TapEditForm = ({ tap, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, currentLocation } = useContext(SessionContext);
  const { userVessels, createUserVessel } = useContext(UserVesselsContext);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(tap.name);
  const [type, setType] = useState(tap.beverage?.type);
  const [list] = useState(tap.list || 'Active');

  // beverage fields
  const [beverageName, setBeverageName] = useState(tap.beverage?.name || '');
  const [beverageStyle, setBeverageStyle] = useState(tap.beverage?.style || '');
  const [beverageStatus, setBeverageStatus] = useState(tap.status || 'ACTIVE');
  const [beverageCategory, setBeverageCategory] = useState(tap.beverage?.category || '');
  const [beverageAbv, setBeverageAbv] = useState(tap.beverage?.abv);
  const [beverageIBU, setBeverageIBU] = useState(tap.beverage?.ibu);
  const [beverageDescription, setBeverageDescription] = useState(tap.beverage?.description || '');
  const [beverageRateBeerRating, setBeverageRateBeerRating] = useState(tap.beverage?.rateBeerRating);

  const [beverageSourceName, setBeverageSourceName] = useState(tap.beverage?.source?.name || '');
  const [beverageSourceLocality, setBeverageSourceLocality] = useState(tap.beverage?.source?.location?.locality || '');
  const [beverageSourceRegion, setBeverageSourceRegion] = useState(tap.beverage?.source?.location?.region || '');
  const [beverageSourceCountry, setBeverageSourceCountry] = useState(tap.beverage?.source?.location?.country || '');
  const [beverageImages, setBeverageImages] = useState(tap.beverage?.images || []);

  const [beverageImagePreviewUrl, setBeverageImagePreviewUrl] = useState();
  const [beverageImageFile, setBeverageImageFile] = useState();
  const [beverageImageFileSignedUploadUrl, setBeverageImageFileSignedUploadUrl] = useState();

  const [servings, setServings] = useState(tap.servings || []);
  const [editServing, setEditServing] = useState(null);
  const [copySergingsDialogOpen, setCopyServingsDialogOpen] = useState(false);
  const [showABV, setShowABV] = useState(tap.showABV !== false);
  const [showIBU, setShowIBU] = useState(tap.showIBU === true);

  const [[createTap, saveTap, deleteTap, signFile], { loading: mutationLoading }] = spreadMutations([
    useMutation(CREATE_TAP),
    useMutation(UPDATE_TAP),
    useMutation(DELETE_TAP),
    useMutation(SIGN_FILE_FOR_UPLOAD)
  ]);

  const { data: beverageCategoryData } = useQuery(GET_BEVERAGE_CATEGORIES);
  const beverageCategories = sortBy(beverageCategoryData?.categories || [], (c) => c.name);

  const handleSelectBeverage = (beverage) => {
    setType(beverage.type);
    setBeverageName(beverage.name);
    setBeverageStyle(beverage.style);
    setBeverageCategory(beverage.category);
    setBeverageAbv(beverage.abv);
    setBeverageIBU(beverage.ibu);
    setBeverageDescription(beverage.description);
    setBeverageRateBeerRating(beverage.rateBeerRating);
    setBeverageSourceName(beverage.source?.name);
    setBeverageSourceLocality(beverage.source?.location?.locality);
    setBeverageSourceRegion(beverage.source?.location?.region);
    setBeverageSourceCountry(beverage.source?.location?.country);
    setBeverageImages(beverage.images || []);
    setShowABV(beverage.showABV !== false);
    setShowIBU(beverage.showIBU === true);
  };

  const handleBeverageImageSelection = async (file, previewUrl) => {
    //The line below is a workaround to show the error messages everytime a file is chosen.
    setErrorMessage('');
    const fileSizeError = checkFileSize(file, MAX_TAP_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setErrorMessage(fileSizeError.message);
    } else {
      setBeverageImagePreviewUrl(previewUrl);
      const variables = {
        locationName: currentLocation?.shortName,
        fileName: file.name,
        fileType: file.type
      };
      const { data, error } = await signFile({ variables });
      if (error) {
        setErrorMessage(error);
      }
      if (data?.signFile) {
        setBeverageImageFile(file);
        setBeverageImageFileSignedUploadUrl(data.signFile.signedUrl);

        let images = [...beverageImages];
        let match = find(images, (i) => i.size === 'medium');
        if (!match) {
          match = { size: 'medium' };
          images.push(match);
        }
        match.url = data.signFile.resourceUrl;
        setBeverageImages(images);
      }
    }
  };

  const handleAddServing = () => {
    let serving = { key: shortid.generate() };
    setEditServing(serving);
  };

  const handleSaveServing = (serving) => {
    if (serving.isNew) {
      createUserVessel({
        name: serving.vessel,
        size: serving.size
      });
    }
    delete serving.isNew;
    let allServings = [...servings];

    let matchedServing = find(allServings, (p) => p.key === serving.key);

    if (!matchedServing) allServings.push(serving);
    else Object.assign(matchedServing, serving);

    setServings(allServings);
    setEditServing(null);
  };

  const handleCopyServings = (copied) => {
    const copiedServings = copied.map((s) => {
      if (s.isNew) {
        createUserVessel({
          name: s.vessel,
          size: s.size
        });
      }
      delete s.isNew;
      return s;
    });
    setServings(copiedServings);
  };

  const handleDeleteServing = () => {
    if (editServing && editServing.key) {
      let allServings = [...servings];
      let matchedServing = find(allServings, (p) => p.key === editServing.key);
      if (matchedServing) {
        allServings = without(allServings, matchedServing);
        setServings(allServings);
      }
    }
    setEditServing(null);
  };

  const handleDelete = async () => {
    await deleteTap({ variables: { id: tap._id } });
    onClose();
  };

  const handleSave = async () => {
    if (beverageImageFile && beverageImageFileSignedUploadUrl) {
      setLoading(true);
      try {
        await uploadToAws(beverageImageFileSignedUploadUrl, beverageImageFile);
      } catch (e) {
        setErrorMessage(e);
        setLoading(false);
      }
    }

    let location = {
      locality: beverageSourceLocality,
      region: beverageSourceRegion,
      country: beverageSourceCountry
    };
    let beverage = Object.assign(
      { ...tap.beverage },
      {
        name: beverageName,
        type,
        style: beverageStyle,
        category: beverageCategory,
        abv: Number(beverageAbv),
        ibu: Number(beverageIBU),
        description: beverageDescription,
        rateBeerRating: beverageRateBeerRating,
        source: { name: beverageSourceName, location },
        images: beverageImages
      }
    );
    let cleanTap = cleanCachedObjectForMutation({
      ...tap,
      status: beverageStatus,
      name,
      list,
      beverage,
      showABV,
      showIBU,
      servings
    });

    cleanTap = Object.assign({ createdBy: user._id, location: currentLocation?._id }, cleanTap);

    try {
      if (cleanTap._id) {
        await saveTap({ variables: { tap: cleanTap } });
      } else {
        await createTap({ variables: { tap: cleanTap } });
      }
      onClose();
    } catch (e) {
      setErrorMessage(getMessageFromError(e));
      console.log(e);
    }
  };

  let typeName = type === 'Other' ? 'Beverage' : type;
  let sourceType = type === 'Beer' ? 'Brewery' : type === 'Wine' ? 'Winery' : type === 'Cider' ? 'Cidery' : 'Maker';

  let imageUrl = find(beverageImages, (i) => i.size === 'medium')?.url;

  return (
    <div>
      <FeedbackBar message={errorMessage} />
      <Grid container className="px-4 pt-1.5 pb-3">
        <Grid item>
          <ArrowBack className="cursor-pointer" onClick={() => onClose()} />
        </Grid>
      </Grid>

      {!type && <NewBeverageTypeSelection onSelect={handleSelectBeverage} />}

      {type && (
        <>
          <Grid container className={classes.formContainer} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.sectionHeading}>
                {t('TapForm.Basics')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.TapNo')}
                type="number"
                value={name}
                onChange={(e) => setName(Number(e.target.value))}
              />
            </Grid>
            <Grid item xs={8} container alignItems="center">
              {beverageImagePreviewUrl && (
                <div className={classes.itemImage} style={{ backgroundImage: `url(${beverageImagePreviewUrl})` }}></div>
              )}
              {!beverageImagePreviewUrl && imageUrl && (
                <div className={classes.itemImage} style={{ backgroundImage: `url("${imageUrl}")` }}></div>
              )}
              <FileUpload onFileSelected={handleBeverageImageSelection} maxFileSize={MAX_TAP_IMAGE_SIZE_BYTES} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageName', {
                  typeName
                })}
                value={beverageName}
                onChange={(e) => setBeverageName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.ABV')}
                type="number"
                inputProps={{ step: 0.1, inputMode: 'decimal' }}
                value={!showABV ? '' : beverageAbv}
                disabled={!showABV}
                onChange={(e) => setBeverageAbv(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl>
                <div className="text-xs text-gray-600 mt-[-6px]">{t('TapForm.DisplayABV')}</div>
                <RadioGroup row value={showABV.toString()} onChange={(e) => setShowABV(e.target.value === 'true')}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.IBU')}
                type="number"
                inputProps={{ step: 0.1, inputMode: 'decimal' }}
                value={!showIBU ? '' : beverageIBU}
                disabled={!showIBU}
                onChange={(e) => setBeverageIBU(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControl>
                <div className="text-xs text-gray-600 mt-[-6px]">{t('TapForm.DisplayIBU')}</div>
                <RadioGroup row value={showIBU.toString()} onChange={(e) => setShowIBU(e.target.value === 'true')}>
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageStyle', {
                  typeName
                })}
                value={beverageStyle}
                onChange={(e) => setBeverageStyle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                freeSolo
                value={beverageCategory}
                options={beverageCategories.map((option) => option.name)}
                onChange={(e, value) => setBeverageCategory(value)}
                classes={{ paper: classes.menuBorder }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={t('TapForm.BeverageCategory', {
                      typeName
                    })}
                    margin="none"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageStatus')}
                value={beverageStatus}
                onChange={(e) => setBeverageStatus(e.target.value)}>
                <MenuItem value="ACTIVE">{t('TapForm.BeverageActive')}</MenuItem>
                <MenuItem value="ON_DECK">{t('TapForm.BeverageOnDeck')}</MenuItem>
                <MenuItem value="INACTIVE">{t('TapForm.BeverageInactive')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                label={t('TapForm.BeverageDescription', {
                  typeName
                })}
                value={beverageDescription}
                onChange={(e) => setBeverageDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageSource', {
                  sourceType
                })}
                value={beverageSourceName}
                onChange={(e) => setBeverageSourceName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageCity', {
                  sourceType
                })}
                value={beverageSourceLocality}
                onChange={(e) => setBeverageSourceLocality(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageState', {
                  sourceType
                })}
                value={beverageSourceRegion}
                onChange={(e) => setBeverageSourceRegion(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('TapForm.BeverageCountry', {
                  sourceType
                })}
                value={beverageSourceCountry}
                onChange={(e) => setBeverageSourceCountry(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.servingsContainer} spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h2" className={classes.sectionHeading}>
                {t('TapForm.Servings')}
              </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end" alignItems="center" spacing={1}>
              <Grid item>
                <Button variant="outlined" color="blue" onClick={() => setCopyServingsDialogOpen(true)}>
                  {t('TapForm.CopyFromExisting')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleAddServing}>
                  {t('TapForm.AddServing')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{t('TapForm.ServingType')}</TableCell>
                      <TableCell align="right">{t('TapForm.Size')}</TableCell>
                      <TableCell align="right">{t('TapForm.Price')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servings.map((s, i) => (
                      <TableRow key={i} onClick={() => setEditServing(s)}>
                        <TableCell component="th" scope="row">
                          <Edit />
                        </TableCell>
                        <TableCell>{resolveVesselName(s.vessel, userVessels)}</TableCell>
                        <TableCell align="right">{getServingSizeLabel(s)}</TableCell>
                        <TableCell align="right">{getServingPriceLabel(s, true)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <EditServingDialog
            serving={editServing}
            onCancel={() => setEditServing(null)}
            onDelete={handleDeleteServing}
            onSave={(s) => handleSaveServing(s)}
          />
          <CopyServingsDialog
            currentTap={tap}
            open={copySergingsDialogOpen}
            onCopyServings={handleCopyServings}
            onClose={() => setCopyServingsDialogOpen(false)}
          />
          <ActionBar loading={loading || mutationLoading}>
            <Button variant="contained" color="secondary" disabled={!tap._id} onClick={handleDelete}>
              {t('TapForm.Delete')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || mutationLoading}
              className={classes.saveButton}
              onClick={handleSave}>
              {t('TapForm.Save')}
            </Button>
          </ActionBar>
        </>
      )}
    </div>
  );
};
