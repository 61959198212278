import React, { useState } from 'react';
import { Grid, LinearProgress, List, ListItem, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from 'react-apollo';
import { debounce, find } from 'lodash';
import clsx from 'clsx';

import { SEARCH_BEERS } from '../queries';

import RateBeerLogo from '../../../images/logo-ratebeer.png';
import CustomLink from '../../../components/CustomLink';

const useStyles = makeStyles((theme) => ({
  row: {
    textAlign: 'center'
  },
  searchContainer: {
    paddingTop: 10
  },
  poweredBy: {
    width: 140,
    marginTop: 50
  },
  searchResultContainer: {
    borderBottom: '1px solid #D3D3D3',
    padding: 10,
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  itemName: {
    fontWeight: '800',
    fontSize: '14px',
    lineHeight: '1.45'
  },
  itemSource: {
    fontSize: '14px',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase'
  },
  itemStyle: {
    fontSize: '14px',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    opacity: '0.7'
  },
  itemImage: {
    height: 50,
    width: 50,
    borderRadius: 50,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: 30
  }
}));

const debouncedSearch = debounce((func) => {
  func();
}, 500);

export const BeerSearch = ({ onSelect, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [lastSearchText, setLastSearchText] = useState('');

  const [searchBeersQuery, { loading, data }] = useLazyQuery(SEARCH_BEERS, {
    variables: { searchText, type: 'Beer' }
  });

  if (searchText && searchText !== lastSearchText) {
    debouncedSearch(() => {
      searchBeersQuery();
      setLastSearchText(searchText);
    });
  }

  const results = data?.search || [];

  const addCustomBeverage = () => {
    onSelect({
      type: 'Beer'
    });
  };

  return (
    <Grid container className>
      <Grid item xs={12} className={clsx(classes.row, classes.searchContainer)}>
        <Typography variant="h2">{t('BeerSearch.SelectBeverage')}</Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t('BeerSearch.Search')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        {loading && <LinearProgress color="primary" style={{ marginTop: 10 }} />}
        {!loading && (
          <List>
            {results.map((item, i) => (
              <SearchResult item={item} key={i} onClick={() => onSelect(item)} />
            ))}
          </List>
        )}
      </Grid>
      <Grid item xs={12} className={clsx(classes.row, classes.searchContainer)}>
        {t('BeerSearch.CantFind')} <CustomLink onClick={addCustomBeverage}>{t('BeerSearch.AddYourOwn')}</CustomLink>.
      </Grid>
      <div className={clsx(classes.row, 'w-full flex flex-col items-center')}>
        <img alt="Powered by RateBeer" src={RateBeerLogo} className={classes.poweredBy} />
      </div>
    </Grid>
  );
};

const SearchResult = ({ item, onClick }) => {
  const classes = useStyles();
  const img = find(item.images, (i) => i.size === 'medium');

  return (
    <ListItem button onClick={onClick} className={classes.searchResultContainer}>
      <Grid container>
        <Grid item xs={3} md={2} lg={1} container justifyContent="flex-end">
          {img && <div style={{ backgroundImage: `url(${img.url})` }} className={classes.itemImage}></div>}
        </Grid>
        <Grid item xs={9} md={10} lg={11}>
          <div className={classes.itemName}>{item.name}</div>
          <div className={classes.itemSource}>{item.source?.name}</div>
          <div className={classes.itemSource}>
            {item.source?.location?.locality}, {item.source?.location?.region} {item.source?.location?.country}
          </div>
          <div className={classes.itemStyle}>
            {item.style} - ABV {item.abv || 'N/A'}% {item.ibu ? `- IBUs ${item.ibu}` : ''}
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
};
