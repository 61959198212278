import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import clsx from 'clsx';
import { MenuPrintConfig } from './types';
import { getTapLocation } from '../../../util/tap';
import { UserVesselsContext } from '../../../contexts/userVessels';
import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../../util/lang';

const Servings: React.FC<{ tap: any; config: MenuPrintConfig }> = ({ tap, config: { theme, showServingSize, showServingCurrency } }) => {
  const classes = useStyles();
  const vesselsContext = useContext(UserVesselsContext) as any;

  return (
    <div className={classes.servingsContainer}>
      {tap.servings?.map((serving: any) => (
        <div key={serving._id} className={classes.servingContainer}>
          <p className={classes.servingName} style={{ color: theme.secondaryText }}>
            {resolveVesselName(serving.vessel, vesselsContext?.userVessels ?? []).toUpperCase()}{' '}
            {showServingSize ? `(${getServingSizeLabel(serving)})` : ''}
          </p>
          <p className={classes.servingPrice} style={{ color: theme.primaryText }}>
            {getServingPriceLabel(serving, showServingCurrency)}
          </p>
        </div>
      ))}
    </div>
  );
};

const ClassicTap: React.FC<{ tap: any; config: MenuPrintConfig }> = ({ tap, config }) => {
  const classes = useStyles();
  const { showDescription, showTapLocation, showHandle, showBreweryName, columnCount, showServings, showBeerStyle, theme } = config;
  const tapLocation = getTapLocation(tap);
  const breweryName = tap.beverage?.source?.name?.split('(')[0];
  const showServingsComponent = showServings && !!tap.servings?.length;
  return (
    <div className={classes.container}>
      {showBeerStyle && (
        <div className={clsx(classes.row, classes.beerStyle)}>
          {showHandle && <div className={classes.indexWrapper} />}
          <p style={{ display: 'block' }}>{tap.beverage?.style}</p>
        </div>
      )}
      <div className={classes.row}>
        {showHandle && (
          <div className={classes.indexWrapper}>
            <p style={{ color: theme.primary }}>{tap.name}</p>
          </div>
        )}
        <div className={classes.contentContainer}>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <div className={classes.titleContainer}>
                <p className={classes.tapName} style={{ color: theme.primaryText }}>
                  {tap.beverage?.name}
                </p>
                {tap.showABV && (
                  <>
                    <div className={classes.titleSeparator} style={{ backgroundColor: theme.primary }} />
                    <p className={classes.abv} style={{ color: theme.primaryText }}>
                      {tap.beverage?.abv}%
                    </p>
                  </>
                )}
                {tap.showIBU && (
                  <>
                    <div className={classes.titleSeparator} style={{ backgroundColor: theme.primary }} />
                    <p className={classes.abv} style={{ color: theme.primaryText }}>
                      {tap.beverage?.ibu} IBUs
                    </p>
                  </>
                )}
              </div>
              {columnCount === 1 && showServingsComponent && <Servings tap={tap} config={config} />}
            </div>
          </div>
          <div className={classes.breweryContainer}>
            {showBreweryName && (
              <p className={classes.breweryName} style={{ color: theme.primaryText }}>
                {breweryName}
              </p>
            )}
            {showTapLocation && (
              <p className={classes.breweryLocation} style={{ color: theme.secondaryText }}>
                {tapLocation}
              </p>
            )}
          </div>
          {columnCount !== 1 && showServingsComponent && <Servings tap={tap} config={config} />}
          {showDescription && (
            <p className={classes.description} style={{ color: theme.primaryText }}>
              {tap.beverage?.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 8px 12px 8px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  indexWrapper: {
    minWidth: 12,
    marginTop: 3,
    marginRight: 8,
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 'bold',
    color: '#FD5C36'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline'
  },
  beerStyle: {
    fontSize: 7,
    fontWeight: 700,
    lineHeight: '8.17px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tapName: {
    fontWeight: 600,
    lineHeight: '17px',
    fontFamily: 'Bitter'
  },
  titleSeparator: {
    marginLeft: 8,
    marginRight: 8,
    color: '#FD5C36',
    width: 1,
    height: 10
  },
  abv: {
    fontSize: 12,
    textAlign: 'end',
    lineHeight: '17px'
  },
  breweryContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    flexWrap: 'wrap'
  },
  breweryName: {
    marginRight: 5,
    fontSize: 10
  },
  breweryLocation: {
    fontSize: 8,
    fontStyle: 'italic'
  },
  servingsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    marginBottom: 4
  },
  servingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginRight: 8
  },
  servingName: {
    fontSize: 6,
    fontWeight: 600,
    marginRight: 4
  },
  servingPrice: {
    fontSize: 10,
    fontWeight: 600
  },
  description: {
    fontSize: 7,
    marginTop: 5
  },
  beverageStyle: {
    fontSize: 6,
    fontWeight: 600,
    lineHeight: '8px',
    fontFamily: 'Open Sans'
  }
}));

export default ClassicTap;
