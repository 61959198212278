import gql from 'graphql-tag';
import { USER_FIELDS } from '../../data/graphql';

export const LOGIN = gql`
    query Login($email: String!, $password: String!){
        login(email:$email, password:$password) {
            token,
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

export const GET_USER = gql`
    query GetUser($id: MongoID!){
        user: userById(_id:$id) {
            ${USER_FIELDS}
        }
    }
`;
