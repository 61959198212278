import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  Slide,
  MenuItem,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { LightGrey, MediumGrey, Orange } from '../../../theme';

import { ActionBar } from '../../../components/ActionBar';
import { ColorPicker } from '../../../components/ColorPicker';
import FileUpload from '../../../components/FileUpload';
import { MAX_SCREEN_IMAGE_SIZE_BYTES, checkFileSize } from '../../../util/files';
import CustomLink from '../../../components/CustomLink';
import { FeedbackBar } from '../../../components/FeedbackBar';
import useLocationStorage from './useLocationStorage';

import { CLASSIC_HEADER_TYPE, MENU_TYPE, VERTICAL_HEADER_TYPE } from './enums';
import { SessionContext } from '../../../contexts/session';
import { ClassicHeaderSelect, VerticalHeaderSelect } from './HeaderTypeSelect';
import { defaultMenuColors, useMenuColorsStorage } from './useColorsStorage';
import { groupTaps } from './utils';
import { useQuery } from 'react-apollo';
import { GET_TAPS_BY_STATUS } from '../queries';
import { getFilteredTapList } from '../../../util/tap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIcon from '../../../images/drag.png';
import ChevronUp from '../../../images/chevron-up.png';
import ChevronDown from '../../../images/chevron-down.png';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    borderTop: `4px solid ${Orange}`
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  sectionHeading: {
    marginTop: '20px'
  },
  menuBorder: {
    border: `1px solid ${LightGrey}`
  }
}));

const getItemStyle = (_, draggableStyle) => ({
  userSelect: 'none',
  padding: 16,
  margin: `0 0 16px 0`,
  border: '1px solid #ccc',
  background: 'white',
  'border-radius': '8px',
  ...draggableStyle
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PrintDialog = withRouter(({ open, tapsCount, onCancel }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const handlePrint = (settings) => {
    let urlParameters = Object.entries(settings)
      .map((e) => {
        e[1] = encodeURIComponent(e[1]);
        return e.join('=');
      })
      .join('&');
    window.open(`/taps/print?${urlParameters}`);
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted fullWidth fullScreen={fullScreen} onClose={onCancel}>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={2}>
            <ArrowBack onClick={onCancel} />
          </Grid>
          <Grid item xs={8} className={classes.pageHeading}>
            {t('PrintDialog.PrintTapMenu')}
          </Grid>
        </Grid>
        <PrintOptions tapsCount={tapsCount} onCancel={onCancel} onPrint={handlePrint} />
      </DialogContent>
    </Dialog>
  );
});

const PrintOptions = ({ tapsCount, onPrint, onCancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentLocation } = useContext(SessionContext);
  const [menuType, setMenuType] = useLocationStorage('menuType', MENU_TYPE.CLASSIC);
  const [groupBy, setGroupBy] = useLocationStorage('printGroupBy', 'none');
  const [title, setTitle] = useLocationStorage('printTitle', `${currentLocation?.name} Tap List`);
  const [showDate, setShowDate] = useLocationStorage('printShowDate', false);
  const [showDescription, setShowDescription] = useLocationStorage('printShowDescription', false);
  const [columns, setColumns] = useLocationStorage('printColumns', 2);
  const [showHandle, setShowHandle] = useLocationStorage('printShowHandle', true);
  const [showBreweryName, setShowBreweryName] = useLocationStorage('printShowBreweryName', true);
  const [showServings, setShowServings] = useLocationStorage('printShowServings', true);
  const [showServingSize, setShowServingSize] = useLocationStorage('printShowServingSize', false);
  const [showServingCurrency, setShowServingCurrency] = useLocationStorage('printShowServingCurrency', false);
  const [showBeerStyle, setShowBeerStyle] = useLocationStorage('printShowBeerStyle', false);
  const [showLocation, setShowLocation] = useLocationStorage('printShowLocation', false);
  const [showLogo, setShowLogo] = useLocationStorage('printShowLogo', false);
  const [sortBy, setSortBy] = useLocationStorage('printSortBy', 'handle');
  const [tapStart, setTapStart] = useLocationStorage('printTapStart', 1);
  const [tapEnd, setTapEnd] = useLocationStorage('printTapEnd', 0);
  const [primaryColor, setPrimaryColor] = useMenuColorsStorage('primaryColor', menuType, defaultMenuColors[menuType].primary);
  const [secondaryColor, setSecondaryColor] = useMenuColorsStorage('secondaryColor', menuType, defaultMenuColors[menuType].secondary);
  const [primaryTextColor, setPrimaryTextColor] = useMenuColorsStorage(
    'primaryTextColor',
    menuType,
    defaultMenuColors[menuType].primaryText
  );
  const [secondaryTextColor, setSecondaryTextColor] = useMenuColorsStorage(
    'secondaryTextColor',
    menuType,
    defaultMenuColors[menuType].secondaryText
  );
  const [classicHeaderType, setClassicHeaderType] = useLocationStorage('classicHeaderType', CLASSIC_HEADER_TYPE.LEFT_ALIGN);
  const [verticalHeaderType, setVerticalHeaderType] = useLocationStorage('verticalHeaderType', VERTICAL_HEADER_TYPE.PRIMARY);
  const [dashColor, setDashColor] = useMenuColorsStorage('dashColor', menuType, defaultMenuColors[menuType].dash);
  const [footerDate, setFooterDate] = useLocationStorage('footerDate', '');
  const [footerLocation, setFooterLocation] = useLocationStorage('footerLocation', '');
  const [footerWebsite, setFooterWebsite] = useLocationStorage('footerWebsite', '');

  const [headerImageUrl, setHeaderImageUrl] = useState();
  const [headerImagePreviewUrl, setHeaderImagePreviewUrl] = useState();
  const [headerImageFile, setHeaderImageFile] = useState();
  const [footerImageUrl, setFooterImageUrl] = useState();
  const [footerImagePreviewUrl, setFooterImagePreviewUrl] = useState();
  const [footerImageFile, setFooterImageFile] = useState();

  const isGrouped = groupBy !== 'none';
  const [groupOrder, setGroupOrder] = useState([]);
  const [sortGroupsExpanded, setSortGroupsExpanded] = useState(false);

  const [error, setError] = useState(null);

  const isCustomMenu = menuType !== MENU_TYPE.DEFAULT;
  const isClassicMenu = menuType === MENU_TYPE.CLASSIC;
  const isVerticalMenu = menuType === MENU_TYPE.VERTICAL;
  const headerTypeIsLogo =
    (isClassicMenu && classicHeaderType === CLASSIC_HEADER_TYPE.LOGO) ||
    (isVerticalMenu && verticalHeaderType === VERTICAL_HEADER_TYPE.LOGO);

  useEffect(() => {
    if (tapEnd === 0) {
      setTapEnd(tapsCount);
    }
  }, [setTapEnd, tapEnd, tapsCount]);

  const handlePrint = async () => {
    const printParams = {
      menuType,
      groupBy,
      title,
      showDate,
      showDescription,
      showLocation,
      showLogo,
      columns,
      showHandle,
      sortBy,
      showBreweryName,
      showServings,
      showServingSize,
      showServingCurrency,
      showBeerStyle,
      tapStart,
      tapEnd,
      primaryColor,
      secondaryColor,
      dashColor,
      primaryTextColor,
      secondaryTextColor,
      footerDate,
      footerLocation,
      footerWebsite,
      groupsOrder: groupOrder.map((group) => group.index)
    };

    if (isCustomMenu) {
      if (isClassicMenu) {
        printParams.headerType = classicHeaderType;
      } else if (isVerticalMenu) {
        printParams.headerType = verticalHeaderType;
      }

      if (headerImageFile && headerImageUrl) {
        printParams.headerImageUrl = headerImageUrl;
      }

      if (footerImageFile && footerImageUrl) {
        printParams.footerImageUrl = footerImageUrl;
      }
    }

    onPrint(printParams);
  };

  const { data: tapsData } = useQuery(GET_TAPS_BY_STATUS, {
    variables: { location: currentLocation?._id, statuses: ['ACTIVE'] }, // TODO: Get taps on deck?
    fetchPolicy: 'cache-and-network'
  });

  const resetGroupOrder = () => {
    if (!tapsData) return;
    const tapList = getFilteredTapList(tapStart, tapEnd, tapsData.taps);

    if (isGrouped) {
      const defaultGroup = t('PrintedMenu.Other');
      const groups = Object.entries(groupTaps(tapList, groupBy, defaultGroup)).map(([groupName, taps], index) => ({
        id: taps[0]._id,
        groupName,
        taps,
        index
      }));
      setGroupOrder(groups);
    } else {
      setGroupOrder([]);
    }
  };

  useEffect(() => {
    resetGroupOrder();
    if (!isGrouped) {
      setSortGroupsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tapsData, groupBy, tapStart, tapEnd]);

  const handleHeaderImageSelection = (file, previewUrl) => {
    setError('');
    const fileSizeError = checkFileSize(file, MAX_SCREEN_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setError(fileSizeError.message);
    } else {
      var blob = new Blob([file], { type: file.type });
      var blobURL = URL.createObjectURL(blob);
      setHeaderImageUrl(blobURL);
      setHeaderImagePreviewUrl(previewUrl);
      setHeaderImageFile(file);
    }
  };

  const removeHeaderImage = () => {
    setHeaderImagePreviewUrl(null);
    setHeaderImageUrl(null);
    setHeaderImageFile(null);
  };

  const handleFooterImageSelection = (file, previewUrl) => {
    setError('');
    const fileSizeError = checkFileSize(file, MAX_SCREEN_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setError(fileSizeError.message);
    } else {
      var blob = new Blob([file], { type: file.type });
      var blobURL = URL.createObjectURL(blob);
      setFooterImageUrl(blobURL);
      setFooterImagePreviewUrl(previewUrl);
      setFooterImageFile(file);
    }
  };

  const removeFooterImage = () => {
    setFooterImagePreviewUrl(null);
    setFooterImageUrl(null);
    setFooterImageFile(null);
  };

  const dragEnded = (param) => {
    const { source, destination } = param;
    if (!destination) {
      return;
    }
    let _arr = [...groupOrder];
    const _item = _arr.splice(source.index, 1)[0];
    _arr.splice(destination.index, 0, _item);
    setGroupOrder(_arr);
  };

  return (
    <>
      <FeedbackBar message={error} />
      <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 150 }}>
        <Grid item xs={12}>
          <Typography variant="h2">{t('PrintDialog.MenuTemplate')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="grouped-select">{t('PrintDialog.Template')}</InputLabel>
            <Select
              defaultValue={menuType}
              value={menuType}
              onChange={(e) => setMenuType(e.target.value)}
              label={t('PrintDialog.Template')}>
              <MenuItem value={MENU_TYPE.DEFAULT}>{t('PrintDialog.DefaultMenuType')}</MenuItem>
              <MenuItem value={MENU_TYPE.CLASSIC}>{t('PrintDialog.ClassicMenuType')}</MenuItem>
              <MenuItem value={MENU_TYPE.VERTICAL}>{t('PrintDialog.VerticalMenuType')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2">{t('PrintDialog.Basics')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            label={t('PrintDialog.MenuTitle')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={classes.formControl}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="grouped-select">{t('PrintDialog.GroupBy')}</InputLabel>
            <Select defaultValue="none" value={groupBy} onChange={(e) => setGroupBy(e.target.value)} label={t('PrintDialog.GroupBy')}>
              <MenuItem value="none">
                <em>{t('PrintDialog.None')}</em>
              </MenuItem>
              <MenuItem value="style">{t('PrintDialog.Style')}</MenuItem>
              <MenuItem value="category">{t('PrintDialog.Category')}</MenuItem>
              <MenuItem value="brewery">{t('PrintDialog.Brewery')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="grouped-select">{t('PrintDialog.Columns')}</InputLabel>
            <Select defaultValue="" value={columns} onChange={(e) => setColumns(Number(e.target.value))} label={t('PrintDialog.Columns')}>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="grouped-select">{t('PrintDialog.SortBy')}</InputLabel>
            <Select defaultValue="" value={sortBy} onChange={(e) => setSortBy(e.target.value)} label={t('PrintDialog.SortBy')}>
              <MenuItem value="handle">{t('PrintDialog.TapHandleNumber')}</MenuItem>
              <MenuItem value="abv">{t('PrintDialog.BeverageABV')}</MenuItem>
              <MenuItem value="style">{t('PrintDialog.Style')}</MenuItem>
              <MenuItem value="category">{t('PrintDialog.Category')}</MenuItem>
              <MenuItem value="breweryName">{t('PrintDialog.BreweryName')}</MenuItem>
              <MenuItem value="beverageName">{t('PrintDialog.BeverageName')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
          <Typography variant="h2" className={classes.sectionHeading}>
            {t('PrintDialog.TapList')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <TextField
                label={t('PrintDialog.TapNoStart')}
                type="number"
                variant="outlined"
                value={tapStart}
                onChange={(e) => setTapStart(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label={t('PrintDialog.TapNoEnd')}
                type="number"
                variant="outlined"
                value={tapEnd}
                onChange={(e) => setTapEnd(parseInt(e.target.value))}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField label={t('PrintDialog.TapsShown')} type="number" variant="outlined" disabled value={tapEnd - tapStart + 1 || 0} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: 10, paddingTop: 20 }}>
          <Grid item xs={12}>
            <Accordion
              defaultExpanded={false}
              expanded={sortGroupsExpanded}
              onChange={(_, expanded) => {
                if (!isGrouped) return;
                setSortGroupsExpanded(expanded);
              }}>
              <AccordionSummary style={{ padding: 0, margin: 0, height: 40 }}>
                <div>
                  <div className="flex flex-row items-center">
                    <Typography variant="h2">{t('PrintDialog.SortGroups')}</Typography>
                    <img
                      className="w-4 h-4 ml-2 transition-transform"
                      src={sortGroupsExpanded ? ChevronUp : ChevronDown}
                      alt="Chevron Icon"
                    />
                  </div>
                  <p style={{ color: MediumGrey }}>
                    {!isGrouped ? t('PrintDialog.SortGroupsDisabled') : t('PrintDialog.SelectGroupOrder')}
                  </p>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0, paddingTop: 20 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DragDropContext onDragEnd={dragEnded}>
                      <Droppable droppableId="droppable">
                        {(provided, _) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {groupOrder.map((data, index) => (
                              <Draggable key={data.id} draggableId={data.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    className="flex flex-row items-center"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                    <img className="mr-2" width={24} src={DragIcon} alt="Drag and Drop Icon" />
                                    <p>{data.groupName}</p>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {isCustomMenu && (
          <>
            <Grid item xs={12}>
              <Typography variant="h2">{t('PrintDialog.Style')}</Typography>
            </Grid>
            <Grid container spacing={2} style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
              <Grid item xs={12}>
                <Typography variant="h3">{t('PrintDialog.Colors')}</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <ColorPicker
                  color={primaryTextColor}
                  label={t('PrintDialog.PrimaryTextColor')}
                  onColorSelected={(c) => setPrimaryTextColor(c.hex)}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <ColorPicker
                  color={secondaryTextColor}
                  label={t('PrintDialog.SecondaryTextColor')}
                  onColorSelected={(c) => setSecondaryTextColor(c.hex)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
              <Grid item xs={6} md={6}>
                <ColorPicker color={primaryColor} label={t('PrintDialog.PrimaryColor')} onColorSelected={(c) => setPrimaryColor(c.hex)} />
              </Grid>
              <Grid item xs={6} md={6}>
                <ColorPicker
                  color={secondaryColor}
                  label={t('PrintDialog.SecondaryColor')}
                  onColorSelected={(c) => setSecondaryColor(c.hex)}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <ColorPicker color={dashColor} label={t('PrintDialog.DashColor')} onColorSelected={(c) => setDashColor(c.hex)} />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">{t('PrintDialog.HeaderOptions')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="header-type">{t('PrintDialog.HeaderType')}</InputLabel>
                  {isClassicMenu && (
                    <ClassicHeaderSelect value={classicHeaderType} onChange={(e) => setClassicHeaderType(e.target.value)} />
                  )}
                  {isVerticalMenu && (
                    <VerticalHeaderSelect value={verticalHeaderType} onChange={(e) => setVerticalHeaderType(e.target.value)} />
                  )}
                </FormControl>
              </Grid>

              {headerTypeIsLogo && (
                <Grid container spacing={2} style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                  <Grid item xs={12}>
                    <Typography variant="p">{t('PrintDialog.HeaderImageSize')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FileUpload onFileSelected={handleHeaderImageSelection} maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES} id="header" />
                  </Grid>
                  <Grid item xs={12}>
                    {headerImagePreviewUrl && <img className={classes.image} src={headerImagePreviewUrl} alt="Header preview" />}
                  </Grid>
                  <Grid item xs={12}>
                    {(headerImageUrl || headerImagePreviewUrl) && (
                      <p>
                        <CustomLink onClick={removeHeaderImage}>{t('PrintDialog.RemoveHeaderImage')}</CustomLink>
                      </p>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography variant="h3">{t('PrintDialog.Options')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowTapNumber')}
            control={<Checkbox checked={showHandle} onChange={(e) => setShowHandle(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PageEditForm.ShowBreweryName')}
            control={<Checkbox checked={showBreweryName} onChange={(e) => setShowBreweryName(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowServings')}
            control={<Checkbox checked={showServings} onChange={(e) => setShowServings(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowServingSize')}
            control={<Checkbox checked={showServingSize} onChange={(e) => setShowServingSize(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowServingCurrency')}
            control={
              <Checkbox checked={showServingCurrency} onChange={(e) => setShowServingCurrency(Boolean(e.target.checked))} color="primary" />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowBeerStyle')}
            control={<Checkbox checked={showBeerStyle} onChange={(e) => setShowBeerStyle(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowDescription')}
            control={<Checkbox checked={showDescription} onChange={(e) => setShowDescription(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label={t('PrintDialog.ShowLocation')}
            control={<Checkbox checked={showLocation} onChange={(e) => setShowLocation(Boolean(e.target.checked))} color="primary" />}
          />
        </Grid>
        {!isCustomMenu && (
          <>
            <Grid item xs={6}>
              <FormControlLabel
                label={t('PrintDialog.ShowDate')}
                control={<Checkbox checked={showDate} onChange={(e) => setShowDate(Boolean(e.target.checked))} color="primary" />}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                label={t('PageEditForm.ShowLogo')}
                control={<Checkbox checked={showLogo} onChange={(e) => setShowLogo(Boolean(e.target.checked))} color="primary" />}
              />
            </Grid>
          </>
        )}
        {isCustomMenu && (
          <>
            <Grid item xs={12}>
              <Typography variant="h3">{t('PrintDialog.FooterImage')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">{t('PrintDialog.FooterImageSize')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FileUpload onFileSelected={handleFooterImageSelection} maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES} id="footer" />
            </Grid>
            <Grid item xs={12}>
              {footerImagePreviewUrl && <img className={classes.image} src={footerImagePreviewUrl} alt="Footer preview" />}
            </Grid>
            <Grid item xs={12}>
              {(footerImageUrl || footerImagePreviewUrl) && (
                <p>
                  <CustomLink onClick={removeFooterImage}>{t('PrintDialog.RemoveFooterImage')}</CustomLink>
                </p>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">{t('PrintDialog.FooterText')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PrintDialog.Date')}
                fullWidth
                variant="outlined"
                value={footerDate}
                onChange={(e) => setFooterDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={t('PrintDialog.Location')}
                fullWidth
                variant="outlined"
                value={footerLocation}
                onChange={(e) => setFooterLocation(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('PrintDialog.Website')}
                fullWidth
                variant="outlined"
                value={footerWebsite}
                onChange={(e) => setFooterWebsite(e.target.value)}
                className={classes.formControl}
              />
            </Grid>
          </>
        )}
        <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
          <Grid container direction="row">
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button variant="text" color="default" onClick={onCancel}>
                {t('PrintDialog.Cancel')}
              </Button>
              <Button variant="text" color="primary" onClick={handlePrint}>
                {t('PrintDialog.Print')}
              </Button>
            </Grid>
          </Grid>
        </ActionBar>
      </Grid>
    </>
  );
};
