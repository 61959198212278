import React, { useState } from 'react';
import { makeStyles, FormControl, Select, MenuItem, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DarkGrey, LightGrey } from '../../theme';

export const Preview = ({ match }) => {
  const { t } = useTranslation();
  const {
    params: { screen_id }
  } = match;

  const [size, setSize] = useState(1080);
  const [fullScreen, setFullScreen] = useState(false);

  const classes = useStyles();

  document.body.style.overflow = 'scroll';

  const requestFullScreenMethod =
    document.body.requestFullScreen ||
    document.body.webkitRequestFullScreen ||
    document.body.mozRequestFullScreen ||
    document.body.msRequestFullScreen;
  const cancelFullScreenMethod =
    document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen;
  const handleRequestFullScreen = () => {
    if (!fullScreen && requestFullScreenMethod) {
      // Native full screen.
      requestFullScreenMethod.call(document.body);
      setFullScreen(true);
    } else if (fullScreen && cancelFullScreenMethod) {
      cancelFullScreenMethod.call(document);
      setFullScreen(false);
    }
  };

  const [width, height] = size === 720 ? [1280, 720] : [1920, 1080];

  return (
    <div className={classes.root}>
      <div className={classes.screenSizeSelection}>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            MenuProps={{ classes: { paper: classes.selectMenu } }}
            placeholder={t('Preview.ScreenSize')}
            value={size}
            onChange={(e) => setSize(Number(e.target.value))}>
            <MenuItem value={720}>{t('Preview.720TvScreen')}</MenuItem>
            <MenuItem value={1080}>{t('Preview.1080pTvScreen')}</MenuItem>
          </Select>
        </FormControl>
        {requestFullScreenMethod && (
          <Button variant="contained" color="primary" size="large" style={{ marginLeft: 10 }} onClick={handleRequestFullScreen}>
            {fullScreen ? t('Preview.ExitFullScreen') : t('Preview.FullScreen')}
          </Button>
        )}
      </div>
      <div>
        <div className={size === 720 ? classes.screenContainer720 : classes.screenContainer1080}>
          <iframe
            title={t('Preview.PreviewScreen')}
            src={`/wall/screen/${screen_id}`}
            width={`${width}px`}
            height={`${height}px`}
            frameBorder="none"></iframe>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100%',
    minHeight: '100%',
    textAlign: 'center',
    backgroundColor: DarkGrey,
    overflow: 'scroll'
  },
  screenSizeSelection: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  formControl: {
    '& .MuiInputBase-input': {
      backgroundColor: 'white'
    }
  },
  selectMenu: {
    border: `1px solid ${LightGrey}`
  },
  screenContainer1080: {
    minWidth: 1920,
    minHeight: 1080,
    maxWidth: 1920,
    maxHeight: 1080,
    overflow: 'hidden',
    backgroundColor: 'white',
    margin: 'auto'
  },
  screenContainer720: {
    minWidth: 1280,
    minHeight: 720,
    maxWidth: 1280,
    maxHeight: 720,
    overflow: 'hidden',
    backgroundColor: 'white',
    margin: 'auto'
  }
}));
