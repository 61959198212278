import gql from 'graphql-tag';
import { SCREEN_FIELDS } from '../../data/graphql';

export const GET_SCREENS = gql`
    query GetScreens($location: MongoID!){
        screens: screenMany(filter: { location: $location }) {
            ${SCREEN_FIELDS}
        }
    }
`;

export const GET_SCREEN_BY_ID = gql`
    query GetScreenById($id: MongoID!){
        screen: screenById(_id: $id) {
            ${SCREEN_FIELDS}
        }
    }
`;
