interface EmbedIconProps {
  className?: string;
}

const EmbedIcon = ({ className }: EmbedIconProps) => (
  <svg className={className} width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.5 57.0002C26.7583 57.0002 25.2674 56.38 24.0271 55.1397C22.7868 53.8995 22.1667 52.4085 22.1667 50.6668V12.6668C22.1667 10.9252 22.7868 9.43419 24.0271 8.19391C25.2674 6.95363 26.7583 6.3335 28.5 6.3335H57C58.7417 6.3335 60.2326 6.95363 61.4729 8.19391C62.7132 9.43419 63.3333 10.9252 63.3333 12.6668V50.6668C63.3333 52.4085 62.7132 53.8995 61.4729 55.1397C60.2326 56.38 58.7417 57.0002 57 57.0002H28.5ZM28.5 50.6668H57V12.6668H28.5V50.6668ZM15.8333 69.6668C14.0917 69.6668 12.6007 69.0467 11.3604 67.8064C10.1201 66.5661 9.5 65.0752 9.5 63.3335V19.0002H15.8333V63.3335H50.6667V69.6668H15.8333Z"
      fill="currentColor"
    />
  </svg>
);

export default EmbedIcon;
