import { useContext, useEffect, useRef } from 'react';
import { SessionContext } from '../../../contexts/session';
import useLocalStorage from '../../../contexts/localStorage';

const useLocationStorage = (key: string, initialValue: string | boolean | number) => {
  const { currentLocation } = useContext(SessionContext);
  const previousLocation = useRef(currentLocation);
  const [location, setLocation] = useLocalStorage(`${key}${currentLocation?._id}`, initialValue);

  useEffect(() => {
    if (previousLocation.current?._id !== currentLocation?._id) {
      const storedValue = window.localStorage.getItem(`${key}${currentLocation?._id}`);
      setLocation((storedValue && JSON.parse(storedValue)) ?? initialValue);
      previousLocation.current = currentLocation;
    }
  }, [currentLocation, setLocation, key, initialValue]);

  return [location, setLocation];
};

export default useLocationStorage;
