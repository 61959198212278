import React, { useState, useContext, useEffect } from 'react';
import { Slide, useTheme, useMediaQuery, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { find } from 'lodash';

import { SessionContext } from '../../contexts/session';

import { Walkthrough } from './start';

import WavyBackground from '../../images/hero-bg-mobile.svg';
import { useMutation } from 'react-apollo';
import { UPDATE_USER } from './mutations';
import { cleanCachedObjectForMutation } from '../../util/graphql';
import { Orange } from '../../theme';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 80
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  content: {
    '&::after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      bottom: 0,
      left: 0,
      right: 0,
      background: `url(${WavyBackground}) no-repeat`,
      transform: 'rotate(180deg)',
      backgroundPosition: '0 -75px',
      backgroundSize: 'contain'
    },
    [theme.breakpoints.up('sm')]: {
      '&::after': {
        backgroundImage: 'none',
        backgroundColor: Orange,
        height: 340
      }
    },
    [theme.breakpoints.up('md')]: {
      '&::after': {
        height: 200
      }
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const WalkthroughDialog = () => {
  const [open, setOpen] = useState(false);

  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const classes = useStyles();

  const { user } = useContext(SessionContext);

  const [updateUser] = useMutation(UPDATE_USER);

  useEffect(() => {
    let match = find(user.metadata, (m) => m.key === 'walkthroughComplete');
    if (!match || match.value !== 'true') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [user]);

  const handleCancel = () => {};

  window.resetWalkthrough = async () => {
    const cleanUser = cleanCachedObjectForMutation(user);
    let match = find(cleanUser.metadata, (m) => m.key === 'walkthroughComplete');
    if (!match) {
      match = { key: 'walkthroughComplete' };
      cleanUser.metadata.push(match);
    }
    match.value = 'false';
    try {
      await updateUser({ variables: { user: cleanUser } });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCompleted = async () => {
    const cleanUser = cleanCachedObjectForMutation(user);
    let match = find(cleanUser.metadata, (m) => m.key === 'walkthroughComplete');
    if (!match) {
      match = { key: 'walkthroughComplete' };
      cleanUser.metadata.push(match);
    }
    match.value = 'true';
    try {
      await updateUser({ variables: { user: cleanUser } });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog fullWidth open={open} TransitionComponent={Transition} keepMounted fullScreen={fullScreen} onClose={handleCancel}>
      <DialogContent className={classes.content}>
        <Walkthrough onComplete={handleCompleted} />
      </DialogContent>
    </Dialog>
  );
};
