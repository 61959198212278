import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import { LightGrey, FontFamilyUnicaOne, FontFamilyOpenSans, FontFamilyOswald, FontFamilyInter } from '../theme';

export const FontPicker = ({ value, label, onChange }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" fullWidth className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        MenuProps={{ classes: { paper: classes.selectMenu } }}
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}>
        <MenuItem value={'Open Sans'} className={classes.openSans}>
          Open Sans
        </MenuItem>
        <MenuItem value={'Unica One'} className={classes.unicaOne}>
          Unica One
        </MenuItem>
        <MenuItem value={'Inter'} className={classes.inter}>
          Inter
        </MenuItem>
        <MenuItem value={'Oswald'} className={classes.oswald}>
          Oswald
        </MenuItem>
        {/*<MenuItem value={'Ubuntu}'} className={classes.ubuntu}>Ubuntu</MenuItem>*/}
        {/*<MenuItem value={'Lobster'} className={classes.lobster}>Lobster</MenuItem>*/}
        <MenuItem value={'Anton'} className={classes.anton}>
          Anton
        </MenuItem>
        <MenuItem value={'Comfortaa'} className={classes.comfortaa}>
          Comfortaa
        </MenuItem>
        <MenuItem value={'Fjalla One'} className={classes.fjallaOne}>
          Fjalla One
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {},
  selectMenu: {
    border: `1px solid ${LightGrey}`
  },

  openSans: {
    fontFamily: FontFamilyOpenSans,
    fontWeight: 'bold'
  },
  unicaOne: {
    fontFamily: FontFamilyUnicaOne
  },
  inter: {
    fontFamily: FontFamilyInter,
    fontWeight: 'bold'
  },
  oswald: {
    fontFamily: FontFamilyOswald,
    fontWeight: 'bold'
  },
  ubuntu: {
    fontFamily: 'Ubuntu'
  },
  lobster: {
    fontFamily: 'Lobster'
  },
  anton: {
    fontFamily: 'Anton'
  },
  comfortaa: {
    fontFamily: 'Comfortaa'
  },
  fjallaOne: {
    fontFamily: 'Fjalla One'
  }
}));
