import React, { Suspense, useEffect } from 'react';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import ReactGA from 'react-ga';

import { SessionContext, useSession } from './contexts/session';
import { WebSocketProvider } from './contexts/webSockets';
import { DeviceProvider } from './contexts/device';
import { EnableWebSocketsProvider } from './contexts/enableWebSockets';
import { RouteWithSubRoutes, PrivateRoute } from './Route';
import { graphQLClient } from './data/graphql';
import { theme } from './theme';

import routes from './routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'moment/locale/es';

const logPageView = (location) => {
  const page = location.pathname || window.location.pathname;
  ReactGA.set({ page: page });
  ReactGA.pageview(page);
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      page_path: page
    });
  }
};

const listenForPageChange = (history) => {
  history.listen((location) => logPageView(location));
};

const App = () => {
  return (
    <ApolloProvider client={graphQLClient}>
      <DeviceProvider>
        <EnableWebSocketsProvider>
          <WebSocketProvider>
            <SessionedApp />
          </WebSocketProvider>
        </EnableWebSocketsProvider>
      </DeviceProvider>
    </ApolloProvider>
  );
};

const SessionedApp = () => {
  const session = useSession();

  return (
    <SessionContext.Provider value={session}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </ThemeProvider>
    </SessionContext.Provider>
  );
};

const AppRouter = () => {
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize('UA-57009065-1');
    logPageView(window.location);
    listenForPageChange(history);
  }, [history]);

  return (
    <Suspense fallback="">
      {routes.map((route, i) => (route.private ? <PrivateRoute key={i} {...route} /> : <RouteWithSubRoutes key={i} {...route} />))}
    </Suspense>
  );
};

export default App;
