import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import InputLabel from '@material-ui/core/InputLabel';
import NotchedOutline from '@material-ui/core/OutlinedInput/NotchedOutline';
import { withStyles } from '@material-ui/core/styles';
import shortid from 'shortid';

const styles = {
  root: {
    position: 'relative'
  },
  content: {
    padding: '14px 14px',

    '& fieldset': {
      borderRadius: 4
    }
  },
  inputLabel: {
    position: 'absolute',
    left: 0,
    top: 0,
    // slight alteration to spec spacing to match visual spec result
    transform: 'translate(0, 24px) scale(1)'
  }
};

const LabelledOutline = ({ classes = {}, id, label, children }) => {
  const [labelWidth, setLabelWidth] = useState(0);
  const [htmlId] = useState(id || shortid.generate());
  const labelRef = useRef(null);

  useEffect(() => {
    const labelNode = ReactDOM.findDOMNode(labelRef.current);
    setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
  }, [label]);

  return (
    <div style={{ position: 'relative', marginTop: '8px' }}>
      <InputLabel ref={labelRef} htmlFor={htmlId} variant="outlined" className={classes.inputLabel} shrink>
        {label}
      </InputLabel>
      <div className={classes.root}>
        <div id={htmlId} className={classes.content}>
          {children}
          <NotchedOutline notched labelWidth={labelWidth} />
        </div>
      </div>
    </div>
  );
};
export default withStyles(styles)(LabelledOutline);
