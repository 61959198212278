import gql from 'graphql-tag';
import { SCREEN_FIELDS } from '../../data/graphql';

export const FIND_DEVICE = gql`
    query FindDevice($code: String!){
        device: deviceOne(filter:{registrationCode:$code}){
            _id,
            name,
            type,
            registrationCode,
            ipAddress,
            screen {
                ${SCREEN_FIELDS}
            }
          }
    }
`;

export const GET_SCREENS = gql`
    query GetScreens($location: MongoID!){
        screens: screenMany(filter: { location: $location }) {
            ${SCREEN_FIELDS}
        }
    }
`;
