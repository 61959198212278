import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import CastSession from '../../../util/CastSession';

import { LinkDeviceWorkflow } from '../../devices/LinkDeviceWorkflow';

import ChromecastImage from '../../../images/device-chromecast.png';
import CustomLink from '../../../components/CustomLink';

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  loadingContainer: {
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  deviceOverview: {
    paddingTop: 20,

    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  deviceImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

let caster;

export const ChromecastConnector = ({ onCancel, screen }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [supported, setSupported] = useState(true);
  const [loading, setLoading] = useState(true);

  const bootstrapCasting = () => {
    try {
      caster.initializeCastApi();
      setTimeout(() => {
        caster.doCast();
        setLoading(false);
      }, 500);
    } catch (e) {
      console.log(e);
      setSupported(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('bootstrapping cast engine');
    caster = new CastSession(undefined, (err) => console.log(err));
    setTimeout(bootstrapCasting, 500);
  }, []);

  const handleRetryCast = () => {
    setLoading(true);
    try {
      caster.doCast();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setSupported(false);
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <ArrowBack onClick={onCancel} />
      </Grid>
      <Grid item xs={8} className={classes.pageHeading}>
        {t('ChromeCast.ConnectChromeCast')}
      </Grid>
      {loading && (
        <Grid item xs={12} className={classes.loadingContainer}>
          <CircularProgress />
        </Grid>
      )}
      {!loading && supported && (
        <Grid container item xs={12} className={classes.deviceOverview} spacing={2}>
          <Grid item xs={3} className={classes.deviceImage}>
            <img alt="Chromecast" src={ChromecastImage} />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h2">{t('ChromeCast.ChromeCastReady')}</Typography>
            <p>{t('ChromeCast.AvailableDevices')}</p>
            <p>
              {t('ChromeCast.NothingAppears')} <CustomLink onClick={handleRetryCast}>{t('ChromeCast.ClickHere')}</CustomLink>{' '}
              {t('ChromeCast.ToRetry')}
            </p>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <LinkDeviceWorkflow screen={screen} />
          </Grid>
        </Grid>
      )}
      {!loading && !supported && (
        <Grid item xs={12}>
          <p>{t('ChromeCast.ConnectionNotSupported')}</p>
          <p>{t('ChromeCast.ConnectionSupported')}</p>
        </Grid>
      )}
    </Grid>
  );
};
