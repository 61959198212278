import ApolloClient from 'apollo-boost';

export const graphQLClient = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URI}/graphql`,
  request: (operation) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      operation.setContext({
        headers: {
          authorization: token
        }
      });
    }
  }
});

export const THEME_FIELDS = `
    backgroundImageUrl,
    showBackgroundImageHelper,
    backgroundOpacity,
    backgroundPosition,
    backgroundSize,
    backgroundRepeat,
    backgroundColor,
    foregroundColor
    foregroundColor2,
    textSize,
    showTapNumber,
    showBeverageImage,
    beverageImageSize,
    hideBeverageSourceName,
    showBeverageSourceLocation,
    menuType,
    enableAutoPaging,
    showHeading,
    showLogo,
    showRating,
    showServings,
    showStripes,
    showLocation,
    showTapsDescription,
    reverseBreweryAndName,
    columns,
    primaryFont,
    secondaryFont,
    showServingSize,
    showServingCurrency,
    kegMonitoringPartner,
    kegMonitoringApiKey,
    tapsOrientation,
`;

export const SUBSCRIPTION_FIELDS = `
    quantity,
    interval,
    currency,
    unit_amount,
    grandfathered
`;

export const ACCOUNT_FIELDS = `
    _id
    name
    status
    plan
    datePlanCanceled
    datePlanCancels
    dateCreated
    dateUpdated
    stripeCustomerId
    pendingSubscriptionPayment
    elegibleForFreeTrial
    users {
        user {
            _id
            firstName
            lastName
            local {
                email
            }
            hasBeenInvitedByOwner
            metadata {
                key
            }
        }
        role
        locations
    }
    subscription {
      ${SUBSCRIPTION_FIELDS}
    }
`;

export const SERVING_SETTINGS_FIELDS = `
    currency,
    volumeUnit
`;

export const LOCATION_FIELDS = `
    _id,
    active,
    name,
    shortName,
    city,
    state,
    country,
    email,
    settings{
        theme,
        themeOptions {
            ${THEME_FIELDS}
        },
        customCss
    },
    metadata {
        _id,
        key,
        value
    },
    account {
        ${ACCOUNT_FIELDS}
    }
    dateCreated,
    dateUpdated
    servingSettings {
      ${SERVING_SETTINGS_FIELDS}
    }
`;

export const USER_FIELDS = `
    _id,
    firstName,
    lastName,
    local {
        email
    },
    location {
        ${LOCATION_FIELDS}
    },
    metadata {
        key,
        value
    },
    servingSettings {
        ${SERVING_SETTINGS_FIELDS}
    },
    dateCreated,
    dateUpdated,
    appLanguage
    hasBeenInvitedByOwner
`;

export const SCREEN_FIELDS = `
    _id,
    name,
    number,
    orientation,
    dateCreated,
    dateUpdated,
    device {
        _id,
        type,
        lastHeartbeat
    }
    location {
        ${LOCATION_FIELDS}
    },
    pages{
        _id,
        type,
        name,
        key,
        rotationDuration,
        number,
        tapEnd,
        tapStart,
        imageUrl,
        imagePosition,
        style {
            pageKey,
            theme,
            themeOptions {
                ${THEME_FIELDS}
            },
            customCss
        }
    }
`;

export const BEVERAGE_FIELDS = `
    abv,
    name,
    description,
    ibu,
    rateBeerRating,
    style,
    category,
    type,
    vintage,
    source {
        name,
        location {
            locality,
            region,
            country
        }
    }
    images {
        size,
        _id,
        url
    }
`;

export const TAP_FIELDS = `
    _id,
    name,
    status,
    location,
    list,
    servings{
        _id,
        size,
        price,
        vessel,
        key,
        settings {
            currency,
            volumeUnit
        },
    }
    isNitro,
    isCask,
    onNow,
    size,
    showABV,
    showIBU,
    beverage {
        ${BEVERAGE_FIELDS}
    }
`;

export const BEVERAGE_CATEGORY_FIELDS = `
    _id,
    name,
    description,
    dateCreated,
    dateUpdated
`;

export const USER_VESSEL_FIELDS = `
    _id,
    name,
    size,
    createdBy
`;
