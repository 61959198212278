/// =============================================
/// GOOGLE CHROMECAST CUSTOM SENDER
/// =============================================
/* global chrome */

import { GOOGLECAST_APP_ID } from './config';

export default class CastSession {
  constructor(applicationID, errorCallback) {
    this.applicationID = applicationID || GOOGLECAST_APP_ID;
    this.errorCallback = errorCallback;
  }

  namespace = 'urn:x-cast:com.pourwall.wall.cast';
  session = null;

  initializeCastApi() {
    /*
		var instance = cast.framework.CastContext.getInstance();
		instance.setOptions({
			receiverApplicationId: applicationID,
			autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
		});
		
		console.log('cast initialized');
		console.log(instance, instance.getCurrentSession());
		*/

    var sessionRequest = new chrome.cast.SessionRequest(this.applicationID);
    var apiConfig = new chrome.cast.ApiConfig(sessionRequest, this.sessionListener, (e) => this.receiverListener(e));
    chrome.cast.initialize(
      apiConfig,
      () => {
        this.appendMessage('onInitSuccess', arguments);
      },
      (message) => {
        this.errorCallback(message);
        console.log(message);
        this.appendMessage('onError: ' + JSON.stringify(message));
      }
    );
  }

  doCast() {
    var data = {};

    this.sendMessage(JSON.stringify(data));
    //chrome.cast.requestSession(onRequestSessionSuccess, onLaunchError);
  }

  sendMessage(message) {
    /*
		var castSession = cast.framework.CastContext.getInstance().getCurrentSession();
		onSuccess('getting session...', castSession, message);
		console.log(cast.framework.CastContext.getInstance(), castSession, message);
		*/

    console.log('Sending message', message, this.session);

    if (this.session != null) {
      //session.sendMessage(namespace, message, onSuccess.bind(this, "Message sent: " + message), onError);
    } else {
      chrome.cast.requestSession(
        (e) => {
          this.session = e;
          this.onSuccess('session created.');
          this.session.sendMessage(this.namespace, message, this.onSuccess.bind(this, 'Message sent: ' + message), (message) => {
            this.errorCallback(message);
            console.log(message);
            this.appendMessage('onError', message);
          });
          //setTimeout(function(){ session.sendMessage(namespace, message, onSuccess.bind(this, "Message sent: " + message), onError); }, 1000);
        },
        (message) => {
          console.log(arguments);
          if (message.code === 'cancel') {
            console.log('User canceled');
          } else {
            this.errorCallback(message);
            this.appendMessage('onError: ' + JSON.stringify(message));
          }
        }
      );
    }
  }

  /**
   * generic success callback
   */
  onSuccess(message) {
    this.appendMessage('onSuccess: ' + message);
  }

  /**
   * append message to debug message window
   * @param {string} message A message string
   */
  appendMessage(message) {
    console.log(message);
    //var dw = $("#debugmessage");
    //dw.text(dw.text() + '\n' + JSON.stringify(message));
  }

  /**
   * session listener during initialization
   */
  sessionListener(e) {
    this.appendMessage('New session ID:' + e.sessionId);
    this.session = e;
    this.session.addUpdateListener(this.sessionUpdateListener);
    this.session.addMessageListener(this.namespace, this.receiverMessage);
  }

  /**
   * listener for session updates
   */
  sessionUpdateListener(isAlive) {
    var message = isAlive ? 'Session Updated' : 'Session Removed';
    message += ': ' + this.session.sessionId;
    this.appendMessage(message);
    if (!isAlive) {
      this.session = null;
    }
  }

  /**
   * utility function to log messages from the receiver
   * @param {string} namespace The namespace of the message
   * @param {string} message A message string
   */
  receiverMessage(namespace, message) {
    this.appendMessage('receiverMessage: ' + namespace + ', ' + message);
  }

  /**
   * receiver listener during initialization
   */
  receiverListener(e) {
    if (e === 'available') {
      this.appendMessage('receiver found');
      //setTimeout(() => this.doCast(), 2000);
    } else {
      this.appendMessage('receiver list empty');
    }
  }
}
