import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { ActionBar } from '../../../components/ActionBar';
import { Dialog } from '../../../components/Dialog';
import { DialogSettings } from '../DialogSettings';
import QRCode from '../../../images/qrcode_example.jpg';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../components/CustomLink';

const useStyle = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 0 15px 0'
  },
  exampleTitle: {
    //Print title
    alignSelf: 'center',
    fontSize: '14px',
    lineHeight: '16px'
  },
  exampleDesc: {
    //Print description
    alignSelf: 'center',
    fontSize: '10px',
    lineHeight: '12px',
    marginBottom: '12px'
  },
  item: {
    //represent each sticker
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    width: '195px',
    height: '262px',
    border: `1px dashed #2D3135`
  },
  itemTitle: {
    //Brand Name
    color: '#2D3135',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '22px',
    padding: '40px 0 30px 0',
    maxWidth: '123px',
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'clip',
    whiteSpace: 'nowrap'
  },
  itemDesc: {
    // QRCode use description
    color: '#2D3135',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    maxWidth: '180px',
    textAlign: 'center',
    margin: '0 0 30px 0'
  },
  codeContainer: {
    // the container of the QRCode
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '92px', //must be greater than 128, which is the QRCode width
    height: '92px', //must be greater than 128, which is the QRCode height
    marginBottom: '25px',
    padding: '3mm',
    border: '1px solid #2D3135',
    borderRadius: '2px'
  },
  img: {
    // QRCode Example
    position: 'absolute',
    left: 10,
    bottom: 20,
    width: '70px',
    height: '12px',

    fontSize: '8px'
  }
});

export const QrCodeDialog = ({ open, onCancel }) => {
  const skip = JSON.parse(localStorage.getItem('skip')); //get skip setting
  const { t } = useTranslation();

  const [params, setParams] = useState('');

  const [step, setStep] = useState(skip ? 1 : 0); //current step

  const handlePrint = () => {
    window.open(`/taps/qr?${params}`);
  };

  //show instruction again
  const undoSkip = () => {
    localStorage.setItem('skip', false);
    setStep(0);
  };

  //used to reset the page once dialog closed
  useEffect(() => {
    if (!open && !JSON.parse(localStorage.getItem('skip')) && step === 1) {
      setStep(0);
    }
  }, [open, step]);

  const currentStep = () => {
    switch (
      step === 0 &&
      !skip //render current step
    ) {
      case true:
        return <PreStep onNextStep={() => setStep(step + 1)}>{t('QrDialog.Step')}</PreStep>;
      case false:
        return (
          <PrintStep
            setParams={setParams}
            onCancel={skip ? onCancel : () => setStep(step - 1)}
            cancelText={skip ? t('QrDialog.Cancel') : t('QrDialog.Previous')}
            onPrint={handlePrint}>
            {skip ? <CustomLink onClick={undoSkip}>{t('QrDialog.SeeInstructions')}</CustomLink> : 'Step 2 / 2'}
          </PrintStep>
        );
      default:
    }
  };

  return (
    <Dialog title={t('QrDialog.PrintQrCode')} open={open} onCancel={onCancel}>
      <Grid container spacing={2}>
        {currentStep()}
      </Grid>
    </Dialog>
  );
};

//instruction step
const PreStep = ({ children, onNextStep }) => {
  //handle checkbox
  const { t } = useTranslation();
  const handleChange = (key) => (event) => {
    const fieldValue = event.target?.value || !!event.target?.checked;
    localStorage.setItem(key, fieldValue);
  };

  return (
    <>
      <Info>{t('QrDialog.Pourwall')}</Info>
      <QRExample />
      <Info>{t('QrDialog.Settings')}</Info>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel label={t('QrDialog.SkipStepFuture')} control={<Checkbox color="primary" onChange={handleChange('skip')} />} />
          </Grid>
        </Grid>
      </Grid>
      <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
        <Grid container direction="row">
          <Grid item xs={6} style={{ textAlign: 'left', alignSelf: 'center' }}>
            {children}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button variant="text" color="primary" onClick={onNextStep}>
              {t('QrDialog.Next')}
            </Button>
          </Grid>
        </Grid>
      </ActionBar>
    </>
  );
};

//URL page settings
const PrintStep = ({ children, setParams, onPrint, onCancel, cancelText }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogSettings setParams={setParams} tapsPresentationType="qrCode" prefix="qr" />
      <ActionBar dialog={1} style={{ justifyContent: 'flex-end' }}>
        <Grid container direction="row">
          <Grid item xs={6} style={{ textAlign: 'left', alignSelf: 'center' }}>
            {children}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button variant="text" color="default" onClick={onCancel}>
              {' '}
              {cancelText}{' '}
            </Button>
            <Button variant="text" color="primary" onClick={onPrint}>
              {t('QrDialog.Print')}
            </Button>
          </Grid>
        </Grid>
      </ActionBar>
    </>
  );
};

// QRCode representation
const QRExample = () => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <span className={classes.exampleTitle}> {t('QrDialog.Example')} </span>
      <span className={classes.exampleDesc}> ({t('QrDialog.Note')}) </span>
      <div className={classes.item}>
        <span className={classes.itemTitle}> {t('QrDialog.BarNameHere')} </span>
        <div className={classes.codeContainer}>
          <img src={QRCode} width="70" height="70" alt="QRCode" />
        </div>
        <span className={classes.itemDesc}>{t('QrDialog.Scan')}</span>
      </div>
    </div>
  );
};

//custom info
const Info = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Alert severity="info" style={{ marginBottom: 15 }}>
        {children}
      </Alert>
    </Grid>
  );
};
