import React from 'react';
import { Tooltip as ReactTooltip, ITooltip } from 'react-tooltip';

interface TooltipProps extends Omit<ITooltip, 'content'> {
  text?: string;
  content?: React.ReactNode;
  children: React.ReactNode;
  anchorSelect?: string;
}

const defaultProps: Partial<ITooltip> = {
  className: 'max-w-[400px]',
  place: 'top'
};

const Tooltip = ({ text, content, children, anchorSelect, ...props }: TooltipProps) => {
  if (anchorSelect) {
    return (
      <>
        {children}
        <ReactTooltip anchorSelect={anchorSelect} {...defaultProps} {...props}>
          {content || text}
        </ReactTooltip>
      </>
    );
  }

  return (
    <>
      {React.cloneElement(children as React.ReactElement, {
        'data-tooltip-id': text
      })}
      <ReactTooltip id={text} {...defaultProps} {...props}>
        {content || text}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
