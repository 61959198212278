import React from 'react';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/Layout';
import { TapEditForm } from './TapEditForm';
import { FeedbackBar } from '../../components/FeedbackBar';
import { Loading } from '../../components/Loading';

import { GET_TAP_BY_ID } from './queries';
import { UserVesselsProvider } from '../../contexts/userVessels';

export const EditTap = withRouter(({ match, history }) => {
  const { t } = useTranslation();
  const tapId = match?.params?.tap_id;

  const { data, loading, error } = useQuery(GET_TAP_BY_ID, {
    variables: { id: tapId }
  });

  let tap = data?.tap;

  return (
    <UserVesselsProvider>
      <Layout title={t('EditTap.EditTap')}>
        {loading && <Loading className="mx-8" />}
        {!loading && <TapEditForm tap={tap} onClose={() => history.push('/taps')} />}
        <FeedbackBar message={error} />
      </Layout>
    </UserVesselsProvider>
  );
});
