import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Divider,
  Drawer,
  Grid,
  Grow,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { AccountCircle, ExpandLess, ExpandMore, LocalDrink, Menu, Tv } from '@material-ui/icons';
import { darken } from '@material-ui/core/styles/colorManipulator';

import { SessionContext } from '../contexts/session';

import Logo from '../images/pourwall-logo.svg';
import { Black, DarkOrange, FontFamilyUnicaOne, OffWhite } from '../theme';
import { EditLocationDialog } from './LocationDialog';
import PremiumFeatureDialog from './PremiumFeatureDialog';
import IconPremiumChip from '../images/icon-premium-chip.svg';
import { GET_SUBSCRIPTION_DETAILS } from '../contexts/queries';

const useStyles = makeStyles((theme) => ({
  addLocation: {
    color: DarkOrange,
    textDecoration: 'underline'
  },
  logo: {
    height: '20px'
  },
  menuButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10
  },
  list: {
    width: 250
  },
  navLink: {
    textTransform: 'uppercase',
    marginRight: 20,
    color: Black,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
      color: darken('#fff', 0.1)
    }
  },
  navItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    borderRadius: 15,
    backgroundColor: DarkOrange,
    color: 'white',

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: darken(DarkOrange, 0.1)
    },

    '& > svg': {
      //marginRight: 5,
      height: 18
    }
  },
  dropdown: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    borderRadius: 15,
    backgroundColor: DarkOrange,
    color: 'white',

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: darken(DarkOrange, 0.1)
    },

    '& > svg': {
      //marginRight: 5,
      height: 18
    }
  },
  languageDropdown: {
    width: '100%',
    zIndex: 1000
  },
  location: {
    '& span': {
      fontFamily: FontFamilyUnicaOne,
      textTransform: 'uppercase',
      fontSize: `1.5em`
    }
  },
  selectMenu: {
    boxShadow: 'rgba(0, 0, 0, .2) 0px 0px 2px, rgba(0, 0, 0, 0.1) 0px 4px 8px',
    background: 'white',
    borderRadius: 12
  },
  selectedLocation: {
    backgroundColor: OffWhite
  },
  pageHeading: {
    margin: '30px 0 10px 0',
    color: Black
  }
}));

export const Bar = withRouter(({ history, ...props }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { user, account, locations, locRefetch, currentLocation, setCurrentLocation, premium, isOwner } = useContext(SessionContext);
  const language = user?.appLanguage;

  const { data: subsDetailData } = useQuery(GET_SUBSCRIPTION_DETAILS, {
    variables: { id: account?._id },
    fetchPolicy: 'cache-and-network',
    skip: !account?._id
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [editLocation, setEditLocation] = useState(null);
  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [width, setWidth] = useState(320);
  const measuredRef = useRef(null);

  useEffect(() => {
    setWidth(measuredRef?.current?.offsetWidth || 320);
  }, [i18n.language, measuredRef?.current?.offsetWidth]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleCloseLocationMenu = (event, location) => {
    setCurrentLocation(location);
    handleClose(event);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const toggleDrawer = (side, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const addLocation = () => {
    if (premium && !subsDetailData?.subscription?.grandfathered) {
      let location = {
        _id: null,
        name: ''
      };

      setEditLocation(location);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const onSuccess = async (location) => {
    await locRefetch();
    if (location) setCurrentLocation(location);

    setEditLocation(null);
  };

  const sideList = (side) => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(side, false)} onKeyDown={toggleDrawer(side, false)}>
      <List>
        <ListItem>
          <ListItemText primary={`Hi ${user?.firstName}!`} className={classes.location} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => history.push(`/screens`)}>
          <ListItemIcon>
            <Tv />
          </ListItemIcon>
          <ListItemText primary={t('Navbar.Screens')} />
        </ListItem>
        <ListItem button onClick={() => history.push(`/taps`)}>
          <ListItemIcon>
            <LocalDrink />
          </ListItemIcon>
          <ListItemText primary={t('Navbar.TapMenu')} />
        </ListItem>
      </List>
      <Divider />
      <List>
        {locations?.map((location) => (
          <ListItem
            className={location._id === currentLocation?._id ? classes.selectedLocation : null}
            key={location._id}
            button
            onClick={() => setCurrentLocation(location)}>
            <ListItemIcon />
            <Typography variant={location._id === currentLocation?._id ? 'button' : 'subtitle1'} noWrap>
              {location?.name}
            </Typography>
          </ListItem>
        ))}
        {isOwner && (
          <ListItem
            button
            onClick={(event) => {
              addLocation();
              handleClose(event);
            }}>
            <ListItemIcon />
            <ListItemText className={classes.addLocation} primary={t('Navbar.AddLocation')} />
            {(!premium || subsDetailData?.subscription?.grandfathered) && <img alt="Icon Premium Feature" src={IconPremiumChip} />}
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={() => history.push(`/account`)}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary={t('Navbar.MyAccount')} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
          {sideList('left')}
        </Drawer>
        <Grid item xs={4}>
          <img src={Logo} alt="POURWALL" className={classes.logo} />
        </Grid>
        <Grid item ref={measuredRef}>
          <Hidden mdUp>
            <Menu onClick={toggleDrawer('left', true)} />
          </Hidden>
          <Grid item className={classes.menuButton}>
            <Hidden smDown>
              <Link href="/taps" className={classes.navLink}>
                {t('Navbar.TapMenu')}
              </Link>
              <Link href="/screens" className={classes.navLink}>
                {t('Navbar.Screens')}
              </Link>
              <Link href="/account" className={classes.navItem}>
                <AccountCircle /> {t('Navbar.MyAccount')}
              </Link>
            </Hidden>
          </Grid>
          {editLocation && (
            <EditLocationDialog
              location={editLocation}
              account={account}
              subDetail={subsDetailData?.subscription}
              onCancel={() => setEditLocation(null)}
              onSuccess={onSuccess}
              canDelete={locations.length > 1}
            />
          )}
          <PremiumFeatureDialog
            show={showPremiumFeatureDialog}
            featureDescription={t(`PremiumFeatureDialog.Features.MultiLocation${account?.elegibleForFreeTrial ? 'FreeTrial' : ''}`)}
            onClose={() => setShowPremiumFeatureDialog(false)}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent={'space-between'} grow={0} className={classes.pageHeading}>
        <Typography variant="h1">{props.title}</Typography>
        <Grid style={{ position: 'relative' }}>
          <Hidden smDown>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              className={classes.dropdown}
              onClick={handleToggle}
              fullWidth
              style={{ width: `${width}px` }}>
              <Typography variant="inherit" noWrap>
                {currentLocation?.name}
              </Typography>
              {open ? <ExpandLess style={{ marginLeft: 'auto' }} /> : <ExpandMore style={{ marginLeft: 'auto' }} />}
            </Button>
            <Popper
              anchorEl={anchorRef.current}
              open={open}
              placement={'bottom-start'}
              className={classes.languageDropdown}
              role={undefined}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                  <div className={classes.selectMenu}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        {locations?.map((location) => (
                          <MenuItem
                            onKeyDown={handleListKeyDown}
                            key={location._id}
                            onClick={(event) => handleCloseLocationMenu(event, location)}
                            disableRipple
                            className={location._id === currentLocation?._id ? classes.selectedLocation : null}>
                            <Typography variant="inherit" noWrap>
                              {location?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                        {isOwner && (
                          <MenuItem
                            onKeyDown={handleListKeyDown}
                            className={classes.addLocation}
                            onClick={(event) => {
                              addLocation();
                              handleClose(event);
                            }}
                            disableRipple>
                            {t('Navbar.AddLocation')}{' '}
                            {(!premium || subsDetailData?.subscription?.grandfathered) && (
                              <img alt="Icon Premium Feature" src={IconPremiumChip} style={{ marginLeft: 8 }} />
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                </Grow>
              )}
            </Popper>
          </Hidden>
        </Grid>
      </Grid>
    </>
  );
});
