import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imagePage: {
    flex: 1,
    overflow: 'hidden',
    '& img': {
      width: `100vw`,
      height: `100%`,
      objectFit: 'cover'
    }
  }
}));

export const ImagePage = ({ page }) => {
  const classes = useStyles();

  return (
    <div className={classes.imagePage}>
      <img alt="custom page" src={page.imageUrl} />
    </div>
  );
};
