import gql from 'graphql-tag';
import { SCREEN_FIELDS } from '../../data/graphql';

export const SIGN_FILE_FOR_UPLOAD = gql`
  mutation ($locationName: String!, $fileName: String!, $fileType: String!) {
    signFile(location_name: $locationName, file_name: $fileName, file_type: $fileType) {
      signedUrl
      resourceUrl
    }
  }
`;

export const UPDATE_SCREEN = gql`
    mutation($screen: UpdateByIdScreenInput!){
        screenUpdateById(record:$screen){
            record {
                ${SCREEN_FIELDS}
            }
        }
    }
`;

export const CREATE_SCREEN = gql`
    mutation($screen: CreateOneScreenInput!){
        screenCreateOne(record:$screen){
            record {
                ${SCREEN_FIELDS}
            }
        }
    }
`;

export const DELETE_SCREEN = gql`
    mutation($id:MongoID!){
        screenRemoveById(_id:$id){
            record {
                ${SCREEN_FIELDS}
            }
        }
    }
`;
