import React, { useRef, useState, useContext, useEffect } from 'react';
import { EventEmitter } from '../util/EventEmitter';
import { DeviceContext } from './device';
import { reconnectingWebSocket } from '../util/reconnectingWebSocket';
import { useEnableWebSockets } from './enableWebSockets';

export const WebSocketContext = React.createContext(null);
export const UPDATE_SCREEN_ACTION = 'update_screen';
export const PONG_MESSAGE = 'pong';
export const WEBSOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;
export const ALLOWED_UPDATE_TYPES = ['SCREEN_UPDATE', 'TAP_UPDATE'];
const WEBSOCKET_PING_INTERVAL_MINUTES = process.env.REACT_APP_WEB_SOCKET_PING_INTERVAL_MINUTES;

const eventEmitter = new EventEmitter();

export const WebSocketProvider = ({ children }) => {
  const { device } = useContext(DeviceContext);
  const webSocketClient = useRef();
  const [isWebSocketConnected, setIsWebSocketConnected] = useState();
  const { webSocketsEnabled } = useEnableWebSockets();

  const handleMessage = (data) => {
    console.log('MESSGE DATA', JSON.parse(data));
    try {
      const msg = JSON.parse(data);
      if (msg.message === PONG_MESSAGE) {
        console.log('Received pong');
        return;
      }
      if (msg.action === UPDATE_SCREEN_ACTION) {
        if (ALLOWED_UPDATE_TYPES.includes(msg.type)) {
          console.log('WebSocket.onmessage:', msg.type);
          eventEmitter.emit(msg.type);
          return;
        }
      }
      console.log('Unrecognized WebSocket message', msg);
    } catch (err) {
      console.log('WebSocket.onmessage error', data, err.message);
    }
  };

  const registerScreen = () => {
    if (!webSocketClient.current || !webSocketsEnabled) return;

    console.log('registerScreen screen id:', device?.screen?._id);
    if (device?.screen?._id) {
      try {
        const registerMessage = {
          action: 'register',
          screenId: device?.screen?._id,
          platform: {
            os: navigator.oscpu,
            version: navigator.appVersion
          }
        };
        webSocketClient.current.getClient().send(JSON.stringify(registerMessage));
        console.log('WebSocket.send - registered');
      } catch (err) {
        console.log('Error: WebSocket.send', err);
      }
    } else {
      console.log('Screen id not available; skipping registration');
    }
  };

  useEffect(() => {
    if (!webSocketsEnabled) return;

    let minutesRemaining = Number(WEBSOCKET_PING_INTERVAL_MINUTES || 8);

    const PING_MESSAGE = JSON.stringify({
      action: 'ping'
    });

    const pingWebSocket = () => {
      if (--minutesRemaining === 0) {
        minutesRemaining = WEBSOCKET_PING_INTERVAL_MINUTES;

        if (!webSocketClient.current.isConnected()) return;

        try {
          webSocketClient.current.getClient().send(PING_MESSAGE);
        } catch (err) {
          console.log('WebSocket ping error', err);
        }
      } else {
        console.log('WebSocket remaining minutes until ping', minutesRemaining);
      }
    };

    const pingInterval = setInterval(() => pingWebSocket(), 60 * 1000);

    return () => clearInterval(pingInterval);
  }, [webSocketsEnabled]);

  useEffect(
    () => {
      console.log('register ???');
      if (webSocketClient.current && isWebSocketConnected && webSocketsEnabled) {
        console.log('registering');
        registerScreen();
      } else {
        console.log('not registering', !!webSocketClient, isWebSocketConnected);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [device, isWebSocketConnected, webSocketClient, webSocketsEnabled]
  );

  useEffect(() => {
    if (!webSocketsEnabled) return;
    console.log('assigning webSocketClient');
    const client = reconnectingWebSocket(WEBSOCKET_URL);
    client.on(handleMessage);
    webSocketClient.current = client;
    setIsWebSocketConnected(webSocketClient.current.isConnected());
    return () => webSocketClient.current.closeSocket();
  }, [webSocketsEnabled]);

  useEffect(() => {
    if (!webSocketsEnabled) return;
    return webSocketClient.current.onStateChange(setIsWebSocketConnected);
  }, [setIsWebSocketConnected, webSocketsEnabled]);

  return (
    <WebSocketContext.Provider value={{ isWebSocketConnected, webSocketEventEmitter: eventEmitter }}>{children}</WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
