import gql from 'graphql-tag';
import { USER_FIELDS } from '../../data/graphql';

export const GET_USER = gql`
    query GetUser($id: MongoID!){
        user: userById(_id:$id) {
            ${USER_FIELDS}
        }
    }
`;
