import React from 'react';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import DeviceChromecastImage from '../../images/device-chromecast.png';
import DeviceFireTvImage from '../../images/device-firetv.png';
import DeviceAppleTvImage from '../../images/device-appletv.png';

const useStyles = makeStyles({
  paper: {
    padding: 15
  },
  deviceIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > img': {
      height: 72,
      width: 72
    }
  },
  deviceMeta: {
    paddingLeft: 15,
    '& > h2': {
      margin: 0
    },
    '& > p': {
      margin: 0,
      opacity: 0.8,
      textTransform: 'uppercase',
      fontFamily: '"Unica One", Roboto, sans-serif'
    }
  }
});

export const DeviceCard = ({ device }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container>
        <Grid item xs={3} className={classes.deviceIcon}>
          {device.type === 'chromecast' && <img src={DeviceChromecastImage} alt="Device" />}
          {device.type === 'fire-tv' && <img src={DeviceFireTvImage} alt="Device" />}
          {device.type === 'apple-tv' && <img src={DeviceAppleTvImage} alt="Device" />}
          {/*device.type === 'computer' && <img src={DeviceComputerImage} alt="Device" />*/}
        </Grid>
        <Grid item xs={9} className={classes.deviceMeta}>
          <h2>{device.registrationCode}</h2>
          <p>
            {t('Devices.DeviceType')} <strong>{device.type}</strong>
          </p>
          <p>
            {t('Devices.IPAddress')} <strong>{device.ipAddress}</strong>
          </p>
        </Grid>
      </Grid>
    </Paper>
  );
};
