import { Button, Card, Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    height: '100%'
  },
  memberName: {
    fontSize: '18px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2)
  },
  statusContainer: {
    marginRight: theme.spacing(4),
    width: 55
  },
  label: {
    fontSize: '14px'
  },
  status: {
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  email: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '1.45',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word'
  },
  actionWrapper: {
    height: '100%'
  },
  actionContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(2)
  }
}));

export const MemberCard = ({
  member,
  onManageLocations,
  deleteMemberLoading,
  onRemove
}: {
  member: any;
  deleteMemberLoading: boolean;
  onManageLocations: () => void;
  onRemove: () => void;
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { user, role } = member;
  const isActive = !!user.metadata.length;
  const isOwner = role === 'owner';

  const OWNER = 'Owner';
  const MEMBER = 'Member';

  const i18nOwnerText = `Account.${role === 'owner' ? OWNER : MEMBER}`;

  return (
    <Card className={styles.container} variant="outlined">
      <Typography variant="h1" className={styles.memberName}>{`${user.firstName} ${user.lastName ?? ''}`}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} className={styles.row}>
            <Grid item className={styles.statusContainer}>
              <Typography variant="body1" className={styles.label}>
                {t('Account.Status')}
              </Typography>
              <Typography variant="body1" className={styles.status}>
                {t(`Account.${isActive ? 'Active' : 'Invited'}`)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={styles.label}>
                {t('Account.Role')}
              </Typography>
              <Typography variant="body1" className={styles.status}>
                {t(i18nOwnerText)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" className={styles.label}>
            {t('Account.Email')}
          </Typography>
          <Typography variant="body1" className={styles.email}>
            {user.local.email}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems={'flex-end'} className={styles.actionWrapper}>
        <Grid item xs={12} className={styles.actionContainer}>
          {!isOwner && (
            <Button variant="text" color="primary" onClick={onManageLocations}>
              {t('Account.ManageLocations')}
            </Button>
          )}

          <Button variant="text" color="secondary" disabled={deleteMemberLoading} onClick={onRemove}>
            {t('Account.Remove')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};
