import gql from 'graphql-tag';
import { SCREEN_FIELDS } from '../../data/graphql';

export const DEVICE_PING = gql`
    mutation($deviceType: String!, $code: String!){
        device: devicePing(deviceType: $deviceType, code: $code){
            _id,
            registrationCode,
            dateCreated,
            dateUpdated,
            ipAddress,
            lastHeartbeat,
            type,
            screen {
                ${SCREEN_FIELDS}
            }
        }
    }
`;

export const DEVICE_HEARTBEAT = gql`
    mutation($id: String!){
        device: deviceHeartbeat(id: $id){
            _id,
            registrationCode,
            dateCreated,
            dateUpdated,
            ipAddress,
            lastHeartbeat,
            type,
            screen {
                ${SCREEN_FIELDS}
            }
        }
    }
`;
