// get rid of all the __typename fields
export const cleanCachedObjectForMutation = (obj) => {
  if (obj && typeof obj === 'object') {
    obj = cleanRelationships(obj);
    const { __typename, ...props } = obj;
    obj = { ...props };
    for (let key of Object.keys(obj)) {
      let prop = obj[key];
      if (prop && Array.isArray(prop)) {
        obj[key] = prop.map((item) => cleanCachedObjectForMutation(item));
      } else {
        obj[key] = cleanCachedObjectForMutation(prop);
      }
    }
  }
  return obj;
};

const cleanRelationships = (obj) => {
  if (obj.__typename === 'User') {
    if (obj.location && typeof obj.location === 'object' && obj.location._id) {
      obj.location = obj.location._id;
    }
  }
  if (obj.__typename === 'Screen') {
    if (obj.location && typeof obj.location === 'object' && obj.location._id) {
      obj.location = obj.location._id;
    }
  }
  return obj;
};

export const spreadQueries = (queries) => {
  let result = { error: false, loading: false, data: [] };
  queries.forEach((q) => {
    const { data, loading, error } = q;
    result.error = result.error || error;
    result.loading = result.loading || loading;
    result.data.push(data);
  });
  return result;
};

export const spreadMutations = (mutations) => {
  let mutationStorage = [];
  let result = { error: false, loading: false, data: [] };
  mutations.forEach((q) => {
    const [mutation, { data, loading, error }] = q;
    mutationStorage.push(mutation);
    result.error = result.error || error;
    result.loading = result.loading || loading;
    result.data.push(data);
  });
  return [mutationStorage, result];
};

export const getMessageFromError = (error) => {
  const errors = error?.graphQLErrors || [];
  return errors.map((e) => e.message).join('');
};
