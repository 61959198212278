import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import { Layout } from '../../components/Layout';
import { LinkDeviceWorkflow } from './LinkDeviceWorkflow';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    padding: 30
  }
}));

export const Link = withRouter(({ match, history }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Layout title={t('LinkDevice.LinkDevice')}>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <LinkDeviceWorkflow />
        </Grid>
      </Grid>
    </Layout>
  );
});
