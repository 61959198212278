import React from 'react';
import { ArrowBack } from '@material-ui/icons';
import { Grid, useTheme, useMediaQuery, Dialog as MuiDialog, DialogContent, makeStyles } from '@material-ui/core';
import { Orange } from '../../theme';
import { Transition } from './Transition';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    borderTop: `4px solid ${Orange}`
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  closeArrow: {
    cursor: 'pointer'
  }
}));

export const Dialog = ({ open, onCancel, children, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiDialog fullWidth keepMounted open={open} onClose={onCancel} fullScreen={fullScreen} TransitionComponent={Transition}>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={2}>
            <ArrowBack className={classes.closeArrow} onClick={onCancel} />
          </Grid>
          <Grid item xs={8} className={classes.pageHeading}>
            {title}
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ paddingTop: 20, paddingBottom: 150 }}>
          {children}
        </Grid>
      </DialogContent>
    </MuiDialog>
  );
};
