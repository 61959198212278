import gql from 'graphql-tag';
import { USER_FIELDS } from '../../data/graphql';

export const SIGN_UP = gql`
    mutation Signup($email: String!, $password: String!, $firstName: String!, $lastName: String!, $locationName: String!, $currency: String, $volumeUnit: String, $hearAboutUs: String!, $appLanguage: String!){
        signup(email:$email, password:$password, firstName:$firstName, lastName:$lastName, locationName:$locationName, currency:$currency, volumeUnit:$volumeUnit, hearAboutUs:$hearAboutUs, appLanguage:$appLanguage) {
            token,
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

export const SIGN_UP_MEMBER = gql`
    mutation MemberFinishSignup($email: String!, $password: String!, $firstName: String!, $lastName: String!){
        memberFinishSignup(email:$email, password:$password, firstName:$firstName, lastName:$lastName) {
            token,
            user {
                ${USER_FIELDS}
            }
        }
    }
`;

export const ADD_CONTACT = gql`
  mutation AddContact($email: String!) {
    addContact(email: $email) {
      success
    }
  }
`;
