import gql from 'graphql-tag';
import { ACCOUNT_FIELDS, LOCATION_FIELDS, SUBSCRIPTION_FIELDS, USER_FIELDS, USER_VESSEL_FIELDS } from '../data/graphql';

export const GET_USER = gql`
    query GetUser($id: MongoID!){
        user: userById(_id:$id) {
            ${USER_FIELDS}
        }
    }
`;

export const GET_ACCOUNT_BY_USER = gql`
    query GetAccountByUser($id: MongoID!){
        account: accountOne(filter: { users: { user: $id } }) {
            ${ACCOUNT_FIELDS}
        }
    }
`;

export const GET_USER_VESSELS = gql`
    query GetUserVessels($id: MongoID!){
        userVessels: userVesselMany(filter: { createdBy: $id }) {
            ${USER_VESSEL_FIELDS}
        }
    }
`;

export const GET_LOCATIONS_BY_ACCOUNT = gql`
    query GetLocationsByAccount($id: MongoID!) {
        locations: locationMany(filter: { account: $id }) {
            ${LOCATION_FIELDS}
        }
    }
`;

export const GET_SUBSCRIPTION_DETAILS = gql`
    query GetSubscriptionDetails($id: String!) {
        subscription: getSubscriptionDetails(accountId: $id) {
            ${SUBSCRIPTION_FIELDS}
        }
    }
`;

export const GET_LOCATION_BY_ID = gql`
  query GetLocationById($id: MongoID!) {
    location: locationById(_id: $id) {
      account {
        plan
        status
      }
    }
  }
`;
