import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

import IconPremium from '../images/icon-premium.svg';
import { useSession } from '../contexts/session';

interface PremiumFeatureDialogProps {
  show: boolean;
  featureName?: string;
  featureDescription?: string;
  onClose?: () => void;
}

const PremiumFeatureDialog = ({ show, featureName, featureDescription, onClose }: PremiumFeatureDialogProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(show);
  const cancelButtonRef = useRef(null);
  const { account } = useSession();
  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleUpgrade = () => {
    setOpen(false);
    window.location.pathname = '/account/subscription';
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <img src={IconPremium} alt="Icon Premium Feature" className="absolute top-0 right-0 rotate-45" />
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-bold leading-6 ">
                      {t('PremiumFeatureDialog.Title')}
                    </Dialog.Title>
                    <div className="mt-2">
                      {featureDescription && <p className="text-sm text-gray-500">{featureDescription}</p>}
                      {!featureDescription && (
                        <p className="text-sm text-gray-500">
                          {t(`PremiumFeatureDialog.Description${account?.elegibleForFreeTrial ? 'FreeTrial' : ''}`, { featureName })}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex flex-row justify-end gap-2">
                  {/* tech-debt: weird hack to prevent ripple / focus race condition:
                  https://github.com/mui/material-ui/issues/16810
                  upgrade to MUI v5 should fix in the future
                  */}
                  <button></button>
                  <Button variant="contained" color="default" onClick={handleClose}>
                    {t('PremiumFeatureDialog.CancelButton')}
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleUpgrade}>
                    {t('PremiumFeatureDialog.UpgradeButton')}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PremiumFeatureDialog;
