import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { CURRENCIES_ARRAY } from '../util/currencies';

import { makeStyles } from '@material-ui/core/styles';
import { LightGrey } from '../theme';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectMenu: {
    border: `1px solid ${LightGrey}`
  }
}));

export function CurrencyDropdown({ currency, setCurrency, label = 'Currency' }) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="currency-dropdown">{label}</InputLabel>
      <Select
        MenuProps={{ classes: { paper: classes.selectMenu } }}
        notched
        value={currency}
        labelId="currency-dropdown"
        onChange={(e) => setCurrency(e.target.value)}
        labelWidth={128}>
        {CURRENCIES_ARRAY.map(({ id, name, symbol }) => (
          <MenuItem value={id} key={id}>
            {name} ({symbol})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
