import React, { useContext } from 'react';

import { DeviceRegistration } from './display/registration';
import { Display } from './display/DisplayDevice';
import { DeviceContext } from '../../contexts/device';
import { withRouter } from 'react-router-dom';

export const DirectDisplay = withRouter(({ match }) => {
  const code = match.params?.deviceId;
  const { device, registerDevice } = useContext(DeviceContext);

  const handleDeviceUpdated = (device) => {
    registerDevice(device);
  };

  return (
    <div className="device chromecast">
      {(!device || !device.screen) && <DeviceRegistration deviceType="direct" code={code} onDeviceUpdated={handleDeviceUpdated} />}
      {device && device.screen && <Display />}
    </div>
  );
});
