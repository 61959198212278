import { makeStyles } from '@material-ui/core';
import React from 'react';
import { MenuPrintConfig, MenuPrintPageProps } from './types';

export interface ClassicMenuPageProps extends MenuPrintPageProps {
  Header: React.ElementType<{ config: MenuPrintConfig }>;
  Content: React.ElementType<{
    config: MenuPrintConfig;
    onColumnRef: MenuPrintPageProps['onColumnRef'];
    columns: MenuPrintPageProps['columns'];
  }>;
  Footer: React.ElementType<{ config: MenuPrintConfig }>;
}

const ClassicMenuPage: React.FC<ClassicMenuPageProps> = ({ config, Header, Content, Footer, onColumnRef, columns }) => {
  const classes = useStyles();
  return (
    <div className={classes.fullPage}>
      <Header key="classicmenu-header" config={config} />
      <div className={classes.content}>
        <Content key="classicmenu-content" config={config} onColumnRef={onColumnRef} columns={columns} />
      </div>

      <div className={classes.footer}>
        <Footer key="classicmenu-footer" config={config} />
        {!!config.footerImageUrl && (
          <div className={classes.logoContainer}>
            <img src={config.footerImageUrl} alt={config.title + ' logo'} />
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fullPage: {
    height: '209.55mm',
    width: '161.925mm',
    padding: '12px 16px 8px 16px',
    position: 'relative'
  },
  content: {
    height: '176.74mm',
    width: '100%',
    marginTop: '16px'
  },
  footer: {
    position: 'absolute',
    bottom: 8,
    left: 16,
    right: 16,
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  logoContainer: {
    height: '80px',
    width: '80px',
    '& img': {
      margin: '0px',
      height: '80px',
      width: '80px'
    }
  }
}));

export default ClassicMenuPage;
