import React from 'react';
import clsx from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'destructive';
  size?: 'sm' | 'md' | 'lg';
  rounded?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
}

export const Button = ({
  variant = 'primary',
  size = 'md',
  rounded = false,
  fullWidth = false,
  loading = false,
  className,
  disabled,
  children,
  ...props
}: ButtonProps) => {
  const baseStyles = 'inline-flex items-center justify-center transition-colors duration-200';

  const variants = {
    primary: 'bg-green text-dark-green hover:bg-hover-green disabled:bg-accent-grey disabled:text-light-grey',
    secondary:
      'bg-transparent border-2 border-green text-dark-green hover:bg-light-green disabled:border-accent-grey disabled:text-light-grey',
    destructive:
      'bg-transparent border-2 border-dark-orange text-dark-orange hover:bg-light-orange disabled:border-accent-grey disabled:text-light-grey'
  };

  const sizes = {
    lg: 'h-[52px] px-6 py-4 gap-2 text-base font-semibold leading-5',
    md: 'h-[36px] px-4 py-2 gap-2 text-base font-normal leading-5',
    sm: 'h-[30px] px-4 py-1 gap-2 text-xs font-semibold leading-[22px]'
  };

  const cornerStyles = rounded ? 'rounded-full' : 'rounded-[4px]';

  return (
    <button
      className={clsx(
        baseStyles,
        variants[variant],
        sizes[size],
        cornerStyles,
        fullWidth && 'w-full',
        (disabled || loading) && 'cursor-not-allowed',
        className
      )}
      disabled={disabled || loading}
      {...props}>
      {loading ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
};
