import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { VOLUME_UNITS_ARRAY } from '../util/volumeUnits';

import { makeStyles } from '@material-ui/core/styles';
import { LightGrey } from '../theme';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  selectMenu: {
    border: `1px solid ${LightGrey}`
  }
}));

export function VolumeUnitDropdown({ volumeUnit, setVolumeUnit, label = 'Volume Unit' }) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="volume-unit-dropdown" className={'whitespace-nowrap'}>
        {label}
      </InputLabel>
      <Select
        MenuProps={{ classes: { paper: classes.selectMenu } }}
        value={volumeUnit}
        labelId="volume-unit-dropdown"
        onChange={(e) => setVolumeUnit(e.target.value)}
        labelWidth={152}
        autoWidth>
        {VOLUME_UNITS_ARRAY.map(({ name, value }) => (
          <MenuItem value={value} key={value}>
            {name} ({value})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
