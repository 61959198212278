import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { SessionContext } from './contexts/session';

export const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
};

export const PrivateRoute = (route) => {
  return <Route path={route.path} exact={route.exact} render={(props) => <PrivateRouteComponent {...props} route={route} />} />;
};

const PrivateRouteComponent = (props) => {
  const { route } = props;

  const session = useContext(SessionContext);

  if (!session?.user || !session?.token) {
    return <Redirect to={{ pathname: '/login' }} />;
  }
  // pass the sub-routes down to keep nesting
  return <route.component {...props} routes={route.routes} />;
};
