import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../components/shared/Card';
import { Button } from '../../../components/shared/Button';

import { SessionContext } from '../../../contexts/session';

import IconBeer from '../../../images/icon-beer.svg';
import IconWine from '../../../images/icon-wine.svg';
import IconSpirit from '../../../images/icon-spirit.svg';
import IconOther from '../../../images/icon-beverage-other.svg';
import IconCopy from '../../../images/icon-copy';

import IconPremiumChip from '../../../images/icon-premium.svg';

import { BeerSearch } from './BeerSearch';
import PremiumFeatureDialog from '../../../components/PremiumFeatureDialog';

export const NewBeverageTypeSelection = ({ onSelect }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);

  let session = useContext(SessionContext);

  const { premium } = session;
  const hasMultipleLocations = session.locations?.length > 1;

  const isCopyCardEnabled = hasMultipleLocations && premium;

  const [interstitialType, setInterstitialType] = useState(null);
  const [searchType, setSearchType] = useState(null);

  const confirmType = (interstitialType) => {
    if (interstitialType === 'Copy' && premium) {
      return history.push('/taps/copy');
    }

    if (interstitialType === 'Beer') {
      return setSearchType('Beer');
    }

    onSelect({ type: interstitialType });
  };

  const handleCopyClick = useCallback(() => {
    if (!premium) {
      setShowPremiumFeatureDialog(true);
      return;
    }

    if (!hasMultipleLocations) return;

    setInterstitialType('Copy');
  }, [premium, hasMultipleLocations]);

  const handleSelectBeverage = (beverage) => {
    onSelect(beverage);
  };

  return (
    <>
      {!searchType && (
        <div className="font-['Public_Sans',sans-serif] border-t border-gray-300">
          <div className="text-center my-6">
            <h2 className="text-center m-0 text-blackish text-md">{t('NewBeverageSelection.TypeBeverage')}</h2>
          </div>

          <div className="grid justify-center grid-cols-[155px_155px] md:grid-cols-[225px_225px] lg:grid-cols-[225px_225px_225px_225px] gap-4 mx-4">
            <Card
              variant="outlined"
              selected={interstitialType === 'Beer'}
              className="flex flex-col items-center justify-center text-center cursor-pointer w-[155px] h-[218px] md:w-[225px] md:h-[218px]"
              onClick={() => setInterstitialType('Beer')}>
              <Card.Content className="flex flex-col items-center">
                <img src={IconBeer} alt="Beer or Cider" className="h-[75px] w-[75px]" />
                <p className="mt-6 mb-0 uppercase font-bold text-md">{t('NewBeverageSelection.BeerCider')}</p>
              </Card.Content>
            </Card>

            <Card
              variant="outlined"
              selected={interstitialType === 'Wine'}
              className="flex flex-col items-center justify-center text-center cursor-pointer w-[155px] h-[218px] md:w-[225px] md:h-[218px]"
              onClick={() => setInterstitialType('Wine')}>
              <Card.Content className="flex flex-col items-center">
                <img src={IconWine} alt="Wine" className="h-[75px] w-[75px]" />
                <p className="mt-6 mb-0 uppercase font-bold text-md">{t('NewBeverageSelection.Wine')}</p>
              </Card.Content>
            </Card>

            <Card
              variant="outlined"
              selected={interstitialType === 'Spirit'}
              className="flex flex-col items-center justify-center text-center cursor-pointer w-[155px] h-[218px] md:w-[225px] md:h-[218px]"
              onClick={() => setInterstitialType('Spirit')}>
              <Card.Content className="flex flex-col items-center">
                <img src={IconSpirit} alt="Spirit" className="h-[75px] w-[75px]" />
                <p className="max-w-[6.5ch] md:max-w-full mt-6 mb-0 uppercase font-bold text-md">
                  {t('NewBeverageSelection.SpiritCocktail')}
                </p>
              </Card.Content>
            </Card>

            <Card
              variant="outlined"
              selected={interstitialType === 'Other'}
              className="flex flex-col items-center justify-center text-center cursor-pointer w-[155px] h-[218px] md:w-[225px] md:h-[218px]"
              onClick={() => setInterstitialType('Other')}>
              <Card.Content className="flex flex-col items-center">
                <img src={IconOther} alt="Other" className="h-[75px] w-[75px]" />
                <p className="mt-6 mb-0 uppercase font-bold text-md">{t('NewBeverageSelection.Other')}</p>
              </Card.Content>
            </Card>
          </div>

          <div className="flex items-center justify-center my-8">
            <div className="border-t border-accent-grey mx-4 w-16"></div>
            <span className="text-blackish uppercase font-bold">or</span>
            <div className="border-t border-accent-grey mx-4 w-16"></div>
          </div>

          <div className="grid place-items-center px-2.5">
            <Card
              variant="outlined"
              selected={interstitialType === 'Copy'}
              className={`flex flex-col items-center justify-center text-center cursor-pointer w-[300px] h-[218px] ${
                !isCopyCardEnabled ? 'hover:border-accent-grey hover:border' : ''
              }`}
              onClick={handleCopyClick}>
              <Card.Content
                className={`flex flex-col items-center relative ${isCopyCardEnabled ? 'text-dark-greyht-green' : 'text-light-grey'}`}>
                <IconCopy className={`h-[75px] w-[75px] ${isCopyCardEnabled ? 'text-orange' : 'text-light-grey'}`} />
                <p className="whitespace-pre mt-6 mb-0 uppercase font-bold text-md ">{t('NewBeverageSelection.CopyFromExisting')}</p>
                {!premium && (
                  <p className="whitespace-pre text-golden mt-3 mb-0 uppercase font-bold text-md">
                    {t('NewBeverageSelection.CopyPremium')}
                  </p>
                )}
                {premium && !hasMultipleLocations && (
                  <p className="whitespace-pre mt-2 mb-0 text-sm ">({t('NewBeverageSelection.SecondLocationRequired')})</p>
                )}
              </Card.Content>
              {!premium && (
                <img alt="Icon Premium Feature" src={IconPremiumChip} className="absolute top-1 right-1 rotate-[30deg] w-8 h-8" />
              )}
            </Card>
          </div>

          <div className="p-4 mt-6 bg-white border-t flex justify-end">
            <Button variant="primary" disabled={!interstitialType} onClick={() => confirmType(interstitialType)}>
              {t('NewBeverageSelection.Next')}
            </Button>
          </div>

          <PremiumFeatureDialog
            show={showPremiumFeatureDialog}
            featureDescription={t('NewBeverageSelection.CopyPremiumDescription')}
            onClose={() => setShowPremiumFeatureDialog(false)}
          />
        </div>
      )}
      {searchType === 'Beer' && <BeerSearch onSelect={handleSelectBeverage} />}
    </>
  );
};
