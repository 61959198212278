import gql from 'graphql-tag';
import { TAP_FIELDS, SCREEN_FIELDS } from '../../data/graphql';

export const GET_TAPS = gql`
    query GetTaps($location: MongoID!){
        taps: tapMany(filter: { location: $location }) {
            ${TAP_FIELDS}
        }
    }
`;

export const GET_TAPS_BY_STATUS = gql`
    query GetTapsByStatus($location: MongoID!, $statuses: [EnumTapStatus]){
        taps: tapMany(filter: { location: $location, _operators: {status: {in: $statuses} }}) {
            ${TAP_FIELDS}
        }
    }
`;

export const GET_TAP_STATUS = gql`
  query GetTapStatus($screen: String!, $page: String!) {
    tapStatus(screen: $screen, page: $page) {
      tapName
      percentRemaining
      dateTapped
    }
  }
`;

export const GET_SCREEN_BY_ID = gql`
    query GetScreenById($id: MongoID!){
        screen: screenById(_id: $id) {
            ${SCREEN_FIELDS}
        }
    }
`;

export const GET_WALL_BY_SCREEN_ID = gql`
  query GetWallByScreenId($id: MongoID!) {
    screen: getWallByScreenId(_id: $id) {
        ${SCREEN_FIELDS}
    }
  }
`;
