import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';

import { Display } from './display/DisplayDevice';
import { DeviceContext } from '../../contexts/device';

import { GET_WALL_BY_SCREEN_ID } from './queries';

export const ViewScreen = ({ match }) => {
  const {
    params: { screen_id }
  } = match;
  const { device, registerDevice } = useContext(DeviceContext);
  const { data } = useQuery(GET_WALL_BY_SCREEN_ID, { variables: { id: screen_id } });

  useEffect(
    () => {
      let fakeDevice;
      if (data?.screen) {
        fakeDevice = { screen: data.screen };
        // make sure we erase any previously connected device data
        fakeDevice.screen.device = null;
        registerDevice(fakeDevice);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.screen]
  );

  return <>{device && <Display />}</>;
};
