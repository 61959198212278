import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import { MenuPrintPageProps } from './types';
import { UserVesselsProvider } from '../../../contexts/userVessels';
import { SetupPrintPageStyles, useColumns, usePrintConfigFromURLParams, useTaps } from './utils';
import { SessionContext } from '../../../contexts/session';

export interface MenuPrintDocumentProps {
  Page: React.FC<MenuPrintPageProps>;
}

const MenuPrintDocument: React.FC<MenuPrintDocumentProps> = ({ Page }) => {
  const config = usePrintConfigFromURLParams(window.location.search);
  const taps = useTaps(config);
  const { columns, onColumnRef } = useColumns(taps, config);
  const { account } = useContext(SessionContext);

  let pages = [];
  for (let i = 0; i < columns.length; i++) {
    if (i % config.columnCount === 0) {
      pages.push([Array(config.columnCount).fill(undefined)]);
    }
    let p = pages[pages.length - 1];
    p[i % config.columnCount] = columns[i];
  }

  useEffect(() => {
    if (taps.length && columns.length) {
      const timer = setTimeout(() => {
        const paramsObject = JSON.stringify(config);

        const gaLabel = `Account: ${account._id} Template: Default  Custom Params: ${paramsObject}`;

        ReactGA.event({
          category: 'print_start',
          action: 'print_start',
          label: gaLabel
        });
        // @ts-ignore
        if (typeof window.gtag === 'function') {
          // @ts-ignore
          window.gtag('event', 'print_start', {
            event_category: 'print_start',
            event_label: gaLabel
          });
        }

        window.print();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [account?._id, columns, config, taps]);

  if (!config.premium) {
    return null;
  }

  return (
    <UserVesselsProvider>
      {pages.map((page, i) => (
        <Page key={i} config={config} columns={page} onColumnRef={(el, colIndex) => onColumnRef(el, i, colIndex)} />
      ))}
      <SetupPrintPageStyles />
    </UserVesselsProvider>
  );
};

export default MenuPrintDocument;
