import React, { useState } from 'react';
import { Grid, Button, Slide, useTheme, useMediaQuery, Dialog, DialogContent, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ActionBar } from '../../../components/ActionBar';
import { ChromecastConnector } from './ChromecastConnector';
import { FireTvConnector } from './FireTvConnector';
import { AppleTvConnector } from './AppleTvConnector';
import { AndroidTvConnector } from './AndroidTvConnector';

import CheckedImage from '../../../images/checked.png';
import DeviceChromecastImage from '../../../images/device-chromecast.png';
import DeviceFireTvImage from '../../../images/device-firetv.png';
import DeviceAppleTvImage from '../../../images/device-appletv.png';
import DeviceAndroidTvImage from '../../../images/device-androidtv.png';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 80
  },
  pageHeading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  device: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 10px',
    textAlign: 'center',
    cursor: 'pointer',

    '& > img': {
      width: '72px',
      height: 'auto',
      marginBottom: '15px'
    },
    '& > div': {
      fontWeight: 'bold'
    }
  },
  deviceSelected: {
    position: 'relative',
    backgroundColor: '#f9f9f9',
    '&::after': {
      background: `url(${CheckedImage}) no-repeat`,
      backgroundSize: 'cover',
      content: '""',
      height: 36,
      width: 36,
      position: 'absolute',
      top: -8,
      right: -8
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ConnectDeviceDialog = ({ screen, onCancel }) => {
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const classes = useStyles();

  const [selectedDevice, setSelectedDevice] = useState('');
  const [connectMode, setConnectMode] = useState('');

  const handleNext = () => {
    setConnectMode(selectedDevice);
  };

  return (
    <Dialog fullWidth open TransitionComponent={Transition} keepMounted fullScreen={fullScreen} onClose={onCancel}>
      <DialogContent>
        <Grid container spacing={2} className={classes.root}>
          {!connectMode && (
            <>
              <Grid item xs={2}>
                <ArrowBack onClick={onCancel} />
              </Grid>
              <Grid item xs={8} className={classes.pageHeading}></Grid>

              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <h2 style={{ textAlign: 'center', margin: '0 0 10px 0' }}>{t('DeviceDialog.WhatDevice')}</h2>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Paper
                  variant="outlined"
                  onClick={() => setSelectedDevice('chromecast')}
                  className={clsx(classes.device, selectedDevice === 'chromecast' ? classes.deviceSelected : null)}>
                  <img src={DeviceChromecastImage} alt="Chromecast" />
                  <div>{t('DeviceDialog.Chromecast')}</div>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Paper
                  variant="outlined"
                  onClick={() => setSelectedDevice('firetv')}
                  className={clsx(classes.device, selectedDevice === 'firetv' ? classes.deviceSelected : null)}>
                  <img src={DeviceFireTvImage} alt="Fire TV" />
                  <div>{t('DeviceDialog.FireTv')}</div>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Paper
                  variant="outlined"
                  onClick={() => setSelectedDevice('androidtv')}
                  className={clsx(classes.device, selectedDevice === 'androidtv' ? classes.deviceSelected : null)}>
                  <img src={DeviceAndroidTvImage} alt="Android TV" />
                  <div>{t('DeviceDialog.AndroidTv')}</div>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Paper
                  variant="outlined"
                  onClick={() => setSelectedDevice('appletv')}
                  className={clsx(classes.device, selectedDevice === 'appletv' ? classes.deviceSelected : null)}>
                  <img src={DeviceAppleTvImage} alt="Apple TV" />
                  <div>{t('DeviceDialog.AppleTv')}</div>
                </Paper>
              </Grid>
              <ActionBar dialog={1}>
                <Button fullWidth variant="contained" color="primary" onClick={handleNext}>
                  {t('DeviceDialog.Next')}
                </Button>
              </ActionBar>
            </>
          )}
          {connectMode === 'chromecast' && <ChromecastConnector screen={screen} onCancel={onCancel} />}
          {connectMode === 'firetv' && <FireTvConnector screen={screen} onCancel={onCancel} />}
          {connectMode === 'androidtv' && <AndroidTvConnector screen={screen} onCancel={onCancel} />}
          {connectMode === 'appletv' && <AppleTvConnector screen={screen} onCancel={onCancel} />}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
