import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: '10px',
    cursor: 'pointer',
    height: 155
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  number: {
    marginRight: 15
  },
  itemHandle: {
    display: 'inline-block',
    border: '1px solid #DDD',
    borderRadius: '100%',
    height: '40px',
    width: '40px',
    lineHeight: '38px',
    textAlign: 'center',
    fontSize: '13px',
    marginTop: '4px'
  },
  itemName: {
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '1.45',
    marginBottom: '5px'
  },
  itemMetaPrimary: {
    fontFamily: ['Unica One'],
    textTransform: 'uppercase',
    lineHeight: 1.2,
    fontSize: '20px'
  },
  itemMetaSecondary: {
    fontSize: '18px',
    lineHeight: '1.2',
    fontFamily: '"Unica One", Roboto, sans-serif',
    textTransform: 'uppercase',
    opacity: '0.7'
  }
}));

export const PageCard = ({ page, onEdit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.container} variant="outlined" onClick={onEdit}>
      <CardContent>
        <div className={classes.content}>
          <div className={classes.number}>
            <span className={classes.itemHandle}>{page.number || '?'}</span>
          </div>
          <div className={classes.item}>
            <div className={classes.itemName}>{page.name}</div>
            {page.type === 'taps' && (
              <div>
                <span className={classes.itemMetaPrimary}>
                  {t('PageCard.Taps')} ({page.tapStart || 1} - {page.tapEnd})
                </span>
              </div>
            )}
            {page.type === 'image' && (
              <div>
                <span className={classes.itemMetaPrimary}>{t('PageCard.CustomImage')}</span>
              </div>
            )}
            <div className={classes.itemMetaSecondary}>
              {page.rotationDuration} {t('PageCard.Seconds')}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
