import { createContext, useEffect, useMemo, useState } from 'react';

import useLocalStorage from './localStorage';
import { useApolloClient, useQuery } from 'react-apollo';
import { GET_ACCOUNT_BY_USER, GET_LOCATIONS_BY_ACCOUNT, GET_USER } from './queries';

interface Session {
  user: any;
  account: any;
  accountRefetch: () => Promise<any>;
  locations: Array<any>;
  setLocations: (locations: any[]) => void;
  token: string;
  premium: boolean;
  login: (user: any, token: string) => void;
  logout: () => void;
  setUser: (user: any) => void;
  currentLocation: any;
  setCurrentLocation: (location: any) => void;
  locLoading: boolean;
  locRefetch: () => Promise<any>;
  isOwner: boolean;
}

export const SessionContext = createContext({} as Session);

export const useSession = () => {
  const [user, setUser] = useLocalStorage('user', null);
  const [currentLocation, setCurrentLocation] = useLocalStorage('currentLocation', null);
  const [token, setToken] = useLocalStorage('token', null);
  const [premium, setPremium] = useLocalStorage('premium', false);
  const [account, setAccount] = useState<any>(null);
  const client = useApolloClient();

  const [locations, setLocations] = useState(null);

  // make sure we get a fresh version of the user object
  // TODO i can't seem to get rid of the cached data
  // so when someone logs into a different account in the same browser the old data is still there
  // TODO: maybe this should be a GET_PROFILE query that uses the auth token instead of the user ID?
  const { data } = useQuery(GET_USER, {
    variables: { id: user?._id },
    fetchPolicy: 'cache-and-network',
    skip: !user?._id
  });

  const { data: accountData, refetch: accountRefetch } = useQuery(GET_ACCOUNT_BY_USER, {
    variables: { id: user?._id },
    fetchPolicy: 'cache-and-network',
    skip: !user?._id
  });

  const {
    data: locationData,
    loading: locLoading,
    refetch: locRefetch
  } = useQuery(GET_LOCATIONS_BY_ACCOUNT, {
    variables: { id: accountData?.account?._id },
    fetchPolicy: 'cache-and-network',
    skip: !accountData?.account?._id
  });

  const isOwner = useMemo<boolean>(
    () =>
      accountData?.account?.users.some((u: any) => {
        return u.user._id === user?._id && u.role === 'owner';
      }),
    [accountData?.account?.users, user?._id]
  );

  useEffect(() => {
    if (locationData?.locations) {
      setLocations(locationData?.locations.filter((location: any) => location?.active));
      if (!currentLocation) {
        setCurrentLocation(locationData?.locations?.[0]);
      } else {
        const location = locationData?.locations?.find((location: any) => location?._id === currentLocation?._id);

        if (location) setCurrentLocation(location);
        else setCurrentLocation(locationData?.locations?.[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData?.locations]);

  if (data?.user?.dateUpdated && data?.user?.dateUpdated !== user?.dateUpdated) {
    setUser(data.user);
  }

  if (accountData?.account?.dateUpdated && accountData?.account?.dateUpdated !== account?.dateUpdated) {
    setAccount(accountData.account);
    if (
      accountData?.account?.plan === 'premium' &&
      (accountData?.account?.status === 'active' || accountData?.account?.status === 'trialing')
    ) {
      setPremium(true);
    } else {
      setPremium(false);
    }
  }

  const login = (user: any, token: string) => {
    //client.resetStore();
    setUser(user);
    setToken(token);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    setAccount(null);
    setCurrentLocation(null);
    client.resetStore();
  };

  return {
    user,
    account,
    accountRefetch,
    locations,
    locLoading,
    locRefetch,
    token,
    isOwner,
    login,
    logout,
    setUser,
    premium,
    currentLocation,
    setCurrentLocation
  };
};
