import { makeStyles } from '@material-ui/core';
import TapHeaderUnderline from '../../../images/tap-header-underline';

const ClassicSessionHeader: React.FC<{ groupName: string; theme: any }> = ({ groupName, theme }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h1 className={classes.groupName} style={{ color: theme.primary }}>
        {groupName}
      </h1>
      <TapHeaderUnderline className={classes.sessionHeaderUnderline} color={theme.secondary} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  sessionHeaderUnderline: {
    position: 'absolute',
    bottom: -5
  },
  container: {
    position: 'relative',
    marginBottom: 5
  },
  groupName: {
    fontSize: 18,
    marginLeft: 8,
    zIndex: 1,
    position: 'relative',
    fontFamily: 'Bitter',
    textTransform: 'uppercase'
  }
}));

export default ClassicSessionHeader;
