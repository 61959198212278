import { DEFAULT_CURRENCY } from './currencies';
import { DEFAULT_VOLUME_UNIT, volumeUnitConverter } from './volumeUnits';

const VESSEL_TYPES = {
  BEER: 'Beer',
  WINE: 'Wine',
  SPIRITS: 'Spirits'
};

/*
 *
 * Note:
 *  We are considering that the default value for vessels is measured in ounces (oz).
 *
 */
export const Vessels = [
  { type: VESSEL_TYPES.BEER, name: 'Pint', size: 16 },
  { type: VESSEL_TYPES.BEER, name: 'Taster', size: 3 },
  {
    type: VESSEL_TYPES.BEER,
    name: '1/2 Pint',
    size: 8
  },
  {
    type: VESSEL_TYPES.BEER,
    name: '1/3 Pint',
    size: 5.333
  },
  {
    type: VESSEL_TYPES.BEER,
    name: '2/3 Pint',
    size: 10.666
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Imperial',
    size: 20
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Growler',
    size: 64
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Half Growler',
    size: 32
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Crowler',
    size: 32
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Pitcher',
    size: 64
  },
  { type: VESSEL_TYPES.BEER, name: 'Tulip', size: 10 },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Snifter',
    size: 10
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Schooner',
    size: 10
  },
  { type: VESSEL_TYPES.BEER, name: 'Bottle', size: 0 },
  { type: VESSEL_TYPES.BEER, name: 'Mug', size: 24 },
  { type: VESSEL_TYPES.BEER, name: 'Can', size: 0 },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Pour',
    size: 0
  },
  {
    type: VESSEL_TYPES.BEER,
    name: '1/2 Keg',
    size: 1984
  },
  {
    type: VESSEL_TYPES.BEER,
    name: '1/4 Keg',
    size: 992
  },
  {
    type: VESSEL_TYPES.BEER,
    name: '1/6 Keg',
    size: 660
  },
  {
    type: VESSEL_TYPES.BEER,
    name: 'Keg',
    size: 3968
  },
  { type: VESSEL_TYPES.WINE, name: 'Glass', size: 5 },
  { type: VESSEL_TYPES.WINE, name: 'Taste', size: 3 },
  {
    type: VESSEL_TYPES.SPIRITS,
    name: 'Single',
    size: 1.5
  },
  {
    type: VESSEL_TYPES.SPIRITS,
    name: 'Double',
    size: 3
  }
];

export const BeerVessels = Vessels.filter(({ type }) => type === VESSEL_TYPES.BEER);
export const WineVessels = Vessels.filter(({ type }) => type === VESSEL_TYPES.WINE);
export const SpiritsVessels = Vessels.filter(({ type }) => type === VESSEL_TYPES.SPIRITS);

export const VESSEL_PER_NAME = Vessels.reduce((mappedVessels, vessel) => {
  mappedVessels[vessel.name] = vessel;
  return mappedVessels;
}, {});

export const getServingSettings = (serving) => serving.settings || {};

export const getServingSizeLabel = (serving) => {
  const { volumeUnit = DEFAULT_VOLUME_UNIT } = getServingSettings(serving);
  return `${new Intl.NumberFormat().format(serving.size)} ${volumeUnit}`;
};

export const getServingPriceLabel = (serving, showCurrency = false) => {
  if (!showCurrency) return serving.price;
  const { currency } = getServingSettings(serving);
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency ?? DEFAULT_CURRENCY,
    currencyDisplay: 'narrowSymbol'
  }).format(serving.price);
};

export const getVesselLabel = ({ name = '', size = 0 }, volumeUnit = DEFAULT_VOLUME_UNIT) => {
  const type = VESSEL_PER_NAME[name] ? VESSEL_PER_NAME[name].type : 'Custom';
  const unitLabel = size ? ` (${new Intl.NumberFormat().format(resolveVesselSize({ name, size, type }, volumeUnit))} ${volumeUnit})` : '';
  return `${name}${unitLabel}`;
};

export const resolveVesselName = (vesselValue, userVessels = []) => {
  return VESSEL_PER_NAME[vesselValue]?.name || userVessels.find(({ name }) => name === vesselValue)?.name || vesselValue;
};

export const resolveVesselSize = ({ name, size, type }, volumeUnit = DEFAULT_VOLUME_UNIT) => {
  const vessel = type === 'Custom' ? { size } : VESSEL_PER_NAME[name];
  return vessel ? volumeUnitConverter(volumeUnit, vessel.size) : null;
};
