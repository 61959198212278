import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { SessionContext } from '../../contexts/session';

export const Logout = () => {
  const { logout, user, token } = useContext(SessionContext);

  useEffect(() => {
    logout();
  }, [logout]);

  if (!user && !token) return <Redirect to="/login" />;

  return null;
};
