import React, { useState, useContext, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Slide,
  Dialog,
  DialogContent,
  useMediaQuery,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import Slider from 'react-slick';
import { Cast } from '@material-ui/icons';
import { find, without, sortBy } from 'lodash';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../contexts/session';
import { LightGrey } from '../../theme';

import { PageCard } from './PageCard';
import { PageEditForm } from './pages/PageEditForm';
import { ActionBar } from '../../components/ActionBar';

import { UPDATE_SCREEN, CREATE_SCREEN, DELETE_SCREEN } from './mutations';
import { cleanCachedObjectForMutation, spreadMutations, getMessageFromError } from '../../util/graphql';
import { ConnectDeviceDialog } from './connect/ConnectDeviceDialog';
import { FeedbackBar } from '../../components/FeedbackBar';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    paddingBottom: 80
  },
  formContainer: {
    '& h2': {
      marginTop: 0
    }
  },
  pagesContainer: {
    paddingTop: '20px',
    '& .slick-track': {
      marginLeft: 0
    }
  },

  infoAlert: {
    '& svg': {
      height: 14,
      width: 'auto'
    },
    '& > p': {
      margin: 0,
      padding: 0
    },
    '& p:nth-child(2)': {
      marginTop: 10
    }
  },
  selectMenu: {
    border: `1px solid ${LightGrey}`
  },

  deleteButton: {
    marginLeft: 20
  },
  saveButton: {
    flexGrow: 1,
    marginLeft: '20px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ScreenEditForm = withRouter(({ history, screen, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, currentLocation } = useContext(SessionContext);

  const [errorMessage, setErrorMessage] = useState();

  const [name, setName] = useState(screen.name);
  const [pages, setPages] = useState(screen.pages || []);
  const [editPage, setEditPage] = useState(null);
  const [orientation, setOrientation] = useState(screen.orientation || 'landscape_0');

  const [cacheLocation, setCacheLocation] = useState(currentLocation);

  const [connect, setConnect] = useState(false);

  const [[createScreen, saveScreen, deleteScreen], { loading }] = spreadMutations([
    useMutation(CREATE_SCREEN),
    useMutation(UPDATE_SCREEN),
    useMutation(DELETE_SCREEN)
  ]);

  useEffect(() => {
    if (cacheLocation !== currentLocation) {
      setCacheLocation(currentLocation);
      history.push(`/screens`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, cacheLocation]);

  const addPage = () => {
    let page = {
      name: 'New Page',
      rotationDuration: 10,
      key: shortid.generate(),
      number: 1
    };

    const numbers = (pages || []).map((s) => s.number);
    if (numbers.length > 0) {
      let [min, max] = [Math.min(...numbers), Math.max(...numbers)];
      let out = Array.from(Array(max - min), (v, i) => i + min).filter((i) => !numbers.includes(i));
      if (out.length > 0) page.number = out[0];
      else page.number = max + 1;
    }

    setEditPage(page);
  };

  const handleSavePage = async (page) => {
    let allPages = [...pages];
    let matchedPage = find(allPages, (p) => p.key === page.key);
    if (!matchedPage) allPages.push(page);
    else Object.assign(matchedPage, page);

    setPages(allPages);
    setEditPage(null);
  };

  const handleDeletePage = async () => {
    if (editPage && editPage.key) {
      let allPages = [...pages];
      let matchedPage = find(allPages, (p) => p.key === editPage.key);
      if (matchedPage) {
        allPages = without(allPages, matchedPage);
        setPages(allPages);
      }
    }
    setEditPage(null);
  };

  const handleSave = async (close) => {
    // remove all the __typename properties since our mutation won't like them
    let cleanScreen = cleanCachedObjectForMutation({
      ...screen,
      name,
      orientation,
      pages
    });

    cleanScreen = Object.assign({ createdBy: user._id, location: currentLocation?._id }, cleanScreen);

    // this seems messy
    if (cleanScreen.device && typeof cleanScreen.device === 'object') {
      cleanScreen.device = cleanScreen.device._id;
    }

    try {
      if (cleanScreen._id) {
        await saveScreen({ variables: { screen: cleanScreen } });
      } else {
        let {
          data: {
            screenCreateOne: { record }
          }
        } = await createScreen({ variables: { screen: cleanScreen } });
        history.push(`/screens/${record._id}/edit`);
      }
    } catch (e) {
      setErrorMessage(getMessageFromError(e));
      console.log(e);
    }
  };

  const handleDelete = async () => {
    await deleteScreen({ variables: { id: screen._id } });
    onClose();
  };

  const handlePreview = () => {
    const newWindow = window.open('', '_blank');
    handleSave()
      .then(() => {
        newWindow.location.href = `/screens/${screen?._id}/preview`;
      })
      .catch((error) => {
        newWindow.close();
        console.error('Error while saving:', error);
      });
  };

  const sliderSettings = {
    className: 'slider',
    infinite: false,
    centerMode: false,
    accessibility: false,
    arrows: false,
    centerPadding: '0px',
    slidesToShow: 1.5,
    slidesToScroll: 1
  };

  const handleConnect = async () => {
    await handleSave(false);
    setConnect(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.root}>
          <FeedbackBar message={errorMessage} />
          <Grid container className={classes.formContainer} spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info" style={{ marginBottom: 15 }}>
                <div className={classes.infoAlert}>
                  {t('ScreenEdit.AddingPage')} <Cast /> {t('ScreenEdit.ButtonBelow')}
                </div>
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2">{t('ScreenEdit.Basics')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField variant="outlined" label={t('ScreenEdit.Name')} value={name} onChange={(e) => setName(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">{t('ScreenEdit.Orientation')}</InputLabel>
                <Select
                  MenuProps={{ classes: { paper: classes.selectMenu } }}
                  value={orientation}
                  label={t('ScreenEdit.Orientation')}
                  onChange={(e) => setOrientation(e.target.value)}>
                  <MenuItem value={'landscape_0'}>Landscape (default)</MenuItem>
                  <MenuItem value={'landscape_180'}>{t('ScreenEdit.LandscapeReversed')}</MenuItem>
                  <MenuItem value={'portrait_90'}>Portrait</MenuItem>
                  <MenuItem value={'portrait_270'}>{t('ScreenEdit.PortraitReversed')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.pagesContainer}>
            <Grid item xs={6}>
              <Typography variant="h2">{t('ScreenEdit.PagesTitle')}</Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={addPage} style={{ marginBottom: 10 }}>
                {t('ScreenEdit.AddPage')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Hidden smUp>
                <Slider {...sliderSettings}>
                  {sortBy(pages, (p) => p.number).map((p, i) => (
                    <PageCard key={i} page={p} onEdit={() => setEditPage(p)} />
                  ))}
                </Slider>
              </Hidden>
              <Hidden xsDown>
                <Grid container spacing={2}>
                  {sortBy(pages, (p) => p.number).map((p, i) => (
                    <Grid item xs={6} md={4} key={i}>
                      <PageCard key={i} page={p} onEdit={() => setEditPage(p)} />
                    </Grid>
                  ))}
                </Grid>
              </Hidden>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 15 }}>
              <Alert severity="info">
                <div className={classes.infoAlert}>
                  <p>{t('ScreenEdit.Pages')}</p>
                  {pages.length === 0 && <p>{t('ScreenEdit.AddPages')}</p>}
                </div>
              </Alert>
            </Grid>
          </Grid>
          <EditPageDialog
            screen={screen}
            page={editPage}
            onCancel={() => setEditPage(null)}
            onSave={handleSavePage}
            onDelete={handleDeletePage}
          />
          {connect && <ConnectDeviceDialog screen={screen} onCancel={() => setConnect(false)} />}
        </Grid>
      </Grid>
      <ActionBar loading={loading}>
        <Button variant="outlined" color="default" disabled={!screen._id} onClick={handleConnect}>
          <Cast />
        </Button>
        <Hidden smDown>
          <Button
            variant="outlined"
            color="default"
            disabled={!screen._id || pages?.length === 0}
            className={classes.deleteButton}
            onClick={handlePreview}>
            {t('ScreenEdit.Preview')}
          </Button>
        </Hidden>
        <Button variant="contained" color="secondary" disabled={!screen._id} className={classes.deleteButton} onClick={handleDelete}>
          {t('ScreenEdit.Delete')}
        </Button>
        <Button variant="contained" color="primary" disabled={loading} className={classes.saveButton} onClick={() => handleSave(true)}>
          {t('ScreenEdit.Save')}
        </Button>
      </ActionBar>
    </>
  );
});

const EditPageDialog = ({ screen, page, onCancel, onSave, onDelete }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={page !== null} TransitionComponent={Transition} keepMounted fullScreen={fullScreen} onClose={onCancel}>
      <DialogContent>
        {page && <PageEditForm screen={screen} page={page} onCancel={onCancel} onSave={onSave} onDelete={onDelete} />}
      </DialogContent>
    </Dialog>
  );
};
