import { createTheme } from '@material-ui/core/styles';

export const Black = '#251D1D';
export const White = '#ffffff';
export const OffWhite = '#EBEBEB';
export const LightGrey = '#D3D3D3';
export const MediumGrey = '#959A9C';
export const DarkGrey = '#727779';
export const DarkSlateGrey = '#3c3c3c';
export const MediumSlateGrey = '#8A8A8A';
export const Darkblue = '#35495D';

export const PremiumYellow = '#F9B91E';

export const Red = '#FD5C36';
export const Orange = '#FD5C36';
export const DarkOrange = '#CA3D1C';
export const Green = '#37DEA9';
export const DarkGreen = '#124736';

export const BackgroundColor = '#F0F4F6';

export const SelectedBoxBackgroundColor = '#f9f9f9';

export const DisplayBackgroundColor = '#4C4C4C';

export const FontFamilyOpenSans = 'Open Sans, sans-serif';
export const FontFamilyUnicaOne = 'Unica One, sans-serif';
export const FontFamilyOswald = 'Oswald, sans-serif';
export const FontFamilyInter = 'Inter, sans-serif';
export const FontFamilyPublicSans = 'Public Sans, sans-serif';
export const FontFamilyPublicSansItalic = 'Public Sans Italic, sans-serif';

export const theme = createTheme({
  palette: {
    primary: {
      main: Green,
      contrastText: DarkGreen
    },
    secondary: {
      main: Orange,
      contrastText: White
    },
    info: {
      main: Green
    }
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      margin: 0,
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: FontFamilyUnicaOne,
      fontSize: '1.2rem',
      fontWeight: 'bold',
      margin: '10px 0',
      textTransform: 'uppercase'
    },
    h3: {
      fontFamily: FontFamilyUnicaOne,
      fontSize: '1rem',
      fontWeight: 'bold',
      margin: 0,
      textTransform: 'uppercase',
      opacity: 0.7
    },
    button: {
      fontWeight: 'bold'
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none'
  ]
});
