import { Checkbox, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useState } from 'react';

type CheckedLocationsType = {
  [key: string]: boolean;
};

type TLocations = { _id: string; name: string }[];

export const useMemberLocationList = (locations: TLocations, assignedLocations: string[]) => {
  const [checkedLocations, setCheckedLocation] = useState<CheckedLocationsType>(() =>
    locations?.reduce((acc, { _id }) => {
      acc[_id] = assignedLocations.includes(_id);
      return acc;
    }, {} as CheckedLocationsType)
  );

  const toggleCheck = (_id: string) => {
    setCheckedLocation((prevState) => {
      return { ...prevState, [_id]: !prevState?.[_id] };
    });
  };

  const asArray = () => Object.keys(checkedLocations).filter((key) => checkedLocations[key]);

  const MemberLocationListComponent = () => (
    <LocationsMemberAssignList locations={locations} checkedLocations={checkedLocations} onCheck={toggleCheck} />
  );
  return {
    checkedLocations: { ...checkedLocations, asArray },
    onCheck: toggleCheck,
    MemberLocationListComponent
  };
};

function LocationsMemberAssignList({
  locations,
  checkedLocations,
  onCheck
}: {
  locations: TLocations;
  checkedLocations: CheckedLocationsType;
  onCheck: (event: any) => void;
}) {
  return (
    <Table>
      <TableBody>
        {locations?.map(({ _id, name }: { _id: string; name: string }) => (
          <TableRow key={_id}>
            <TableCell>{name}</TableCell>
            <TableCell align="right" component="th" scope="row">
              <Checkbox color="primary" checked={checkedLocations?.[_id]} onChange={() => onCheck(_id)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
