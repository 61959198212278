import { groupBy, sortBy } from 'lodash';
import { MenuPrintConfig, MenuPrintConfigGroup, MenuPrintConfigSort } from './types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { GET_BEVERAGE_CATEGORIES, GET_TAPS_BY_STATUS } from '../queries';
import { SessionContext } from '../../../contexts/session';
import { getFilteredTapList } from '../../../util/tap';
import { CLASSIC_HEADER_TYPE, MENU_TYPE, VERTICAL_HEADER_TYPE } from './enums';

export const groupTaps = (tapList: any[] = [], groupByParam: MenuPrintConfigGroup, defaultGroup?: string) => {
  switch (groupByParam) {
    case 'category':
      return groupBy(tapList, (i) => i?.beverage?.category || defaultGroup);
    case 'brewery':
      return groupBy(tapList, (i) => i?.beverage?.source?.name || defaultGroup);
    case 'none':
      return groupBy(tapList, () => '');
    case 'style':
    default: //Group by style
      return groupBy(tapList, (i) => i?.beverage?.style || defaultGroup);
  }
};

export const getGroupName = (tap: any, groupByParam?: MenuPrintConfigGroup, defaultGroup?: string) => {
  switch (groupByParam) {
    case 'category':
      return tap?.beverage?.category || defaultGroup;
    case 'brewery':
      return tap?.beverage?.source?.name || defaultGroup;
    case 'style':
    default: //Group by style
      return tap?.beverage?.style || defaultGroup;
  }
};

export const sortTaps = (tapList: any[] = [], sortingParam?: MenuPrintConfigSort) => {
  switch (sortingParam) {
    case 'abv':
      return sortBy(tapList, (t) => t.beverage?.abv);
    case 'style':
      return sortBy(tapList, (t) => t.beverage?.style);
    case 'category':
      return sortBy(tapList, (t) => t.beverage?.category);
    case 'breweryName':
      return sortBy(tapList, (t) => t.beverage?.source?.name);
    case 'beverageName':
      return sortBy(tapList, (t) => t.beverage?.name);
    case 'handle':
    default:
      return sortBy(tapList, (t) => t.name);
  }
};

export interface MenuPrintTap {
  groupName?: string;
  taps: any[];
}

export const useTaps = (config: MenuPrintConfig): MenuPrintTap[] => {
  const { currentLocation } = useContext(SessionContext);
  const { data: beverageCategoryData } = useQuery(GET_BEVERAGE_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });
  const { t } = useTranslation();

  const { data: tapsData } = useQuery(GET_TAPS_BY_STATUS, {
    variables: { location: currentLocation?._id, statuses: ['ACTIVE'] }, // TODO: Get taps on deck?
    fetchPolicy: 'cache-and-network'
  });

  const taps: MenuPrintTap[] = useMemo(() => {
    const hasData = tapsData?.taps?.length > 0 && beverageCategoryData?.categories?.length > 0;
    if (!hasData) {
      return [];
    }

    const tapList = getFilteredTapList(config.tapStartIndex, config.tapEndIndex, tapsData.taps);

    if (config.groupBy) {
      const defaultGroup = t('PrintedMenu.Other');
      const groups = groupTaps(tapList, config.groupBy, defaultGroup);
      const entries = Object.entries(groups);
      const groupsOrdered =
        config.groupBy !== 'none' ? config.groupsOrder?.map((position) => entries[parseInt(position)]) ?? entries : entries;

      return groupsOrdered?.map(([groupName, value]) => ({
        groupName,
        taps: sortTaps(value, config.sortBy)
      }));
    } else {
      return [
        {
          taps: sortTaps(tapList, config.sortBy)
        }
      ];
    }
  }, [tapsData?.taps, beverageCategoryData?.categories, t, config]);

  return taps;
};

export const useColumns = (taps: MenuPrintTap[], config: MenuPrintConfig) => {
  const [columns, setColumns] = useState<any[][]>([[]]);
  const columnRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    setColumns([taps.flatMap((t) => [!!t.groupName && { groupName: t.groupName }, ...t.taps]).filter((t) => !!t)]);
  }, [taps]);

  useEffect(() => {
    for (let i = 0; i < columnRefs.current.length; i++) {
      let colRef = columnRefs.current[i];
      let taps = columns[i];

      if (isOverflown(colRef)) {
        let lastTap = taps.pop();
        let updatedColumns = [...columns];

        if (!updatedColumns[i + 1]) {
          updatedColumns[i + 1] = [];
        }
        updatedColumns[i + 1].unshift(lastTap);

        // see if the previous item is a group heading, and if so, move it over too so we don't have an orphan
        if (taps[taps.length - 1]?.groupName) {
          let group = taps.pop();
          updatedColumns[i + 1].unshift(group);
        }

        setColumns(updatedColumns);
      }
    }
  }, [columns]);

  const onColumnRef = (el: HTMLDivElement | null, pageIndex: number, colIndex: number) => {
    if (el) {
      columnRefs.current[pageIndex * config.columnCount + colIndex] = el;
    }
  };

  return {
    columns,
    onColumnRef
  };
};

export const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: HTMLDivElement) => {
  return scrollHeight > clientHeight;
};

export const usePrintConfigFromURLParams = (params: string): MenuPrintConfig => {
  const { currentLocation, premium } = useContext(SessionContext);

  return useMemo(() => {
    const settings = new URLSearchParams(params);
    return {
      theme: {
        primary: settings.get('primaryColor') ?? '#000000',
        secondary: settings.get('secondaryColor') ?? '#ffffff',
        primaryText: settings.get('primaryTextColor') ?? '#ffffff',
        secondaryText: settings.get('secondaryTextColor') ?? '#000000',
        dash: settings.get('dashColor') ?? '#000000'
      },
      menuType: (settings.get('menuType') as MENU_TYPE) ?? MENU_TYPE.CLASSIC,
      title: settings.get('title') ?? '',
      headerType: (settings.get('headerType') as CLASSIC_HEADER_TYPE | VERTICAL_HEADER_TYPE) ?? undefined,
      headerImageUrl: settings.get('headerImageUrl') ?? '',
      footerImageUrl: settings.get('footerImageUrl') ?? '',
      columnCount: parseInt(settings.get('columns') ?? '2', 10),
      currentLocation,
      premium,
      footer: {
        date: settings.get('footerDate') ?? undefined,
        location: settings.get('footerLocation') ?? undefined,
        website: settings.get('footerWebsite') ?? undefined
      },
      showDescription: settings.get('showDescription') === 'true',
      showTapLocation: settings.get('showLocation') === 'true',
      showHandle: settings.get('showHandle') === 'true',
      showBreweryName: settings.get('showBreweryName') === 'true',
      showServings: settings.get('showServings') === 'true',
      showServingSize: settings.get('showServingSize') === 'true',
      showServingCurrency: settings.get('showServingCurrency') === 'true',
      showBeerStyle: settings.get('showBeerStyle') === 'true',
      sortBy: settings.get('sortBy') as MenuPrintConfigSort,
      groupBy: settings.get('groupBy') as MenuPrintConfigGroup,
      tapStartIndex: parseInt(settings.get('tapStart') ?? '1', 10),
      tapEndIndex: parseInt(settings.get('tapEnd') ?? '1', 10),
      groupsOrder: settings.get('groupsOrder')?.split(',') ?? []
    };
  }, [params, currentLocation, premium]);
};

export const SetupPrintPageStyles: React.FC = () => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
              body {
                  background: white !important;
                  -webkit-print-color-adjust: exact !important;
              }
              @media print
              {
                div {
                  break-inside: avoid;
                  page-break-inside: avoid;
                }
                @page {
                  margin: 0;
                    /* change the margins as you want them to be. */
                  size: 161.925mm 209.55mm;
                }
              }
          `
      }}></style>
  );
};
