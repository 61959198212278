import React from 'react';
import { Skeleton } from '@material-ui/lab';

export const Loading = (props) => {
  return (
    <div {...props}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  );
};
