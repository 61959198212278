import color from 'color';
import { find, groupBy, partition, sortBy } from 'lodash';
import { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { Container, Grid, makeStyles } from '@material-ui/core';
import { getServingPriceLabel, getServingSizeLabel, resolveVesselName } from '../../util/lang';
import { getFilteredTapList, getTapLocation } from '../../util/tap';
import { GET_TAPS_BY_STATUS } from './queries';

import { useTranslation } from 'react-i18next';
import { UserVesselsContext, UserVesselsProvider } from '../../contexts/userVessels';
import './display/styles.css';
import { useIsPremiumByLocation } from '../../hooks/useIsPremiumByLocation';

const getSettings = (searchParams) => {
  let settings = {};
  for (const [key, value] of searchParams.entries()) {
    if (value === 'true') settings[key] = true;
    else if (value === 'false') settings[key] = false;
    else if (!isNaN(Number(value))) settings[key] = Number(value);
    else settings[key] = value;

    if (key.indexOf('Color') > 0 && value && value.indexOf('#') < 0) {
      settings[key] = `#${value}`;
    }
  }
  return settings;
};

export const EmbededTapList = withRouter(({ match }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const locationId = match.params?.locationId;

  const settings = getSettings(new URLSearchParams(match.params.settings));

  const { premium, loading: loadingPremiumCheck } = useIsPremiumByLocation(locationId);

  const theme = {
    showTapNumber: true,
    showServings: true,
    ...settings
  };

  const [taps] = useState([]);
  const [{ activeTaps, onDeckTaps }, setPartitionedTaps] = useState({
    activeTaps: [],
    onDeckTaps: []
  });

  const { data } = useQuery(GET_TAPS_BY_STATUS, {
    variables: { location: locationId, statuses: ['ACTIVE', 'ON_DECK'] },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data?.taps?.length > 0) {
      const [active, onDeck] = partition(data.taps, (t) => t.status === 'ACTIVE');

      const filteredActiveTaps = getFilteredTapList(settings.tapStart, settings.tapEnd, active);

      let groupedActiveTaps = {};

      switch (theme.groupTapsBy) {
        case 'style':
          groupedActiveTaps = groupBy(filteredActiveTaps, (i) => i?.beverage?.style || t('EmbedPreview.Other'));
          break;
        case 'category':
          groupedActiveTaps = groupBy(filteredActiveTaps, (i) => i?.beverage?.category || t('EmbedPreview.Other'));
          break;
        case 'brewery':
          groupedActiveTaps = groupBy(filteredActiveTaps, (i) => i?.beverage?.source?.name || t('EmbedPreview.Other'));
          break;
        default:
          groupedActiveTaps = { 'Active Taps': filteredActiveTaps };
          break;
      }

      let sortedAndGroupedActiveTaps = [];
      Object.entries(groupedActiveTaps).forEach(([, tapsInGroup]) => {
        let sortFn;
        switch (theme.sortTapsBy) {
          case 'handle':
            sortFn = (t) => t?.name;
            break;
          case 'abv':
            sortFn = (t) => t?.beverage?.abv;
            break;
          case 'style':
            sortFn = (t) => t?.beverage?.style;
            break;
          case 'category':
            sortFn = (t) => t?.beverage?.category;
            break;
          case 'breweryName':
            sortFn = (t) => t?.beverage?.source?.name;
            break;
          case 'beverageName':
            sortFn = (t) => t?.beverage?.name;
            break;
          default:
            //Sort by handle
            sortFn = (t) => t?.name;
            break;
        }

        const sortedGroup = sortBy(tapsInGroup, sortFn);
        sortedAndGroupedActiveTaps.push(sortedGroup);
      });

      let sortedOnDeckList = onDeck;

      setPartitionedTaps({ activeTaps: sortedAndGroupedActiveTaps, onDeckTaps: sortedOnDeckList });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function getHeaderTitle(groupTapsBy, tap) {
    switch (groupTapsBy) {
      case 'style':
        return tap.beverage?.style;
      case 'category':
        return tap.beverage?.category;
      case 'brewery':
        return tap.beverage?.source?.name;
      default:
        return '';
    }
  }

  const containerStyle = {
    backgroundColor: theme.backgroundColor || '#fff',
    minHeight: '100%',
    fontSize: theme.fontSize
  };

  let contrastColor = color(theme.backgroundColor).isLight() ? color('black') : color('white');
  return (
    <UserVesselsProvider>
      <div style={containerStyle}>
        <Container maxWidth="md" style={containerStyle}>
          <Grid container className={[classes.widgetContainer]}>
            {loadingPremiumCheck ? (
              <h1 className={classes.noPremiumAndLoadingTitle} style={{ color: contrastColor }}>
                {t('EmbedPreview.Loading')}
              </h1>
            ) : premium ? (
              <>
                {activeTaps.map((group) => (
                  <>
                    {theme.groupTapsBy !== 'none' && (
                      <h1 className={classes.sectionTitle} style={{ fontFamily: theme.primaryFont, color: theme.foregroundColor }}>
                        {getHeaderTitle(theme.groupTapsBy, group[0])}
                      </h1>
                    )}
                    {group.map((tap) => (
                      <Tap key={tap._id} {...{ tap, taps, theme }} />
                    ))}
                  </>
                ))}
                {onDeckTaps.length > 0 && (
                  <>
                    <h1 className={classes.sectionTitle} style={{ fontFamily: theme.primaryFont, color: theme.foregroundColor }}>
                      {t('EmbedPreview.OnDeck')}
                    </h1>
                    {onDeckTaps.map((tap) => (
                      <Tap key={tap._id} {...{ tap, taps, theme }} />
                    ))}
                  </>
                )}
              </>
            ) : (
              <h1 className={classes.noPremiumAndLoadingTitle} style={{ color: contrastColor }}>
                {t('EmbedPreview.PremiumOnly')}
              </h1>
            )}
          </Grid>
        </Container>
      </div>
    </UserVesselsProvider>
  );
});

const Serving = ({ serving, theme }) => {
  const classes = useStyles();
  const { userVessels } = useContext(UserVesselsContext);

  return (
    <Grid item key={serving._id} className={classes.itemMetaCostSingle}>
      <div className={classes.servingCost} style={{ color: theme.foregroundColor2, fontFamily: theme.primaryFont }}>
        {getServingPriceLabel(serving, theme.showServingCurrency)}
      </div>
      <div
        className={classes.servingSize}
        style={{
          color: theme.foregroundColor,
          fontFamily: theme.secondaryFont
        }}>
        <div className={classes.servingVesselType}>{resolveVesselName(serving.vessel, userVessels)}</div>
        {theme.showServingSize && <div className="vessel-size">({getServingSizeLabel(serving)})</div>}
      </div>
    </Grid>
  );
};

const Tap = ({ tap, allTaps, theme }) => {
  const classes = useStyles();

  // TODO. this should be responsive
  let itemStyle = {};

  let contrastColor = color(theme.backgroundColor).isLight() ? color('black') : color('white');

  if (theme.showStripes) {
    const altColor = contrastColor.alpha(0.1);

    let index = allTaps.indexOf(tap);
    let row = index / theme.columns;
    const isAlt = Math.floor(row) % 2 === 1;
    if (isAlt) {
      itemStyle.backgroundColor = `rgba(${altColor.red()}, ${altColor.green()}, ${altColor.blue()}, ${altColor.alpha()})`;
    }
  }

  const imageUrl = (find(tap.beverage.images, (i) => i.size === 'medium') || {}).url;

  const tapLocation = getTapLocation(tap);
  return (
    <Grid item xs={12} md={6} className={[classes.itemMeta]} key={tap._id} style={itemStyle}>
      {theme.showTapNumber && (
        <span
          className={classes.bigHandle}
          style={{
            color: theme.foregroundColor,
            fontFamily: theme.primaryFont
          }}>
          {tap.name}
        </span>
      )}

      {theme.showBeverageImage && <span className={classes.itemImage} style={{ backgroundImage: `url("${imageUrl}")` }}></span>}

      <div className={classes.itemMetaDescription}>
        <div className={classes.itemMetaPrimary}>
          <div
            className={classes.itemMetaName}
            style={{
              color: theme.foregroundColor2,
              fontFamily: theme.primaryFont
            }}>
            {tap.beverage.name}
          </div>
          {!theme.hideBeverageSourceName && (
            <div
              className={`item-source ${theme.menuType || ''}`}
              style={{
                color: theme.foregroundColor,
                fontFamily: theme.secondaryFont
              }}>
              {tap.beverage?.source?.name}
            </div>
          )}
        </div>

        <div
          className={`item-meta-secondary ${theme.menuType || ''}`}
          style={{
            color: theme.foregroundColor,
            fontFamily: theme.secondaryFont
          }}>
          <span className="style">{tap.beverage.style}</span>
          {theme.showLocation && tapLocation && (
            <>
              <span className="divider"> - </span>
              <span className="style">{tapLocation}</span>
            </>
          )}
          {tap.showABV !== false && (
            <>
              <span className="divider"> - </span>
              <span className="abv">{tap.beverage.abv || '?'}%</span>
            </>
          )}
          {tap.showIBU === true && (
            <>
              <span className="divider"> - </span>
              <span className="abv">{tap.beverage.ibu || '?'} IBUs</span>
            </>
          )}
        </div>

        {theme.showServings && (
          <Grid container className={classes.itemMetaCost}>
            <Grid container item xs={12} spacing={2} style={{ textAlign: 'center' }}>
              {sortBy(tap.servings, (s) => s.size).map((serving) => (
                <Serving key={serving._id} {...{ serving, theme }} />
              ))}
            </Grid>
          </Grid>
        )}

        {theme.showDescription && (
          <div
            className={classes.itemBeverageDescription}
            style={{
              color: theme.foregroundColor,
              fontFamily: theme.secondaryFont
            }}>
            {tap.beverage?.description}
          </div>
        )}
      </div>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  pageHeading: {},
  widgetContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },

  itemMeta: {
    '@media (max-width: 400px)': {
      paddingInline: 0
    },
    padding: 20,
    //width: '50%',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width: 960px)': {
      justifyContent: 'center'
    }
  },

  bigHandle: {
    paddingRight: 20,
    paddingTop: 10,
    fontWeight: 'bold',
    display: 'flex',
    //alignItems: 'center',
    fontSize: '2em',
    width: '57px',
    justifyContent: 'flex-end'
  },
  itemImage: {
    width: 50,
    height: 50,
    borderRadius: 100,
    backgroundPosition: 'center',
    marginTop: 10,
    marginRight: 16,
    backgroundSize: 'cover'
  },

  itemMetaDescription: {
    flex: 1,
    '@media (max-width: 960px)': {
      maxWidth: '80ch'
    }
  },
  itemMetaPrimary: {},
  itemMetaName: {
    fontWeight: 'bold'
  },
  itemMetaCost: {
    display: 'flex'
  },

  itemMetaCostSingle: {
    display: 'flex'
  },
  servingCost: {
    fontWeight: 'bold'
  },
  servingVesselType: {
    margin: '0 10px'
  },
  servingSize: {
    display: 'flex'
  },
  sectionTitle: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    padding: '1.5rem 0 0 0',
    margin: 0,
    textTransform: 'uppercase'
  },
  noPremiumAndLoadingTitle: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    padding: '1.5rem 0 0 0',
    margin: 0
  }
}));
